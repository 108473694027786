<template>
	<div class="arena">
		<div class="arena-warp">
			<div class="extension_index_img">
				<img src="../assets/img/roll/aav2.png" alt="">
			</div>
			<div class="boxsss">
				<button :class="payType == '0'?'el-btn-click':'el-btn'" @click="getTypeList(0)">{{ $t("全部房间") }}</button>
				<button :class="payType == '1'?'el-btn-click':'el-btn'" @click="getTypeList(1)">{{ $t("官方房间") }}</button>
				<button :class="payType == '2'?'el-btn-click':'el-btn'" @click="getTypeList(2)">{{ $t("主播房间") }}</button>
				<button :class="payType == '4'?'el-btn-click':'el-btn'" @click="getTypeList(4)">{{ $t("我参与的") }}</button>
				<button :class="payType == '3'?'el-btn-click':'el-btn'" @click="getTypeList(3)">{{ $t("已结束") }}</button>
			</div>
			<div class="tabs">
				<div class="list">
					<ul>
						<li v-for="(item, index) in list" :key="index">
							<div class="list-warp">
								<div class="tou-warp">
									<img :src="item.img" />
									<div class="tou-warp-back"></div>
								</div>
								<div class="tou">
									<img :src="item.img" />
									<span>{{ item.type == 1 ? $t("官方") : $t("主播") }}</span>
								</div>
								<div class="name">{{ $getLocaleProperty(item,'room_name') }}</div>
								<div class="num">
									<div class="num-box">
										<span>{{$t("奖池")}}</span>
										<span class="num-span">{{ item.pool }}</span>
									</div>
									<div class="num-box">
										<span>{{$t("件数")}}</span>
										<span>{{ item.count }}</span>
									</div>
									<div class="num-box">
										<span>{{$t("人数")}}</span>
										<span>{{ item.person_num }}</span>
									</div>
								</div>
								<div class="img" v-for="(item1, index1) in item.skin_list" :key="index1">
									<div class="img-box">
										<img :src="item1.imageUrl" />
										<!-- <span>{{ item1.price }}</span> -->
									</div>
								</div>
								<div class="clear"></div>
								<div class="btn">
									<div class="btn-warp" @click="jionRoom(item.id)" v-if="item.status == 1">
										<!--  v-if="item.condition_type == 1 || item.condition_type == 3" -->
										<!-- <img  src="../assets/img/suo1.png" /> -->
										<span>{{$t("加入房间")}}</span>
									</div>
									<div class="btn-warp" v-if="item.status == 2">
										<span @click="jionRoom(item.id)">{{$t("已结束")}}</span>
									</div>
									<div class="btn-warp" v-if="item.status == 3">
										<span @click="jionRoom(item.id,true)">{{$t("已加入")}}</span>
									</div>
								</div>
								<div class="time">{{$t("开奖时间")}} : {{ item.run_lottery_time }}</div>

								<div class="back1" v-if="item.status == 2" @click="jionRoom(item.id)"></div>
							</div>
						</li>
						<li v-for="item in 5"></li>
					</ul>
				</div>
				<div class="list" v-if="payType == '4'">
					<ul>
						<li v-for="(item, index) in list1" :key="index">
							<div class="list-warp">
								<div class="tou-warp">
									<img :src="item.img" />
									<div class="tou-warp-back"></div>
								</div>
								<div class="tou">
									<img :src="item.img" />
									<span>{{ item.type == 1 ? $t("官方") : $t("主播") }}</span>
								</div>
								<div class="name">{{ $getLocaleProperty(item,'room_name')}}</div>
								<div class="num">
									<div class="num-box">
										<span>{{ $t("奖池") }}</span>
										<span class="num-span">{{ item.pool }}</span>
									</div>
									<div class="num-box">
										<span>{{ $t("件数") }}</span>
										<span>{{ item.count }}</span>
									</div>
									<div class="num-box">
										<span>{{ $t("人数") }}</span>
										<span>{{ item.person_num }}</span>
									</div>
								</div>
								<div class="img" v-for="(item1, index1) in item.skin_list" :key="index1">
									<div class="img-box">
										<img :src="item1.imageUrl" />
										<span>{{ item1.price }}</span>
									</div>
								</div>
								<div class="clear"></div>
								<div class="btn">
									<div class="btn-warp" v-if="item.status == 1">
										<span @click="jionRoom(item.id,true)">{{ $t("查看房间") }}</span>
									</div>
									<div class="btn-warp" v-if="item.status == 2">
										<span @click="jionRoom(item.id)">{{ $t("已结束") }}</span>
									</div>
								</div>
								<div class="time">{{ $t("开奖时间") }} : {{ item.run_lottery_time }}</div>

								<div class="back1" v-if="item.status == 2" @click="jionRoom(item.id)"></div>
							</div>
						</li>
						<li style="height: 0;"></li>
						<li style="height: 0;"></li>
						<li style="height: 0;"></li>
						<li style="height: 0;"></li>
						<li style="height: 0;"></li>
						<li style="height: 0;"></li>
					</ul>
				</div>
			</div>
		</div>
		<div class="clear"></div>
		<myhomebot></myhomebot>
		<navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>
	</div>
</template>
<script>
	import myhomebot from "@/components/my_homebot.vue";
	import myinform from "@/components/my_inform.vue";
	import navheader from "@/components/navheader.vue";
	export default {
		components: {
			myhomebot,
			myinform,
			navheader
		},
		props: ["yidongmenu", "showNav", "openHongbao"],
		data() {
			return {
				activeName: "first",
				list: [],
				list1: [],
				payType: "0",
			};
		},
		async mounted() {
			await this.getMyList();
			this.getList();
		},
		methods: {
			handleClick(tab, event) {
				if (tab.name == "first") {
					this.getList();
				} else {
					this.getMyList();
				}
			},
			//免费皮肤房间列表
			getList() {
				let _this = this;
				let param = {
					page: 1,
					pageSize: 50,
				};
				_this.$axios
					.post("index/Free/freeRoomList", _this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == 1) {
							this.list = data.data.list;
							for (let i = 0; i < this.list.length; i++) {
								if (this.list[i].person_num == null) {
									this.list[i].person_num = 0;
								}
								if (this.list[i].count == null) {
									this.list[i].count = 0;
								}
								if (this.list[i].pool == null) {
									this.list[i].pool = 0;
								}
							}
							this.list.forEach(e => {
								this.list1.forEach(es => {
									if (e.id == es.id && e.status != 2) {
										e.status = 3;
									}
								})
							})
						}
					});
			},
			// 官方roll
			getTypeList(e) {
				let _this = this;
				let param = {
					page: 1,
					pageSize: 30,
				};
				this.list = [];
				this.list1 = [];
				this.payType = e;
				if (e == 0) {
					this.getList();
				}
				if (e == 1) {
					_this.$axios
						.post("index/Free/officialList", _this.$qs.stringify(param))
						.then((res) => {
							// console.log(res.data);
							var data = res.data;
							if (data.status == 1) {
								this.list = data.data.list;
								for (let i = 0; i < this.list.length; i++) {
									if (this.list[i].person_num == null) {
										this.list[i].person_num = 0;
									}
									if (this.list[i].count == null) {
										this.list[i].count = 0;
									}
									if (this.list[i].pool == null) {
										this.list[i].pool = 0;
									}
								}
								this.list.forEach(e => {
									this.list1.forEach(es => {
										if (e.id == es.id && e.status != 2) {
											e.status = 3;
										}
									})
								})
							}
						});
				}
				if (e == 2) {
					_this.$axios
						.post("index/Free/anchorList", _this.$qs.stringify(param))
						.then((res) => {
							// console.log(res.data);
							var data = res.data;
							if (data.status == 1) {
								this.list = data.data.list;
								for (let i = 0; i < this.list.length; i++) {
									if (this.list[i].person_num == null) {
										this.list[i].person_num = 0;
									}
									if (this.list[i].count == null) {
										this.list[i].count = 0;
									}
									if (this.list[i].pool == null) {
										this.list[i].pool = 0;
									}
								}
								this.list.forEach(e => {
									this.list1.forEach(es => {
										if (e.id == es.id && e.status != 2) {
											e.status = 3;
										}
									})
								})
							}
						});
				}
				if (e == 3) {
					_this.$axios
						.post("index/Free/overList", _this.$qs.stringify(param))
						.then((res) => {
							// console.log(res.data);
							var data = res.data;
							if (data.status == 1) {
								this.list = data.data.list;
								for (let i = 0; i < this.list.length; i++) {
									if (this.list[i].person_num == null) {
										this.list[i].person_num = 0;
									}
									if (this.list[i].count == null) {
										this.list[i].count = 0;
									}
									if (this.list[i].pool == null) {
										this.list[i].pool = 0;
									}
								}
								this.list.forEach(e => {
									this.list1.forEach(es => {
										if (e.id == es.id && e.status != 2) {
											e.status = 3;
										}
									})
								})
							}
						});
				}
				if (e == 4) {
					this.getMyList();
				}
				this.$forceUpdate()
			},
			//我参与的房间列表
			getMyList() {
				let _this = this;
				let param = {
					page: 1,
					pageSize: 10,
				};
				 _this.$axios
					.post("index/Free/myFreeRoom", _this.$qs.stringify(param))
					.then((res) => {
						// console.log(res.data);
						var data = res.data;
						if (data.status == 1) {
							this.list1 = data.data.list;
							for (let i = 0; i < this.list1.length; i++) {
								if (this.list1[i].person_num == null) {
									this.list1[i].person_num = 0;
								}
								if (this.list1[i].count == null) {
									this.list1[i].count = 0;
								}
								if (this.list1[i].pool == null) {
									this.list1[i].pool = 0;
								}
							}
						}
					});
			},
			//加入房间
			jionRoom(id, status) {
				if (!this.$store.state.token) {
					this.$store.commit("getLogin", true);
					return;
				}
				this.$router.push({
					path: `/ArenaRoom`,
					query: {
						id: id,
						status: status,
					},
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.boxsss {
		width: 600px;
		padding: 0 32px;
		border-radius: 32px;
		border: 1px solid rgb(30, 28, 44);
		padding: 2px;
		margin-top: 32px;
	}

	@media screen and (max-width: 767px) {
		.boxsss {
			width: 80%;
			min-width: 280px;
			border-radius: 32px;
			border: 1px solid rgb(30, 28, 44);
			padding: 2px;
			margin: 12px auto;
		}
		.extension_index_img{
			// padding: 16px 50px;
			img{
				width: 80%;
			}
			margin: auto;
		}

		.el-btn {
			width: 20% !important;
			padding: 7px 0 !important;
			border-radius: 32px;
			border: none;
			// border: 1px solid rgb(233, 177, 14);
			background: none;
			// color: rgb(233, 177, 14);
			color: white;
			font-weight: 400 !important;
			font-size: 10px !important;
			// font-weight: 600;
		}

		// .el-btn:hover {
		// 	width: 100px;
		// 	padding: 8px 0;
		// 	border-radius: 32px;
		// 	// border: 1px solid rgb(233, 177, 14);
		// 	background: rgb(233, 177, 14);
		// 	color: white;
		// 	// font-weight: 600;
		// }

		.el-btn-click {
			width: 20% !important;
			padding: 7px 0 !important;
			border-radius: 32px;
			// border: 1px solid rgb(233, 177, 14);
			border: none;
			background: rgb(233, 177, 14);
			color: white;
			// font-weight: 600;
			font-weight: 400 !important;
			font-size: 10px !important;
		}

		.list-warp {
			margin: 8px 0 !important;
			// padding: 16px;
			background-color: #2b2a37;
			border-radius: 5px;
			overflow: hidden;
			position: relative;
		}
	}

	.el-btn {
		width: 116px;
		padding: 8px 0;
		border-radius: 32px;
		border: none;
		// border: 1px solid rgb(233, 177, 14);
		background: none;
		// color: rgb(233, 177, 14);
		color: white;
		font-weight: 600;
		// font-weight: 600;
	}

	// .el-btn:hover {
	// 	width: 100px;
	// 	padding: 8px 0;
	// 	border-radius: 32px;
	// 	// border: 1px solid rgb(233, 177, 14);
	// 	background: rgb(233, 177, 14);
	// 	color: white;
	// 	// font-weight: 600;
	// }

	.el-btn-click {
		width: 116px;
		padding: 8px 0;
		border-radius: 32px;
		// border: 1px solid rgb(233, 177, 14);
		border: none;
		background: rgb(233, 177, 14);
		color: white;
		// font-weight: 600;
		font-weight: 600;
	}

	.arena {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		background-color: #06090f;

		.arena-warp {
			min-height: 100vh;
			// padding: 16px 50px;
			width: 90%;
			margin: auto;

			.extension_index_img {
				display: flex;
				justify-content: center;
				align-items: center;
			}




			// display: flex;
			/deep/.el-tabs__header {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			/deep/.el-tabs__nav-wrap {
				// width: 200px;
			}

			.tabs /deep/ .el-tabs__item.is-active {
				color: #c3c3e2;
			}

			.tabs /deep/ .el-tabs__item {
				color: #848492;
				font-size: 20px;
			}

			.tabs /deep/ .el-tabs__nav-wrap::after {
				background-color: #1a1c24;
			}

			.tabs {
				.list {
					margin: 0 0;
					width: 100%;

					@media screen and (max-width: 767px) {
						ul {
							// background-size: cover !important;
							width: 80% !important;
							background: none !important;
							padding: 0 !important;
							min-width: 280px;
						}
					}

					ul {
						margin: 0 0;
						width: 100%;
						margin: auto;
						padding: 32px 0;
						// background-color: rgb(16, 21, 27);
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						justify-content: space-between;
						// height: 1000px;


						li {
							width: 19%;

							.list-warp {
								margin: 8px;
								// padding: 16px;
								background-color: #2b2a37;
								border-radius: 5px;
								overflow: hidden;
								position: relative;

								.tou-warp {
									width: 100%;
									height: 130px;
									position: relative;

									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
									}

									.tou-warp-back {
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 130px;
										background: linear-gradient(360deg,
												rgba(43, 44, 55, 1) 0%,
												rgba(43, 44, 55, 0.5) 100%);
									}
								}

								.back1 {
									width: 100%;
									height: 100%;
									background-color: rgba(0, 0, 0, 0.5);
									position: absolute;
									top: 0;
									left: 0;
								}

								.tou {
									padding: 0 16px;
									margin-top: -115px;
									position: relative;
									z-index: 66;
									display: flex;
									justify-content: center;

									img {
										width: 50px;
										height: 50px;
										border-radius: 50%;
										border: 1px solid #ae23c6;
									}

									span {
										color: #fff;
										font-size: 12px;
										padding: 0 6px;
										position: absolute;
										bottom: -3px;
										background-color: #ae23c6;
										border-radius: 3px;
									}
								}

								.name {
									position: relative;
									z-index: 66;
									padding: 0 16px;
									margin-top: 5px;
									font-size: 16px;
									color: #c3c3e2;
									text-align: center;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.num {
									position: relative;
									z-index: 66;
									padding: 0 16px;
									margin-top: 10px;
									display: flex;
									justify-content: space-around;
									align-items: center;
									font-size: 12px;

									.num-box {
										display: flex;
										flex-direction: column;
										align-items: center;

										span {
											color: #848492;
										}

										span:last-child {
											margin-top: 5px;
										}

										.num-span {
											color: #e9b10e;
										}
									}
								}

								.img {
									padding: 0 16px;
									margin-top: 20px;

									.img-box {
										width: 33.33%;
										float: left;
										background-image: url("../assets/img/beibao.png");
										background-size: 100% 100%;
										position: relative;
										text-align: center;
										// margin-right: 2px;

										img {
											width: 70%;
											height: 45px;
										}

										span {
											position: absolute;
											bottom: 0;
											left: 50%;
											// margin-left: -25px;
											color: #fff;
											padding: 0 10px;
											border-radius: 20px;
											background-color: rgba(0, 0, 0, 0.3);
											font-size: 10px;
										}
									}

									.img-box:first-child {
										border-top-left-radius: 5px;
										border-bottom-left-radius: 5px;
									}

									.img-box:last-child {
										border-top-right-radius: 5px;
										border-bottom-right-radius: 5px;
									}
								}

								.btn {
									padding: 0 16px;
									margin-top: 20px;
									display: flex;
									justify-content: center;

									.btn-warp {
										display: flex;
										align-items: center;
										background-color: #e9b10e;
										padding: 5px 25px;
										border-radius: 20px;

										img {
											width: 16px;
											height: 16px;
											margin-right: 10px;
										}

										span {
											font-size: 14px;
											color: black;
										}
									}

									.btn-warp:hover {
										// cursor: pointer;
										// border: 1px solid#e9b10e;
										background-color: #ce9a0c;
									}

									// .btn-warp:hover span {
									// 	color: #e9b10e;
									// }
								}

								.time {
									padding: 0 16px;
									margin: 20px 0 16px 0;

									text-align: center;
									font-size: 10px;
									color: #848492;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
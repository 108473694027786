<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">隐私条款</div>
      <div class="con">本《隐私政策》适用于我们提供的一切csgobox.xyz.com.cn服务。当您使用我们任何单项服务时，您同意接受本《隐私政策》以及我们在该单项服务中发出的特定隐私信息类政策条款（下列称“特定条款”）的保护，在此情况下特定条款与本政策条款同时对您产生效力。<br>
        请注意我们不时地会检查我们的政策，因此有关的措施会随之变化。我们恳请您定期光顾本页面，以确保对我们《隐私政策》最新版本始终保持了解。在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请与csgobox.xyz.com.cn客服联系。<br>
        您使用或继续使用csgobox.xyz.com.cn服务，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。<br>
        <h3>一、我们可能收集的信息</h3><br>
        （一）与个人身份无关的信息：<br>
        当您使用csgobox.xyz.com.cn服务时，我们可能收集和汇总诸如用户的来源途径、访问顺序等信息，例如记录使用csgobox.xyz.com.cn服务的每个用户的来源途径、浏览器软件等。<br>
        （二）有关个人身份的信息：<br>
        当您使用csgobox.xyz.com.cn服务时，我们可能收集和汇总或要求您提供有关个人身份的信息，例如个人：生日、籍贯、性别、兴趣爱好、个人电话号码、（包括系统账号、IP地址、电子邮箱地址等）；个人财产信息（交易记录、余额、优惠券、兑换码等）；通讯信息；个人上网记录和日志信息（包括网站浏览记录、软件使用记录、点击记录、操作日志等）；设备信息（包括设备型号、设备MAC地址、操作系统类型、设备设置）；软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）；位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）。<br>
        我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用csgobox.xyz.com.cn服务。csgobox.xyz.com.cn的目标是向所有的互联网用户提供安全、刺激、有趣及有教益的上网经历。而这些信息有助于我们实现这一目标。<br>
        <h3>二、我们如何收集和使用信息</h3><br>
        （一）我们将通过以下途径收集和获得您的信息：<br>
        1、您提供的信息。 例如：<br>
        （1）您在注册csgobox.xyz.com.cn服务的帐号或使用csgobox.xyz.com.cn服务时，向我们提供的信息；<br>
        （2）您通过csgobox.xyz.com.cn服务向其他方提供的共享信息，以及您使用csgobox.xyz.com.cn服务时所储存的信息。<br>
        2、我们获取的您的信息。您在使用csgobox.xyz.com.cn服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息等。<br>
        （二）COOKIES、日志档案和WEB BEACON<br>
        我们或我们的第三方合作伙伴可能通过COOKIES和WEB BEACON获取和使用您的信息，并将该等信息储存为日志信息。通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得csgobox.xyz.com.cn虚拟社区的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录csgobox.xyz.com.cn的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在csgobox.xyz.com.cn特定服务上花费的时间和您所选择的csgobox.xyz.com.cn服务。COOKIES使得我们能更好、更快地为您服务，并且使您在csgobox.xyz.com.cn服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。<br>
        我们使用自己的COOKIES和WEB BEACON，用于以下用途：<br>
        （1）记住您的身份。例如：COOKIES和WEB BEACON有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；<br>
        （2）分析您使用我们服务的情况。我们可利用COOKIES和WEB BEACON来了解您使用csgobox.xyz.com.cn服务进行什么活动、或哪些服务或服务最受欢迎；及<br>
        （3）广告优化。COOKIES和WEB BEACON有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。<br>
        我们为上述目的使用COOKIES和WEB BEACON的同时，可能将通过COOKIES和WEB BEACON收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用csgobox.xyz.com.cn服务并用于广告服务。<br>
        csgobox.xyz.com.cn服务上可能会有广告商和其他合作方放置的COOKIES和WEB BEACON。这些COOKIES和WEB BEACON可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES和WEB BEACON收集和使用该等信息不受本《隐私政策》约束，而是受到其自身的个人信息保护声明约束，我们不对第三方的COOKIES或WEB BEACON承担责任。<br>
        您可以通过浏览器或用户选择机制拒绝或管理COOKIES或WEB BEACON。但请您注意，如果您停用COOKIES或WEB BEACON，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。<br>
        （三）我们会出于以下目的，收集和使用您的信息：<br>
        1、帮助您完成注册<br>
        为便于我们为您提供服务，您需要提供基本注册信息，例如手机号码、电子邮箱地址等，并创建您的用户名和密码。在部分单项服务中，如果您仅需使用浏览、搜索等基本服务，您不需要注册成为csgobox.xyz.com.cn用户及提供上述信息。<br>
        2、向您提供商品或服务<br>
        我们所收集和使用的信息是为您提供csgobox.xyz.com.cn服务的必要条件，如缺少相关信息，我们将无法为您提供csgobox.xyz.com.cn服务的核心内容，例如：<br>
        （1）在部分服务项目中，为便于向您交付商品或服务，您需提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态等信息。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过csgobox.xyz.com.cn服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。<br>
        （2）为展示您账户的订单信息，我们会收集您在使用csgobox.xyz.com.cn服务过程中产生的订单信息用于向您展示及便于您对订单进行管理；<br>
        （3）当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。<br>
        （4）为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。<br>
        3、为您提供安全保障<br>
        为确保您身份真实性、向您提供更好的安全保障，您可以向我们提供身份证明、面部特征等生物识别信息等个人敏感信息以完成实名认证。<br>
        除身份验证外，我们可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保我们向您提供的服务的安全性；我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br>
        <h3>三、我们可能分享、转让或披露的信息</h3><br>
        （一）分享<br>
        除以下情形外，未经您同意，我们不会与csgobox.xyz.com.cn之外的任何第三方分享您的信息：<br>
        1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；<br>
        2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以帮助我们为您提供更有针对性、更完善的服务，例如：代表我们发出电子邮件或推送通知的通讯服务提供商等；<br>
        3、实现本《隐私政策》第二条“我们如何收集和使用信息”部分所述目的；<br>
        4、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；<br>
        5、向委托我们进行推广的合作伙伴等第三方共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名电话号码或电子邮箱；或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。<br>
        6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他csgobox.xyz.com.cn用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。<br>
        （二）转让<br>
        1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。<br>
        2、在获得您的明确同意后，我们会向其他方转让您的信息。<br>
        （三）披露<br>
        我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：
        1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。<br>
        <h3>四、我们如何保留、储存和保护信息</h3><br>
        我们将采取以下手段保护您的信息：<br>
        （一）数据安全技术措施<br>
        我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。<br>
        （二）我们为保护信息采取的其他安全措施<br>
        我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。<br>
        我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。<br>
        加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。<br>
        （三）我们仅允许有必要知晓这些信息的csgobox.xyz.com.cn员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与csgobox.xyz.com.cn的合作关系。<br>
        （四）我们会采取一切合理可行的措施，确保未收集无关的信息。<br>
        （五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。<br>
        （六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。<br>
        （七）安全事件处置<br>
        在通过csgobox.xyz.com.cn服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。<br>
        为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。<br>
        在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。<br>
        请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入csgobox.xyz.com.cn服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。<br>
        <h3>五、如何联系我们</h3><br>
        如您有关于网络信息安全的投诉和举报，或您对本《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关本声明或csgobox.xyz.com.cn的隐私措施的问题请与csgobox.xyz.com.cn的协调人联系。
      </div><br>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    padding-bottom: 88px;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
}
	@media screen and (max-width: 767px) {
		.doubt {
			overflow: hidden;
			overflow-y: auto;
			width: 100%;
			height: 100%;
			background-color: #1a1c24;
			padding-top: 42px;

			.doubt-warp {
				width: 80%;
				margin: 0 auto;
			}

			.title {
				font-size: 30px;
				color: #c3c3e2;
				font-size: 20px;
			}

			.con {
				margin-top: 12px !important;
				margin-bottom: 12px !important;
				font-size: 14px !important;
				color: #848492;

				h3 {
					color: #ddd;
					font-weight: normal;
					margin-top: 42px;
					font-size: 14px !important;
				}
			}

			.title1 {
				color: #c3c3e2;
				font-size: 14px;
				padding-bottom: 10px;
			}
		}
	}
</style>
<template>
  <div class="room" :style="{ '--backimg': backcssVars() }">
    <div class="room-right-box" v-if="showlll">
      <div class="room-right">
        <div>{{$t('确定邀请机器人')}}</div>
        <div>({{$t('注:本网站拼箱机器人获奖概率与玩家完全相同')}})</div>
        <div>
          <span @click="showlll = false">{{$t('取消')}}</span>
          <span @click="goParticipate(null, true)">{{$t('确认')}}</span>
        </div>
      </div>
    </div>
    <div class="room-box">
      <div class="room-left">
        <div class="room-left-hint">
          <!-- <div class="roomleft-num">
						<span @click="goLucky">盲盒对战</span>
						<span>></span>
						<span>参加对战编号 {{ id }}</span>
					</div> -->
          <!-- pc端对战页面显示所有房间的地方 -->
          <div class="roomleft-btn">
            <div @click="goLucky">
              <img src="../assets/img/ranking/zuo.png" />{{$t('返回')}}
            </div>
            <!-- <el-switch v-model="cartState" inactive-text="声音"> </el-switch> -->
            <!-- <span>邀请好友</span> -->
            <div class="title1">
              <div class="t" v-if="playmode == 1"><img src="../assets/img/ranking/jingdian-bg.png" alt=""></div>
              <div class="t" v-if="playmode == 2"><img src="../assets/img/ranking/feiqiu-bg.png" alt=""></div>
            </div>
          </div>
        </div>
        <div class="room-left-box">
          <div class="roombox-warp">
            <div class="room-info-top">
              <!-- 上方状态 -->
              <div class="first">
                <div
                  class="point"
                  :class="[`point_${statuslist.status}`]"
                ></div>
                <span :class="[`text_${statuslist.status}`]">
                  {{ [$t('等待中'),$t('进行中'),$t('已结束')][statuslist.status - 1] }}
                </span>
              </div>
              <div class="seconed">
                <span v-if="statuslist.status == 1 || statuslist.status == 3"
                  >{{$t('共')}}{{ fightboxList.length }}{{$t('回合')}}</span
                >
                <span v-if="statuslist.status == 2"
                  >{{$t('回合数')}}：{{ thisfightboxList }}/{{
                    fightboxList.length
                  }}</span
                >
              </div>
              <div class="threed">
                <span
                  >{{$t('总消耗')}}：<img src="../assets/img/money.png" alt="" /><span
                    class="price"
                    >{{ totalPrice.toFixed(2) }}</span
                  ></span
                >
              </div>
            </div>
            <!-- 用户选择的武器 -->
            <ul>
              <li
                v-for="(item, index) in fightboxList"
                :key="index"
                :id="item.state ? 'room-li' : ''"
              >
                <div
                  class="roombox-num"
                  @click="getBot(item.name, item.box_id)"
                >
                  <div class="roombox-img">
                    <img
                      :style="item.img_main ? '' : 'display: none'"
                      :src="item.img_main"
                    />
                    <img
                      :style="item.img_active ? '' : 'display: none'"
                      :src="item.img_active"
                    />
                  </div>
                  <div class=""></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="room-left-people">
          <div class="roompe-warp">
            <div class="roompe-line" v-if="roomLineState"></div>
            <ul class="ul1 ul2" v-if="statuslist.status == 1">
              <li
                class="li1"
                v-for="(item, index) in peopleList"
                :key="index"
                :style="{ width: item.width }"
                :class="
                  item.winState ? '' : statuslist.status == 3 ? 'fail' : ''
                "
              >
                <!-- 对战结束后展示结果 -->
                <div class="game-top" v-if="statuslist.status == 3">
                  <div class="game-img">
                    <img
                      class="win-img"
                      :src="imgs"
                      alt=""
                      v-if="item.winState"
                      srcset=""
                    />
                    <img
                      v-else
                      class="fail-img"
                      src="../assets/img/ranking/win1.png"
                      alt=""
                      srcset=""
                    />
                    <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                    >
                      <img src="../assets/img/money.png" alt="" />
                      <span>{{ item.totalPrice }}</span>
                    </p>
                  </div>
                </div>
                <!-- 对战正在进行时显示的动画 -->
                <div class="game-bottom" v-if="statuslist.status == 2">
                  <div class="pool" v-if="openWin">
                    <ul
                      :class="{
                        'pool-ul2': mode == 2,
                        'pool-ul3': mode == 3,
                        'pool-ul4': mode == 4,
                        'pool-ul5': mode == 5,
                        'pool-ul6': mode == 6,
                      }"
                    >
                      <li
                        v-for="(itemBox, indexBox) in item.fightBox"
                        :key="indexBox"
                        :class="[getColorPz(itemBox.color)]"
                      >
                        <div class="box-img1">
                          <img :src="itemBox.imageUrl" />
                        </div>
                        <!-- <div class="box-img-name">
													{{ itemBox.itemName }}
												</div> -->
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="room-warp room-warps"
                  :class="[
                    item.winState ? 'room-win-status' : 'room-fail-status',
                  ]"
                >
                  <div class="room2">
                    <div class="room2-swap">
                      <div class="room2-tou" v-if="item.state">
                        <img :src="item.img" />
                      </div>
                      <div class="room2-tou" v-else style="opacity: 0">
                        <img :src="item.img" />
                      </div>
                      <div class="room2-name" v-if="item.state">
                        {{ item.name }}
                      </div>
                      <div class="room2-name" v-else style="opacity: 0">
                        {{ item.name }}
                      </div>
                    </div>
                    <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                      v-if="statuslist.status != 3"
                    >
                      <img
                        v-if="item.totalPrice"
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.totalPrice }}</span>
                    </p>
                    <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                      v-if="statuslist.status == 3"
                    >
                      <img
                        v-if="item.open_value"
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.open_value }}</span>
                    </p>
                    <!-- <span v-if="!item.state">等待玩家</span> -->
                  </div>

                  <div class="win-list" v-if="statuslist.status != 1">
                    <ul class="win-ul">
                      <li
                        class="win-li"
                        v-for="(item1, index1) in item.box"
                        :key="index1"
                        :class="getColorPz(item1.color)"
                      >
                        <div class="box-img1">
                          <img :src="item1.img" />
                        </div>
                        <div class="box-img-name" :title="item.name">
                          <div class="list-pirce">
                            <div class="pirce-left">
                              <img src="../assets/img/money.png" />
                              <span>{{ item1.price }}</span>
                            </div>
                            <div class="pirce-right">
                              {{$getLocaleProperty(item1,'exteriorName')}}
                              <!-- {{ extractValuesInParentheses(item1.exteriorName)}} -->
                            </div>
                          </div>
                          {{$getLocaleProperty(item1,'shortName')}}
                          <!-- {{ getFrist(item1.name) }} | {{ getLast(item1.name) }} -->
                        </div>
                      </li>
                      <li
                        v-if="
                          item.box &&
                          item.box.length % 2 == 0 &&
                          item.box.length % 4 != 0
                        "
                        style="opacity: 0"
                      ></li>
                      <!-- <li v-if="item.box && item.box.length%3 == 0" style="opacity: 0;"
												class="win-li" v-for="(item1, index1) in 1" :key="index1"
												:class="getColorPz(item1.color)">
											</li> -->
                    </ul>
                  </div>
                  <div class="room1" v-if="statuslist.status == 1">
                    <div class="room1-img" v-if="item.state && item.end == 1">
                      <img src="../assets/img/ranking/yes.png" />
                      <div>{{$t('准备就绪')}}</div>
                    </div>
                    <div
                      class="room1-img"
                      @click="goParticipate(index)"
                      v-if="!item.state"
                    >
                      <img src="../assets/img/ranking/join.png" />
                      <div v-if="homeid == statuslist.home_owner">
                        {{$t('邀请官方人机')}}
                      </div>
                      <div v-else>{{$t('加入游戏')}}</div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="li1" v-if="!showNav"></li>
            </ul>
            <ul class="ul1" v-else>
              <li
                class="li1"
                v-for="(item, index) in peopleList"
                :key="index"
                :style="{ width: item.width }"
                :class="
                  item.winState ? '' : statuslist.status == 3 ? 'fail' : ''
                "
              >
                <div class="game-top" v-if="statuslist.status == 3">
                  <div class="game-img">
                    <img
                      class="win-img"
                      :src="imgs"
                      alt=""
                      v-if="item.winState"
                      srcset=""
                    />
                    <img
                      v-else
                      class="fail-img"
                      src="../assets/img/ranking/win1.png"
                      alt=""
                      srcset=""
                    />
                    <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                    >
                      <img src="../assets/img/money.png" alt="" />
                      <span>{{ item.totalPrice }}</span>
                    </p>
                  </div>
                </div>
                <div
                  class="game-bottom"
                  v-if="statuslist.status == 2 && showNav"
                >
                  <!-- <div class="game-img">
										<img class="win-img" src="../assets/img/ranking/win.png" alt=""
											v-if="item.winState" srcset="" />
										<img v-else class="fail-img" src="../assets/img/ranking/win1.png" alt=""
											srcset="" />
										<p class="prize-price" :class="item.winState? '':'failprize-price'">
											<img src="../assets/img/money.png" alt="">
											<span>{{ item.totalPrice }}</span>
										</p>
									</div> -->
                  <div class="pool" v-if="openWin">
                    <ul
                      :class="{
                        'pool-ul2': mode == 2,
                        'pool-ul3': mode == 3,
                        'pool-ul4': mode == 4,
                        'pool-ul5': mode == 5,
                        'pool-ul6': mode == 6,
                      }"
                    >
                      <li
                        v-for="(itemBox, indexBox) in item.fightBox"
                        :key="indexBox"
                        :class="getColorPz(itemBox.color)"
                      >
                        <div class="box-img1">
                          <img :src="itemBox.imageUrl" />
                        </div>
                        <!-- <div class="box-img-name">
													{{ itemBox.itemName }}
												</div> -->
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="room-warp room-warps"
                  :class="[
                    item.winState ? 'room-win-status' : 'room-fail-status',
                  ]"
                >
                  <div class="room2">
                    <div class="room2-swap">
                      <div class="room2-tou" v-if="item.state">
                        <img :src="item.img" />
                      </div>
                      <div class="room2-tou" v-else style="opacity: 0">
                        <img :src="item.img" />
                      </div>
                      <div class="room2-name" v-if="item.state">
                        {{ item.name }}
                      </div>
                      <div class="room2-name" v-else style="opacity: 0">
                        {{ item.name }}
                      </div>
                    </div>
                    <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                      v-if="statuslist.status != 3"
                    >
                      <img
                        v-if="item.totalPrice"
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.totalPrice }}</span>
                    </p>
                    <p
                      class="prize-price"
                      :class="item.winState ? '' : 'failprize-price'"
                      v-if="statuslist.status == 3"
                    >
                      <img
                        v-if="item.open_value"
                        style="width: 20px"
                        src="../assets/img/money.png"
                        alt=""
                      />
                      <span>{{ item.open_value }}</span>
                    </p>
                    <!-- <span v-if="!item.state">等待玩家</span> -->
                  </div>
                  <div
                    class="game-bottom"
                    v-if="statuslist.status == 2 && !showNav"
                  >
                    <!-- <div class="game-img">
											<img class="win-img" src="../assets/img/ranking/win.png" alt=""
												v-if="item.winState" srcset="" />
											<img v-else class="fail-img" src="../assets/img/ranking/win1.png" alt=""
												srcset="" />
											<p class="prize-price" :class="item.winState? '':'failprize-price'">
												<img src="../assets/img/money.png" alt="">
												<span>{{ item.totalPrice }}</span>
											</p>
										</div> -->
                    <div class="pool" v-if="openWin">
                      <ul
                        :class="{
                          'pool-ul2': mode == 2,
                          'pool-ul3': mode == 3,
                          'pool-ul4': mode == 4,
                          'pool-ul5': mode == 5,
                          'pool-ul6': mode == 6,
                        }"
                      >
                        <li
                          v-for="(itemBox, indexBox) in item.fightBox"
                          :key="indexBox"
                          :class="[
                            getColorPz(itemBox.color),
                            {
                              'pool-li2': peopleList.length == 2,
                              'pool-li3': peopleList.length == 3,
                              'pool-li4': peopleList.length == 4,
                            },
                          ]"
                        >
                          <div class="box-img1">
                            <img :src="itemBox.imageUrl" />
                          </div>
                          <!-- <div class="box-img-name">
														{{ itemBox.itemName }}
													</div> -->
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="win-list" v-if="statuslist.status != 1">
                    <ul
                      class="win-ul"
                      :class="{ 'win-ul4': peopleList.length == 4 }"
                    >
                      <li
                        class="win-li"
                        v-for="(item1, index1) in item.box"
                        :key="index1"
                        :class="getColorPz(item1.color)"
                      >
                        <div class="box-img1">
                          <img :src="item1.img" />
                        </div>
                        <div class="box-img-name" :title="item.name">
                          <div class="list-pirce">
                            <div class="pirce-left">
                              <img src="../assets/img/money.png" />
                              <span>{{ item1.price }}</span>
                            </div>
                            <div class="pirce-right">
                              <!-- {{ item1 }} -->
                              {{$getLocaleProperty(item1,'exteriorName')}}
                              <!-- {{ extractValuesInParentheses($getLocaleProperty(item1,'name')) }} -->
                            </div>
                          </div>
                          {{$getLocaleProperty(item1,'shortName')}}
                          <!-- {{ getFrist($getLocaleProperty(item1,'name')) }} | {{ getLast($getLocaleProperty(item1,'name')) }} -->
                        </div>
                      </li>
                      <li
                        style="width: 140px; height: 0; visibility: hidden"
                      ></li>
                      <li
                        style="width: 140px; height: 0; visibility: hidden"
                      ></li>
                      <li
                        style="width: 140px; height: 0; visibility: hidden"
                      ></li>
                      <!-- <li v-if="item.box && item.box.length%3 == 0" style="opacity: 0;"
												class="win-li" v-for="(item1, index1) in 1" :key="index1"
												:class="getColorPz(item1.color)">
											</li> -->
                    </ul>
                  </div>
                  <div class="room1" v-if="statuslist.status == 1">
                    <div class="room1-img" v-if="item.state && item.end == 1">
                      <img src="../assets/img/ranking/yes.png" />
                      <div>{{$t("准备就绪")}}</div>
                    </div>
                    <div
                      class="room1-img"
                      @click="goParticipate(index)"
                      v-if="!item.state"
                    >
                      <img src="../assets/img/ranking/join.png" />
                      <div>{{$t("加入游戏")}}</div>
                    </div>
                  </div>
                </div>
              </li>
              <div class="room-line" v-if="lineState"></div>
            </ul>
          </div>
        </div>
        <div class="room-left-bot"></div>
      </div>

      <!-- <el-drawer title="我是标题" :visible.sync="drawerBot" :append-to-body="true" :with-header="false"
				direction="btt" class="box-bot">
				<div class="box-img">
					<div class="box-img-title">
						<span>{{ drawerName }}</span>
						<span class="el-icon-close"></span>
					</div>
					<div class="box-img-title1">
						<span>包含以下奖励</span>
					</div>
					<div class="box-img-list">
						<ul>
							<li :class="getColorPz(item.color)" v-for="(item, index) in drawerList" :key="index">
								<div class="box-img1">
									<img :src="item.img" />
								</div>
								<div class="probability">
									{{item.probability}}%
								</div>
								<div class="box-img-name" :title="item.name">
									<div class="list-pirce">
										<div class="pirce-left">
											<img src="../assets/img/money.png" />
											<span>{{ item.price }}</span>
										</div>
										<div class="pirce-right">
											{{extractValuesInParentheses(item.name)}}
										</div>
									</div>
									{{ getFrist(item.name) }} | {{ getLast(item.name) }}
								</div>
							</li>
						</ul>
					</div>
				</div>
			</el-drawer> -->
      <div class="biaoti-box" v-if="drawerBot">
        <div class="top">
          <span>{{$t('包含以下奖励')}}</span>
          <span @click="drawerBot = false"
            ><img src="../assets/img/ranking/cha.png" alt=""
          /></span>
        </div>
        <div class="gailv" v-if="skins_types && skins_types.length > 0">
          <!-- 掉落概率 -->
          <span v-for="(skin, index) in skins_types" :key="index">
            <span
              class="probability-icon"
              :style="{ backgroundColor: skin.color }"
            ></span>
            {{ parseFloat(skin.skins_type_probability).toFixed(2) }}%
          </span>
        </div>
        <div class="bottom">
          <ul>
            <li
              class="li"
              :class="getColorPz(item.color)"
              v-for="(item, index) in drawerList"
              :key="index"
            >
              <div class="box-img-name" :title="item.name">
                {{ getFrist(item.name) }} | {{ getLast(item.name) }}
              </div>
              <div class="box-img1">
                <img :src="item.img" />
              </div>
              <div class="libottom">
                <div class="pirce-right">
                  {{ extractValuesInParentheses(item.name) }}
                </div>
                <div class="list-pirce">
                  <div class="pirce-left">
                    <img src="../assets/img/money.png" />
                    <span>{{ item.price }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <audio controls ref="notify1" class="audio" loop="loop">
        <source src="../assets/audio/battle.mp3" />
      </audio>
    </div>
    <myhomebot></myhomebot>
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";

import Utils from "./../assets/js/util.js";
import { battle } from "@/api/socket.js";
export default {
  components: {
    myhomebot,
  },
  props: ["showNav"],
  data() {
    return {
    skins_types: [],
      imgs: "",
      add: false,
      disabled: false,
      loading: false,
      roomLineState: false,
      statuslist: [],
      id: "",
      winList: [
        {
          name: 1,
        },
        {
          name: 2,
        },
        {
          name: 3,
        },
      ],
      homeid: null,
      loser: [
        {
          name: "",
          img: "",
          price: "0.01",
        },
      ],
      boxList: [],
      boxListIndex: 0,
      fightboxList: [],
      fightImg: [],
      fightImgObj: [],
      peopleList: [],
      peopleObj: {
        name: this.$t("凉凉奖励"),
        width: "",
        state: true,
        price: "0.01",
        img: require("../assets/img/moneyback.png"),
        loading: false,
      },
      thisfightboxList: 1,
      img: "",
      lineState: false,
      openWin: false,
      drawerList: [],
      drawerName: null,
      drawerBot: false,
      mode: "",
      showlll: false,
      openBox: [
        {
          state: false,
        },
      ],
      totalPrice: 0,
      playmode: "",

      adds: false,
      cartState: false,
      c1: [
        [
          339, 143, 348, 69, 7, 340, 500, 24, 155, 582, 158, 75, 89, 17, 443,
          489, 31, 97, 560, 569, 328, 25, 384, 321, 316, 303, 197, 185, 26, 513,
          199, 218, 13, 568, 96, 81, 95, 268, 157, 65, 581, 203, 57, 455, 426,
          408, 43, 580, 405, 0, 415, 60, 284, 433, 120, 291, 145, 149, 523, 389,
          331, 403, 117, 258, 541, 19, 396, 320, 442, 428, 351, 301, 377, 367,
          100, 344, 342, 92, 374, 375, 535, 28, 566, 505, 572, 10, 438, 35, 198,
          16, 493, 213, 125, 463, 588, 257, 388, 492, 355, 358, 215, 371, 427,
          288, 473, 116, 137, 540, 497, 289, 312, 123, 498, 160, 294, 8, 86,
          508, 148, 457, 404, 332, 170, 193, 356, 232, 181, 179, 390, 558, 173,
          317, 370, 525, 326, 255, 458, 41, 574, 254, 139, 420, 401, 562, 206,
          542, 446, 188, 311, 545, 419, 151, 186, 264, 337, 187, 103, 364, 538,
          227, 163, 369, 229, 102, 115, 79, 85, 104, 515, 222, 522, 168, 209,
          429, 380, 27, 252, 512, 152, 88, 118, 362, 481, 409, 223, 534, 386,
          38, 230, 578, 237, 242, 233, 313, 571, 176, 225, 287, 432, 195, 353,
          159, 71, 437, 453, 383, 36, 499, 276, 529, 265, 595, 359, 55, 221,
          236, 214, 146, 111, 360, 435, 586, 40, 202, 210, 533, 483, 385, 398,
          169, 270, 559, 322, 460, 59, 4, 216, 425, 267, 201, 293, 147, 397, 63,
          520, 411, 570, 77, 282, 407, 44, 509, 283, 518, 475, 496, 539, 224,
          249, 563, 537, 524, 140, 564, 330, 212, 33, 599, 253, 11, 78, 376,
          459, 250, 323, 251, 50, 61, 12, 241, 172, 399, 343, 299, 462, 491,
          506, 23, 366, 544, 573, 114, 30, 292, 528, 502, 51, 575, 136, 66, 300,
          456, 357, 286, 511, 22, 579, 72, 465, 84, 379, 476, 29, 471, 266, 341,
          244, 98, 194, 37, 490, 34, 180, 461, 135, 141, 549, 550, 165, 318,
          107, 434, 417, 597, 154, 363, 591, 271, 393, 127, 243, 48, 494, 94,
          314, 269, 308, 52, 416, 93, 217, 327, 365, 372, 131, 67, 468, 2, 262,
          310, 190, 240, 531, 391, 200, 167, 423, 345, 261, 247, 593, 112, 106,
          290, 9, 479, 487, 73, 274, 480, 256, 110, 447, 132, 39, 302, 329, 6,
          589, 596, 592, 309, 464, 400, 466, 211, 82, 430, 273, 394, 208, 32,
          196, 56, 15, 527, 450, 54, 305, 421, 126, 184, 350, 467, 546, 334,
          162, 567, 526, 246, 231, 431, 62, 133, 205, 584, 178, 439, 90, 551,
          576, 410, 280, 354, 413, 189, 590, 361, 108, 532, 587, 248, 238, 234,
          440, 175, 239, 444, 260, 552, 598, 42, 307, 553, 547, 335, 441, 109,
          49, 99, 58, 183, 285, 74, 171, 124, 235, 482, 543, 373, 166, 142, 452,
          14, 470, 325, 449, 495, 228, 412, 304, 101, 395, 577, 451, 501, 445,
          478, 245, 565, 150, 583, 315, 521, 561, 382, 121, 91, 319, 138, 18,
          554, 387, 3, 488, 418, 516, 530, 113, 129, 76, 555, 504, 134, 272,
          548, 192, 277, 130, 20, 5, 207, 536, 281, 204, 80, 46, 517, 53, 507,
          333, 486, 519, 70, 474, 324, 448, 263, 279, 297, 47, 352, 338, 296,
          368, 295, 485, 128, 278, 1, 503, 424, 144, 454, 349, 83, 87, 406, 219,
          191, 594, 153, 469, 306, 119, 275, 336, 557, 381, 68, 182, 484, 414,
          392, 45, 477, 298, 556, 346, 510, 378, 402, 436, 64, 177, 347, 259,
          122, 514, 472, 161, 220, 164, 585, 156, 174, 105, 422, 226, 21,
        ],
        [
          353, 575, 596, 576, 4, 71, 14, 175, 26, 153, 40, 341, 79, 262, 246,
          563, 414, 478, 47, 28, 72, 578, 12, 536, 481, 304, 527, 347, 510, 240,
          251, 30, 482, 283, 277, 95, 119, 73, 269, 45, 33, 471, 535, 465, 226,
          551, 383, 118, 390, 336, 581, 449, 241, 291, 553, 264, 123, 499, 374,
          96, 184, 448, 159, 105, 127, 92, 10, 235, 249, 494, 325, 441, 577,
          345, 364, 504, 293, 141, 101, 429, 22, 224, 408, 171, 367, 74, 387,
          549, 339, 416, 485, 430, 243, 360, 124, 177, 94, 222, 220, 245, 421,
          541, 579, 422, 412, 546, 158, 136, 100, 346, 350, 466, 99, 182, 573,
          368, 103, 561, 537, 52, 531, 477, 586, 505, 539, 234, 413, 87, 163,
          468, 524, 166, 394, 42, 397, 314, 324, 56, 60, 461, 352, 591, 395,
          145, 533, 425, 31, 292, 107, 328, 315, 558, 303, 427, 318, 256, 423,
          351, 205, 144, 11, 464, 149, 529, 157, 459, 562, 276, 399, 568, 199,
          557, 239, 279, 139, 431, 271, 181, 200, 32, 75, 213, 38, 426, 116,
          113, 77, 377, 438, 488, 507, 440, 355, 90, 543, 329, 102, 215, 571,
          98, 6, 111, 552, 114, 456, 203, 63, 9, 487, 108, 572, 511, 574, 530,
          64, 322, 62, 296, 70, 417, 1, 80, 503, 13, 174, 122, 50, 320, 595,
          460, 39, 25, 236, 61, 223, 450, 167, 242, 521, 216, 525, 442, 381,
          209, 258, 555, 484, 183, 317, 548, 250, 78, 475, 523, 146, 492, 599,
          436, 160, 143, 191, 340, 358, 342, 338, 217, 582, 290, 348, 88, 370,
          514, 522, 299, 230, 156, 36, 453, 319, 335, 472, 278, 112, 502, 309,
          344, 547, 512, 411, 68, 446, 462, 410, 379, 580, 398, 447, 517, 407,
          58, 65, 54, 439, 489, 46, 380, 193, 330, 150, 155, 483, 409, 369, 208,
          8, 564, 134, 486, 180, 382, 526, 513, 232, 540, 186, 129, 83, 84, 212,
          280, 588, 164, 266, 528, 388, 294, 189, 519, 211, 401, 148, 337, 176,
          274, 443, 375, 474, 210, 104, 428, 172, 255, 305, 371, 221, 493, 326,
          106, 544, 263, 214, 515, 34, 198, 479, 312, 110, 91, 393, 587, 501,
          435, 135, 308, 356, 457, 248, 391, 452, 403, 67, 373, 260, 307, 327,
          2, 53, 267, 132, 49, 444, 194, 389, 81, 17, 170, 372, 566, 82, 195,
          361, 334, 85, 437, 152, 385, 252, 321, 125, 313, 57, 265, 289, 480,
          147, 598, 585, 41, 490, 559, 287, 285, 133, 218, 185, 151, 420, 21,
          196, 316, 359, 259, 545, 5, 244, 168, 583, 37, 349, 272, 520, 495,
          165, 161, 18, 204, 295, 169, 297, 59, 550, 35, 584, 491, 570, 179,
          569, 476, 231, 162, 298, 69, 554, 121, 567, 532, 228, 424, 455, 44,
          534, 254, 454, 419, 451, 154, 19, 509, 15, 470, 415, 126, 24, 128,
          500, 268, 366, 140, 332, 405, 130, 23, 378, 463, 467, 201, 253, 497,
          115, 27, 386, 506, 282, 86, 173, 396, 131, 229, 496, 197, 138, 402,
          311, 20, 187, 270, 542, 43, 404, 357, 120, 117, 301, 590, 406, 473,
          192, 202, 51, 445, 66, 233, 384, 323, 400, 458, 16, 560, 592, 227,
          206, 275, 288, 237, 432, 257, 594, 142, 0, 433, 597, 518, 286, 190,
          137, 363, 29, 343, 362, 306, 392, 498, 219, 331, 302, 238, 247, 365,
          333, 55, 89, 178, 589, 418, 273, 207, 48, 310, 469, 76, 556, 434, 565,
          284, 300, 593, 97, 3, 225, 7, 109, 376, 354, 281, 261, 508, 188, 93,
          516, 538,
        ],
        [
          159, 228, 86, 567, 584, 406, 59, 434, 299, 30, 500, 275, 199, 84, 206,
          539, 115, 410, 233, 169, 297, 335, 247, 246, 126, 559, 412, 271, 196,
          502, 48, 594, 308, 370, 443, 417, 313, 42, 262, 96, 364, 468, 178,
          329, 23, 557, 256, 403, 514, 519, 55, 341, 461, 114, 558, 27, 232, 20,
          569, 223, 184, 315, 349, 46, 204, 458, 445, 166, 60, 383, 10, 144,
          261, 129, 43, 482, 319, 548, 229, 399, 295, 91, 238, 457, 508, 310,
          66, 40, 435, 26, 194, 85, 444, 583, 177, 586, 430, 377, 553, 8, 290,
          555, 456, 165, 255, 524, 324, 427, 440, 374, 69, 472, 113, 418, 285,
          426, 408, 156, 122, 220, 137, 192, 49, 350, 274, 353, 542, 552, 368,
          543, 454, 146, 507, 470, 388, 139, 280, 532, 83, 511, 463, 386, 150,
          81, 108, 327, 251, 259, 499, 11, 47, 209, 371, 272, 483, 425, 576,
          293, 323, 172, 72, 79, 538, 318, 380, 201, 321, 0, 288, 382, 234, 488,
          12, 431, 578, 360, 102, 326, 283, 211, 580, 267, 307, 317, 61, 171,
          534, 466, 170, 550, 3, 574, 175, 253, 190, 489, 509, 575, 487, 467,
          97, 563, 516, 38, 424, 183, 186, 88, 598, 363, 544, 496, 378, 537,
          331, 167, 304, 273, 407, 401, 182, 533, 138, 372, 244, 257, 438, 411,
          53, 252, 260, 179, 375, 599, 545, 359, 22, 526, 2, 216, 250, 405, 330,
          176, 549, 94, 479, 198, 506, 400, 311, 277, 394, 465, 591, 245, 282,
          64, 18, 109, 303, 141, 237, 100, 564, 195, 366, 74, 95, 145, 119, 289,
          107, 164, 68, 142, 593, 125, 337, 475, 101, 286, 477, 395, 287, 342,
          207, 143, 351, 25, 187, 151, 6, 422, 415, 13, 391, 309, 132, 473, 203,
          592, 413, 376, 568, 338, 241, 279, 116, 76, 51, 385, 520, 389, 149, 5,
          442, 130, 44, 230, 200, 153, 205, 24, 531, 14, 397, 118, 518, 258,
          595, 517, 73, 58, 248, 133, 185, 155, 429, 354, 120, 219, 469, 231,
          560, 453, 346, 497, 226, 104, 110, 121, 498, 369, 312, 356, 147, 390,
          396, 54, 455, 92, 305, 75, 384, 221, 197, 188, 224, 1, 294, 181, 45,
          344, 588, 127, 157, 340, 202, 462, 148, 168, 90, 436, 528, 352, 296,
          589, 332, 112, 521, 239, 339, 62, 50, 111, 447, 98, 416, 432, 562,
          546, 65, 268, 82, 565, 56, 135, 191, 585, 536, 301, 263, 556, 571,
          495, 140, 306, 314, 16, 481, 441, 77, 21, 398, 322, 485, 474, 510,
          554, 449, 547, 78, 419, 393, 210, 225, 32, 29, 336, 270, 540, 173,
          123, 80, 39, 582, 235, 551, 163, 414, 161, 361, 484, 87, 33, 343, 134,
          523, 212, 174, 529, 525, 265, 31, 218, 15, 19, 460, 501, 358, 522,
          124, 300, 158, 362, 99, 52, 215, 334, 504, 492, 367, 402, 503, 284, 7,
          28, 154, 513, 381, 345, 291, 387, 106, 117, 269, 316, 70, 490, 535,
          365, 103, 93, 152, 421, 451, 392, 452, 328, 404, 9, 227, 423, 325,
          573, 180, 240, 71, 302, 222, 572, 37, 439, 128, 131, 355, 264, 266,
          162, 208, 590, 409, 596, 213, 17, 34, 347, 4, 476, 579, 581, 515, 193,
          493, 357, 333, 41, 505, 527, 446, 89, 35, 189, 566, 57, 486, 480, 433,
          428, 448, 105, 320, 577, 278, 541, 491, 214, 530, 494, 236, 379, 242,
          249, 478, 587, 243, 420, 67, 597, 348, 36, 281, 63, 471, 570, 373,
          512, 254, 136, 437, 459, 292, 464, 276, 298, 450, 561, 160, 217,
        ],
        [
          373, 103, 597, 573, 390, 39, 27, 130, 514, 21, 354, 30, 53, 262, 406,
          529, 452, 2, 451, 319, 445, 384, 135, 588, 10, 482, 410, 512, 374,
          405, 255, 520, 188, 586, 379, 19, 22, 394, 13, 145, 175, 274, 385,
          551, 259, 116, 549, 568, 504, 427, 430, 572, 579, 210, 473, 565, 14,
          113, 361, 125, 380, 229, 428, 71, 55, 109, 431, 105, 592, 270, 212,
          207, 49, 545, 554, 585, 523, 393, 432, 388, 308, 346, 106, 138, 3,
          143, 342, 206, 150, 112, 218, 241, 340, 154, 123, 297, 465, 332, 337,
          232, 95, 90, 167, 383, 530, 57, 38, 4, 510, 285, 358, 278, 416, 307,
          63, 240, 553, 560, 333, 433, 531, 153, 449, 381, 187, 418, 389, 152,
          356, 503, 323, 396, 448, 110, 227, 341, 243, 417, 375, 478, 170, 140,
          382, 99, 403, 322, 386, 74, 336, 79, 256, 501, 466, 438, 566, 258,
          238, 317, 493, 500, 201, 84, 226, 192, 236, 567, 469, 142, 484, 434,
          163, 190, 538, 362, 439, 479, 304, 173, 460, 282, 587, 48, 254, 377,
          164, 576, 119, 25, 46, 453, 526, 169, 213, 582, 104, 542, 186, 594,
          121, 96, 291, 197, 42, 314, 569, 311, 310, 494, 519, 7, 219, 260, 488,
          571, 115, 208, 563, 467, 407, 62, 541, 18, 272, 511, 82, 557, 450,
          559, 365, 281, 486, 44, 580, 159, 293, 476, 224, 69, 41, 490, 168,
          562, 527, 296, 86, 172, 483, 457, 316, 575, 273, 179, 561, 148, 0,
          489, 180, 312, 242, 114, 421, 47, 320, 131, 564, 276, 43, 263, 149,
          209, 32, 359, 129, 400, 288, 436, 499, 583, 221, 50, 298, 547, 156,
          540, 75, 102, 35, 196, 420, 134, 61, 536, 185, 315, 244, 264, 151,
          117, 287, 525, 268, 313, 444, 552, 45, 598, 73, 331, 395, 363, 578,
          85, 128, 415, 252, 161, 517, 491, 8, 330, 6, 355, 461, 532, 550, 182,
          34, 474, 5, 543, 98, 447, 397, 177, 470, 437, 266, 70, 392, 271, 306,
          222, 589, 574, 402, 20, 157, 462, 424, 91, 555, 205, 225, 294, 65,
          202, 94, 217, 326, 251, 435, 107, 139, 339, 165, 265, 498, 101, 328,
          269, 275, 468, 539, 1, 516, 480, 245, 441, 321, 11, 289, 509, 584,
          442, 422, 77, 204, 351, 497, 66, 372, 446, 127, 352, 246, 370, 334,
          171, 233, 335, 111, 17, 58, 577, 12, 590, 81, 200, 387, 537, 411, 29,
          481, 464, 558, 595, 176, 399, 158, 591, 283, 199, 136, 522, 338, 413,
          162, 261, 475, 371, 487, 122, 83, 126, 147, 318, 502, 33, 349, 408,
          174, 248, 189, 513, 456, 216, 544, 89, 193, 495, 97, 257, 198, 505,
          54, 72, 26, 181, 286, 443, 548, 166, 570, 228, 344, 277, 183, 546, 78,
          414, 88, 93, 350, 267, 67, 141, 348, 378, 144, 459, 37, 325, 80, 345,
          440, 211, 429, 515, 234, 253, 303, 184, 247, 108, 369, 132, 521, 194,
          367, 364, 533, 301, 357, 56, 477, 155, 124, 284, 305, 133, 366, 471,
          214, 220, 230, 36, 454, 249, 423, 51, 343, 472, 235, 299, 398, 455,
          391, 302, 534, 599, 16, 412, 535, 237, 426, 324, 68, 9, 401, 295, 507,
          76, 52, 92, 40, 496, 279, 376, 360, 556, 239, 118, 31, 250, 463, 528,
          404, 195, 368, 24, 223, 59, 292, 524, 506, 191, 425, 290, 329, 203,
          120, 160, 485, 64, 327, 581, 280, 60, 596, 178, 309, 87, 300, 146, 23,
          492, 137, 347, 593, 215, 231, 15, 28, 518, 419, 100, 508, 409, 458,
          353,
        ],
        [
          136, 249, 458, 280, 236, 19, 100, 199, 207, 524, 331, 5, 390, 572,
          511, 41, 204, 9, 452, 525, 344, 237, 342, 242, 392, 127, 430, 363,
          132, 506, 351, 30, 507, 352, 55, 198, 240, 308, 329, 526, 535, 544,
          32, 89, 213, 391, 326, 398, 565, 478, 407, 113, 83, 169, 17, 533, 194,
          182, 1, 102, 327, 403, 465, 550, 467, 490, 252, 412, 290, 219, 97,
          259, 388, 191, 233, 134, 401, 414, 442, 423, 135, 29, 520, 131, 404,
          360, 573, 586, 508, 556, 433, 114, 560, 149, 103, 399, 6, 225, 2, 257,
          87, 92, 504, 201, 229, 372, 159, 279, 293, 230, 4, 195, 60, 157, 456,
          402, 353, 396, 364, 130, 44, 128, 564, 277, 115, 475, 217, 99, 373,
          71, 155, 380, 21, 340, 267, 464, 291, 35, 274, 197, 151, 118, 145, 40,
          232, 579, 540, 574, 137, 144, 449, 90, 346, 301, 69, 322, 432, 79,
          284, 64, 248, 68, 532, 47, 80, 78, 153, 496, 203, 514, 187, 247, 375,
          105, 305, 88, 122, 67, 286, 418, 554, 395, 39, 54, 33, 558, 152, 354,
          264, 571, 536, 288, 285, 186, 382, 347, 95, 45, 409, 268, 49, 7, 397,
          15, 313, 466, 463, 170, 295, 10, 541, 545, 491, 320, 592, 106, 584,
          38, 304, 517, 527, 596, 62, 415, 101, 139, 13, 567, 86, 239, 215, 349,
          126, 519, 512, 559, 66, 176, 457, 417, 174, 271, 445, 568, 350, 81,
          116, 316, 416, 321, 37, 543, 50, 34, 523, 20, 75, 306, 298, 330, 317,
          51, 477, 594, 358, 488, 426, 56, 357, 165, 173, 12, 429, 188, 214,
          250, 292, 76, 52, 343, 48, 142, 389, 580, 154, 276, 84, 531, 243, 534,
          123, 356, 484, 245, 455, 269, 355, 393, 96, 208, 158, 133, 361, 53,
          141, 108, 590, 498, 156, 235, 434, 324, 244, 539, 31, 241, 591, 119,
          323, 148, 294, 577, 0, 436, 246, 109, 425, 529, 447, 163, 581, 419,
          470, 253, 289, 168, 146, 595, 278, 192, 345, 206, 537, 325, 370, 427,
          181, 439, 489, 338, 561, 518, 557, 421, 266, 451, 309, 283, 11, 226,
          521, 296, 333, 424, 205, 177, 479, 46, 315, 450, 400, 318, 422, 212,
          336, 515, 319, 413, 231, 238, 411, 251, 386, 440, 121, 260, 410, 431,
          287, 469, 263, 93, 570, 420, 74, 497, 193, 493, 65, 314, 172, 562, 63,
          460, 24, 328, 483, 303, 374, 598, 546, 473, 381, 563, 384, 94, 481,
          272, 27, 161, 180, 61, 576, 254, 36, 164, 117, 459, 377, 555, 474,
          175, 461, 57, 503, 104, 435, 255, 23, 275, 575, 485, 339, 365, 129,
          553, 438, 472, 310, 383, 211, 371, 224, 147, 228, 256, 111, 589, 124,
          179, 502, 183, 510, 270, 551, 281, 443, 16, 428, 337, 486, 494, 437,
          367, 72, 300, 43, 210, 98, 200, 184, 379, 209, 480, 70, 138, 218, 495,
          202, 522, 227, 513, 77, 335, 597, 492, 476, 150, 167, 471, 566, 578,
          261, 588, 28, 454, 462, 3, 547, 448, 222, 538, 265, 332, 334, 302,
          189, 234, 585, 378, 85, 405, 311, 394, 185, 223, 453, 8, 162, 91, 593,
          582, 528, 125, 509, 143, 282, 500, 516, 18, 307, 499, 530, 216, 552,
          112, 160, 221, 220, 299, 42, 59, 273, 387, 25, 587, 140, 441, 569,
          369, 408, 107, 190, 549, 505, 73, 406, 58, 26, 366, 120, 487, 262,
          110, 297, 359, 368, 196, 501, 444, 348, 482, 341, 446, 178, 548, 362,
          312, 376, 22, 599, 82, 583, 468, 385, 258, 14, 166, 542, 171,
        ],
        [
          597, 262, 336, 203, 269, 503, 413, 31, 456, 556, 317, 404, 517, 370,
          46, 332, 415, 217, 460, 12, 134, 433, 569, 88, 566, 469, 312, 364,
          108, 412, 293, 563, 531, 209, 534, 581, 132, 136, 544, 72, 137, 568,
          304, 359, 183, 20, 147, 484, 41, 71, 100, 306, 49, 258, 106, 157, 504,
          216, 501, 236, 63, 101, 150, 91, 583, 379, 104, 518, 243, 351, 178,
          218, 277, 382, 160, 559, 60, 356, 366, 74, 449, 279, 354, 532, 367, 5,
          310, 96, 4, 488, 529, 572, 51, 6, 257, 239, 385, 590, 506, 346, 320,
          321, 523, 259, 326, 555, 396, 116, 592, 82, 186, 235, 510, 15, 328,
          145, 331, 121, 390, 574, 432, 152, 119, 524, 294, 229, 67, 109, 551,
          530, 142, 146, 128, 474, 275, 256, 97, 352, 9, 584, 156, 570, 378, 28,
          107, 241, 127, 79, 177, 240, 196, 483, 457, 40, 520, 223, 244, 437,
          408, 528, 179, 204, 11, 113, 207, 35, 212, 126, 305, 153, 206, 388,
          549, 69, 350, 228, 494, 435, 329, 407, 448, 505, 542, 567, 144, 421,
          468, 394, 14, 409, 543, 68, 596, 78, 214, 478, 447, 420, 102, 334, 32,
          58, 48, 467, 341, 381, 292, 2, 580, 47, 130, 545, 392, 282, 120, 499,
          283, 124, 353, 242, 514, 232, 465, 585, 302, 299, 210, 83, 159, 266,
          208, 59, 541, 215, 513, 571, 247, 521, 496, 376, 466, 224, 254, 184,
          451, 56, 403, 297, 490, 3, 90, 151, 24, 163, 75, 372, 249, 340, 86,
          161, 245, 45, 168, 375, 110, 10, 234, 201, 562, 316, 461, 472, 92, 38,
          233, 250, 565, 470, 526, 238, 213, 103, 189, 261, 445, 169, 185, 512,
          1, 80, 347, 29, 422, 17, 593, 481, 582, 598, 295, 554, 278, 418, 33,
          0, 594, 362, 19, 333, 122, 188, 425, 197, 25, 539, 205, 535, 485, 373,
          301, 176, 276, 473, 459, 482, 138, 270, 57, 338, 358, 498, 54, 424,
          427, 576, 391, 345, 264, 355, 50, 308, 298, 377, 23, 135, 230, 303,
          263, 64, 94, 309, 166, 26, 285, 22, 416, 419, 587, 558, 402, 509, 324,
          497, 200, 143, 194, 380, 70, 289, 480, 112, 287, 369, 374, 65, 423,
          290, 525, 55, 486, 552, 476, 174, 225, 182, 538, 463, 158, 365, 363,
          536, 44, 171, 575, 191, 149, 286, 219, 383, 170, 154, 36, 117, 522,
          599, 464, 588, 162, 564, 578, 371, 227, 314, 546, 410, 477, 349, 389,
          39, 140, 125, 165, 265, 491, 141, 255, 500, 131, 411, 111, 318, 342,
          172, 180, 274, 325, 34, 440, 589, 272, 454, 311, 139, 489, 444, 547,
          271, 319, 66, 114, 307, 442, 62, 553, 133, 595, 337, 399, 492, 533,
          291, 439, 95, 296, 175, 284, 455, 368, 450, 417, 231, 118, 192, 511,
          164, 73, 414, 327, 89, 401, 443, 495, 99, 129, 81, 487, 173, 430, 181,
          251, 398, 579, 87, 561, 267, 343, 288, 315, 37, 16, 7, 237, 187, 260,
          42, 434, 115, 246, 52, 458, 436, 77, 198, 475, 222, 253, 441, 105,
          167, 446, 519, 508, 85, 397, 348, 76, 330, 220, 8, 400, 395, 557, 393,
          43, 98, 573, 405, 199, 195, 591, 190, 30, 586, 540, 431, 27, 577, 527,
          479, 507, 453, 226, 386, 300, 123, 84, 281, 193, 18, 335, 13, 384, 61,
          537, 313, 493, 344, 360, 202, 428, 248, 211, 502, 426, 515, 93, 550,
          516, 452, 322, 548, 387, 339, 429, 280, 273, 268, 438, 361, 252, 462,
          53, 21, 221, 155, 471, 148, 357, 323, 560, 406,
        ],
      ],
      c2: [
        [
          24, 60, 236, 151, 21, 598, 257, 256, 128, 411, 155, 420, 374, 116,
          538, 344, 307, 99, 452, 396, 47, 137, 145, 113, 527, 104, 129, 568,
          202, 370, 550, 205, 15, 542, 265, 514, 560, 473, 207, 481, 267, 359,
          454, 401, 57, 519, 36, 385, 22, 587, 245, 442, 67, 471, 547, 279, 409,
          117, 323, 541, 107, 6, 45, 16, 193, 278, 384, 296, 331, 464, 571, 581,
          191, 310, 30, 440, 591, 593, 111, 590, 26, 450, 126, 575, 11, 368,
          248, 269, 197, 386, 253, 285, 537, 485, 246, 12, 173, 52, 189, 543,
          313, 125, 495, 540, 122, 585, 533, 250, 439, 65, 573, 482, 262, 599,
          448, 105, 551, 1, 212, 184, 342, 544, 321, 345, 290, 327, 139, 10,
          562, 457, 149, 106, 453, 421, 28, 586, 382, 561, 355, 119, 42, 402,
          451, 588, 422, 163, 31, 264, 219, 572, 518, 369, 465, 376, 100, 103,
          121, 252, 115, 432, 535, 458, 314, 23, 534, 488, 84, 7, 494, 120, 170,
          295, 211, 204, 570, 259, 363, 275, 394, 566, 437, 234, 3, 397, 523,
          110, 50, 260, 156, 332, 477, 553, 435, 235, 293, 379, 548, 83, 206,
          383, 164, 98, 268, 223, 135, 443, 404, 222, 40, 39, 215, 564, 158,
          233, 509, 70, 512, 375, 9, 366, 392, 546, 300, 501, 552, 291, 539,
          576, 529, 322, 143, 371, 25, 325, 472, 169, 338, 388, 292, 423, 497,
          69, 254, 97, 270, 373, 508, 238, 563, 73, 133, 364, 167, 297, 281,
          312, 444, 436, 41, 525, 463, 299, 251, 425, 403, 414, 531, 428, 487,
          17, 441, 305, 569, 526, 301, 513, 146, 582, 74, 186, 162, 174, 157,
          595, 460, 190, 2, 475, 102, 455, 62, 449, 324, 78, 367, 478, 141, 309,
          231, 108, 480, 209, 277, 153, 341, 574, 148, 579, 391, 109, 361, 237,
          506, 362, 51, 430, 354, 372, 79, 468, 334, 37, 418, 517, 522, 462,
          554, 493, 81, 358, 419, 29, 565, 152, 38, 227, 520, 181, 340, 274,
          407, 474, 88, 417, 486, 96, 27, 241, 242, 343, 216, 499, 429, 315,
          304, 127, 426, 183, 48, 194, 492, 91, 94, 175, 328, 147, 273, 347,
          140, 76, 114, 532, 395, 298, 589, 287, 357, 20, 424, 498, 406, 188,
          72, 390, 578, 87, 214, 280, 213, 500, 503, 516, 399, 580, 320, 286,
          249, 349, 507, 348, 220, 247, 192, 43, 469, 398, 515, 55, 187, 592,
          77, 90, 583, 201, 63, 434, 131, 346, 271, 19, 66, 350, 160, 0, 476,
          317, 584, 410, 180, 240, 58, 530, 61, 381, 491, 336, 171, 557, 318,
          232, 208, 377, 438, 92, 521, 136, 433, 68, 144, 335, 484, 196, 567,
          177, 378, 266, 14, 244, 594, 311, 82, 577, 289, 545, 75, 46, 555, 272,
          263, 549, 415, 431, 356, 124, 86, 56, 18, 166, 182, 308, 35, 132, 319,
          203, 59, 456, 330, 44, 387, 218, 380, 353, 470, 479, 505, 393, 89, 85,
          365, 195, 558, 405, 413, 283, 339, 150, 53, 172, 8, 178, 221, 302,
          168, 93, 412, 142, 176, 511, 255, 123, 49, 427, 199, 466, 467, 224,
          337, 461, 118, 416, 179, 326, 284, 528, 524, 217, 226, 64, 228, 138,
          4, 71, 282, 13, 288, 243, 5, 112, 559, 510, 54, 496, 489, 80, 230,
          200, 210, 446, 294, 504, 459, 536, 408, 445, 483, 556, 101, 333, 306,
          360, 33, 161, 159, 239, 185, 400, 225, 34, 95, 32, 303, 502, 198, 229,
          276, 389, 165, 130, 316, 258, 596, 261, 351, 154, 329, 447, 490, 597,
          352, 134,
        ],
        [
          193, 254, 206, 295, 365, 157, 144, 483, 293, 373, 314, 327, 465, 89,
          48, 431, 294, 291, 86, 505, 565, 432, 492, 502, 361, 420, 148, 209,
          189, 425, 169, 576, 159, 548, 375, 196, 326, 79, 493, 40, 31, 331, 57,
          119, 403, 552, 524, 485, 41, 78, 166, 487, 462, 97, 133, 413, 547, 56,
          147, 433, 484, 322, 441, 321, 580, 102, 225, 490, 309, 6, 334, 288,
          47, 190, 104, 402, 25, 386, 298, 259, 342, 81, 290, 269, 478, 264, 69,
          337, 494, 275, 127, 316, 430, 323, 435, 544, 116, 453, 523, 124, 521,
          586, 378, 162, 395, 32, 67, 161, 510, 338, 292, 482, 428, 243, 406,
          10, 444, 27, 520, 358, 59, 497, 245, 267, 594, 515, 143, 272, 250,
          541, 123, 426, 305, 447, 0, 187, 115, 158, 117, 516, 249, 511, 513,
          367, 53, 152, 387, 567, 393, 416, 529, 324, 312, 219, 512, 247, 36,
          151, 205, 404, 106, 173, 550, 539, 276, 73, 347, 82, 235, 407, 507,
          15, 474, 150, 499, 325, 504, 356, 62, 34, 488, 355, 231, 93, 194, 357,
          52, 87, 112, 212, 135, 418, 545, 236, 214, 349, 450, 139, 424, 371,
          339, 155, 105, 287, 240, 475, 72, 1, 398, 588, 263, 464, 391, 146,
          218, 470, 20, 427, 518, 210, 66, 350, 208, 534, 8, 216, 60, 278, 45,
          165, 469, 385, 311, 239, 17, 568, 213, 134, 174, 129, 201, 14, 394,
          125, 160, 476, 251, 553, 44, 389, 13, 233, 522, 335, 85, 80, 110, 454,
          241, 599, 392, 30, 99, 372, 368, 185, 232, 172, 363, 156, 560, 571,
          300, 401, 543, 480, 270, 63, 354, 237, 120, 573, 486, 422, 346, 230,
          359, 2, 11, 83, 383, 28, 577, 281, 340, 302, 167, 192, 9, 455, 434,
          596, 100, 557, 95, 75, 457, 3, 514, 248, 224, 582, 140, 33, 42, 21,
          554, 296, 71, 429, 343, 262, 329, 449, 410, 498, 24, 255, 437, 286,
          303, 561, 265, 191, 202, 396, 138, 473, 103, 136, 182, 101, 22, 451,
          283, 536, 217, 220, 271, 253, 578, 170, 517, 211, 246, 184, 443, 178,
          315, 421, 88, 200, 360, 583, 414, 564, 364, 121, 503, 446, 501, 258,
          198, 130, 439, 149, 585, 362, 405, 274, 540, 181, 12, 92, 344, 456,
          471, 459, 223, 306, 221, 584, 154, 549, 43, 16, 226, 370, 412, 438,
          37, 384, 374, 572, 222, 452, 153, 592, 442, 332, 409, 593, 598, 526,
          348, 197, 448, 280, 90, 319, 304, 215, 531, 411, 118, 301, 574, 113,
          467, 77, 528, 68, 186, 29, 297, 94, 64, 351, 458, 176, 381, 23, 388,
          317, 419, 91, 399, 461, 525, 546, 519, 538, 128, 108, 590, 496, 566,
          591, 558, 188, 563, 489, 122, 318, 530, 506, 38, 126, 380, 508, 341,
          50, 7, 345, 229, 460, 177, 369, 597, 491, 268, 299, 180, 98, 65, 284,
          352, 35, 84, 445, 109, 400, 581, 336, 199, 252, 328, 500, 579, 46,
          261, 542, 589, 415, 19, 562, 587, 417, 227, 70, 479, 556, 49, 333,
          175, 234, 141, 463, 242, 273, 313, 320, 131, 289, 5, 132, 257, 207,
          111, 282, 423, 39, 307, 164, 569, 114, 256, 408, 308, 468, 376, 285,
          532, 472, 570, 55, 244, 379, 74, 203, 310, 58, 145, 477, 440, 195,
          179, 163, 390, 183, 575, 238, 279, 51, 535, 266, 481, 533, 509, 397,
          495, 260, 555, 61, 527, 4, 537, 137, 76, 54, 26, 366, 466, 277, 171,
          330, 436, 228, 204, 353, 382, 18, 142, 107, 377, 168, 96, 595, 559,
          551,
        ],
        [
          12, 167, 492, 26, 136, 447, 355, 274, 61, 367, 585, 500, 403, 41, 140,
          333, 528, 535, 474, 441, 442, 261, 451, 45, 534, 393, 150, 327, 506,
          513, 491, 39, 103, 245, 551, 52, 161, 128, 121, 400, 361, 565, 572,
          228, 322, 364, 210, 529, 432, 499, 554, 538, 47, 553, 456, 308, 465,
          566, 209, 410, 205, 338, 517, 259, 318, 127, 262, 241, 72, 98, 180,
          448, 591, 166, 507, 289, 377, 107, 151, 86, 313, 49, 362, 53, 195,
          147, 352, 466, 126, 257, 509, 518, 325, 504, 125, 192, 426, 249, 57,
          515, 505, 156, 9, 599, 445, 93, 310, 401, 75, 570, 172, 326, 43, 163,
          497, 394, 316, 540, 15, 181, 154, 407, 144, 358, 527, 212, 247, 139,
          337, 574, 273, 446, 250, 288, 68, 187, 263, 462, 137, 185, 285, 594,
          422, 111, 371, 399, 197, 340, 23, 482, 295, 516, 168, 290, 537, 88,
          271, 314, 230, 459, 231, 155, 317, 211, 58, 469, 134, 158, 319, 478,
          581, 1, 420, 73, 385, 160, 188, 17, 191, 232, 94, 227, 114, 38, 164,
          190, 586, 493, 200, 489, 558, 331, 521, 254, 281, 557, 206, 106, 184,
          533, 522, 417, 44, 330, 398, 252, 380, 256, 260, 204, 315, 596, 46,
          359, 54, 11, 258, 201, 567, 84, 34, 438, 92, 122, 437, 216, 351, 369,
          341, 235, 120, 547, 363, 42, 461, 514, 293, 90, 50, 69, 425, 520, 301,
          584, 416, 323, 3, 87, 29, 36, 419, 590, 27, 402, 171, 220, 6, 226, 33,
          251, 370, 76, 356, 130, 99, 302, 349, 357, 406, 291, 10, 159, 305,
          117, 562, 91, 411, 131, 162, 222, 60, 108, 550, 83, 390, 348, 22, 435,
          372, 178, 598, 237, 353, 444, 238, 543, 588, 501, 450, 354, 213, 294,
          468, 345, 560, 387, 142, 193, 119, 415, 472, 116, 101, 464, 194, 25,
          214, 541, 296, 146, 189, 182, 480, 312, 346, 368, 397, 559, 563, 342,
          16, 539, 64, 595, 48, 427, 284, 85, 112, 335, 510, 512, 463, 21, 395,
          457, 580, 508, 471, 276, 424, 177, 240, 549, 418, 80, 350, 298, 132,
          561, 583, 79, 145, 280, 593, 556, 502, 266, 587, 19, 66, 246, 141,
          152, 347, 434, 124, 386, 458, 490, 392, 494, 525, 344, 97, 575, 405,
          196, 568, 123, 102, 460, 148, 503, 299, 282, 224, 423, 373, 439, 272,
          495, 309, 376, 133, 96, 264, 55, 409, 332, 153, 334, 267, 484, 414,
          452, 379, 283, 176, 215, 555, 569, 82, 207, 324, 270, 430, 170, 118,
          157, 404, 431, 234, 592, 488, 526, 597, 242, 63, 109, 546, 391, 384,
          244, 388, 74, 239, 307, 531, 477, 24, 40, 532, 389, 473, 71, 225, 544,
          203, 138, 18, 523, 467, 365, 143, 545, 8, 339, 375, 32, 275, 552, 149,
          300, 303, 481, 198, 428, 571, 579, 51, 576, 440, 35, 278, 20, 5, 582,
          255, 179, 253, 243, 89, 297, 530, 304, 321, 77, 67, 311, 366, 2, 0,
          218, 269, 217, 81, 378, 4, 183, 95, 455, 236, 62, 175, 483, 221, 475,
          28, 202, 443, 277, 233, 186, 519, 59, 165, 486, 396, 105, 56, 306,
          199, 470, 113, 31, 564, 70, 381, 413, 268, 436, 13, 511, 485, 208,
          487, 573, 524, 279, 78, 577, 320, 454, 498, 496, 129, 292, 421, 104,
          578, 476, 248, 287, 7, 65, 536, 229, 100, 374, 110, 360, 548, 382,
          453, 589, 37, 286, 336, 429, 219, 412, 408, 135, 265, 173, 14, 328,
          223, 449, 383, 542, 433, 479, 30, 329, 174, 169, 115, 343,
        ],
        [
          542, 393, 261, 524, 420, 103, 521, 532, 311, 533, 581, 223, 217, 519,
          452, 272, 433, 69, 4, 210, 78, 246, 179, 176, 462, 180, 377, 319, 323,
          163, 410, 81, 36, 90, 333, 98, 41, 389, 196, 353, 266, 391, 68, 61,
          589, 376, 281, 349, 188, 568, 52, 112, 91, 267, 131, 486, 396, 378,
          401, 444, 498, 305, 504, 425, 137, 74, 40, 570, 303, 364, 501, 177,
          599, 457, 387, 511, 158, 336, 170, 151, 487, 197, 408, 327, 388, 291,
          225, 427, 250, 169, 413, 340, 557, 450, 6, 16, 114, 351, 102, 439,
          165, 276, 100, 270, 497, 587, 244, 304, 467, 306, 551, 574, 295, 465,
          13, 139, 3, 213, 395, 54, 314, 324, 367, 269, 8, 302, 141, 438, 342,
          278, 417, 189, 582, 46, 193, 51, 284, 136, 464, 220, 540, 419, 315,
          482, 101, 430, 480, 226, 567, 595, 432, 153, 301, 73, 575, 259, 409,
          255, 583, 316, 331, 517, 530, 446, 275, 320, 537, 479, 71, 218, 144,
          488, 426, 580, 550, 559, 344, 491, 505, 356, 522, 392, 273, 441, 384,
          578, 18, 203, 325, 240, 564, 428, 448, 552, 286, 534, 49, 247, 242,
          294, 17, 361, 227, 35, 593, 283, 536, 83, 174, 79, 58, 563, 148, 313,
          56, 202, 260, 20, 94, 47, 596, 277, 70, 573, 445, 216, 89, 108, 334,
          27, 383, 119, 208, 48, 368, 416, 125, 437, 481, 289, 67, 379, 134,
          454, 554, 152, 512, 370, 75, 53, 205, 442, 120, 515, 415, 252, 328,
          173, 453, 212, 359, 80, 191, 472, 245, 168, 104, 496, 562, 538, 66,
          443, 288, 110, 492, 280, 287, 308, 232, 312, 556, 32, 406, 400, 375,
          518, 566, 44, 10, 414, 431, 86, 509, 233, 363, 345, 535, 62, 458, 268,
          369, 292, 447, 107, 386, 539, 341, 493, 183, 513, 149, 594, 214, 185,
          93, 178, 506, 475, 529, 354, 9, 166, 549, 477, 584, 285, 192, 362,
          547, 572, 37, 546, 296, 449, 194, 167, 339, 548, 335, 121, 527, 147,
          253, 402, 124, 117, 115, 271, 528, 7, 531, 381, 330, 435, 456, 263,
          290, 293, 164, 476, 423, 399, 577, 565, 520, 469, 182, 474, 135, 309,
          72, 23, 429, 588, 33, 318, 300, 127, 590, 502, 460, 29, 440, 358, 499,
          184, 39, 436, 422, 231, 243, 569, 219, 206, 26, 199, 150, 507, 122,
          541, 133, 249, 372, 60, 555, 514, 63, 118, 140, 126, 360, 553, 374,
          297, 138, 198, 585, 503, 489, 241, 591, 332, 22, 237, 274, 19, 25, 77,
          154, 207, 222, 405, 2, 130, 526, 171, 326, 586, 159, 5, 466, 257, 186,
          21, 495, 92, 317, 230, 373, 411, 397, 494, 87, 412, 116, 543, 160,
          264, 211, 337, 545, 338, 463, 350, 299, 59, 298, 45, 483, 544, 560,
          190, 209, 394, 14, 84, 576, 238, 172, 484, 11, 470, 434, 12, 385, 265,
          76, 558, 525, 473, 352, 421, 239, 343, 88, 34, 347, 142, 57, 50, 235,
          228, 204, 113, 30, 132, 15, 162, 85, 157, 500, 99, 322, 254, 105, 43,
          181, 224, 236, 123, 28, 96, 195, 156, 357, 365, 215, 451, 598, 307,
          155, 371, 382, 128, 282, 42, 200, 355, 348, 424, 468, 404, 523, 516,
          592, 459, 248, 508, 38, 97, 251, 418, 403, 455, 597, 561, 407, 64,
          380, 366, 329, 175, 490, 510, 161, 1, 201, 485, 65, 390, 321, 24, 461,
          234, 310, 0, 478, 111, 146, 256, 129, 31, 579, 55, 471, 82, 262, 109,
          229, 571, 398, 279, 106, 95, 187, 346, 145, 258, 221, 143,
        ],
        [
          424, 27, 390, 494, 389, 215, 9, 503, 155, 380, 60, 475, 364, 427, 59,
          294, 251, 444, 462, 353, 413, 591, 112, 587, 159, 461, 115, 116, 21,
          558, 325, 489, 16, 484, 572, 133, 146, 401, 286, 599, 123, 186, 164,
          560, 149, 392, 344, 113, 549, 306, 326, 370, 8, 247, 423, 184, 194,
          577, 598, 473, 496, 15, 281, 497, 391, 76, 177, 539, 249, 178, 255,
          39, 425, 34, 452, 191, 545, 231, 229, 582, 99, 128, 529, 110, 318,
          358, 203, 372, 119, 310, 418, 551, 219, 49, 519, 446, 40, 448, 36,
          513, 339, 144, 351, 346, 387, 517, 366, 365, 507, 361, 91, 201, 66,
          454, 464, 552, 556, 182, 190, 244, 148, 562, 398, 227, 106, 571, 114,
          340, 406, 166, 208, 441, 498, 356, 405, 480, 314, 232, 120, 57, 594,
          469, 80, 95, 79, 367, 569, 183, 357, 237, 275, 324, 158, 100, 323,
          459, 135, 118, 443, 541, 371, 202, 196, 537, 240, 72, 121, 261, 491,
          50, 283, 198, 156, 334, 82, 532, 585, 412, 253, 550, 160, 289, 197,
          295, 122, 30, 593, 329, 131, 250, 212, 187, 236, 63, 511, 287, 477,
          88, 456, 271, 312, 11, 596, 349, 285, 139, 501, 333, 466, 402, 239,
          505, 222, 553, 542, 127, 48, 32, 500, 482, 230, 154, 172, 393, 457,
          252, 245, 290, 386, 101, 276, 74, 168, 280, 41, 319, 343, 449, 136,
          305, 467, 10, 428, 307, 546, 284, 384, 51, 451, 162, 328, 555, 490,
          442, 429, 479, 521, 7, 597, 383, 540, 259, 574, 43, 125, 233, 143,
          368, 302, 404, 301, 35, 313, 445, 395, 331, 409, 520, 150, 180, 84,
          488, 359, 502, 170, 223, 439, 509, 504, 330, 235, 209, 527, 174, 533,
          117, 518, 42, 69, 535, 564, 291, 175, 2, 109, 145, 486, 523, 46, 421,
          126, 104, 173, 460, 37, 216, 317, 472, 207, 438, 308, 214, 108, 447,
          97, 561, 153, 377, 548, 14, 53, 579, 269, 415, 506, 265, 583, 508,
          568, 89, 288, 592, 431, 315, 440, 105, 241, 217, 107, 487, 394, 26,
          322, 321, 129, 403, 12, 369, 584, 416, 516, 352, 544, 495, 199, 138,
          81, 38, 70, 388, 75, 22, 396, 220, 419, 483, 65, 218, 23, 67, 195,
          510, 531, 580, 263, 435, 363, 92, 567, 337, 373, 350, 335, 246, 277,
          58, 573, 90, 151, 221, 78, 274, 468, 342, 433, 575, 6, 86, 463, 430,
          515, 83, 176, 226, 147, 512, 434, 300, 332, 522, 362, 140, 565, 18,
          73, 465, 426, 458, 45, 499, 142, 320, 536, 514, 400, 376, 455, 528,
          213, 44, 526, 282, 581, 256, 54, 478, 262, 589, 476, 52, 20, 87, 547,
          264, 228, 341, 481, 268, 19, 254, 293, 188, 382, 493, 345, 432, 192,
          71, 355, 422, 68, 270, 31, 408, 163, 96, 492, 471, 56, 381, 586, 554,
          559, 13, 299, 347, 111, 132, 258, 436, 55, 224, 410, 576, 566, 296,
          316, 141, 379, 590, 94, 414, 563, 309, 538, 420, 165, 29, 62, 407,
          534, 385, 98, 24, 453, 64, 543, 557, 3, 267, 204, 61, 327, 238, 28,
          311, 437, 485, 266, 0, 525, 378, 134, 189, 470, 102, 524, 348, 243,
          211, 234, 4, 595, 152, 77, 33, 273, 530, 338, 417, 5, 17, 225, 206,
          298, 278, 272, 257, 450, 210, 1, 297, 205, 193, 179, 200, 167, 169,
          85, 578, 130, 474, 279, 157, 137, 161, 25, 360, 181, 397, 303, 374,
          248, 242, 354, 260, 375, 124, 304, 399, 103, 93, 570, 292, 171, 185,
          588, 411, 47, 336,
        ],
        [
          16, 153, 228, 494, 157, 343, 450, 379, 29, 364, 473, 332, 481, 34,
          456, 505, 35, 391, 89, 9, 90, 188, 131, 42, 485, 488, 394, 443, 555,
          1, 355, 158, 197, 139, 534, 27, 513, 182, 110, 487, 553, 137, 37, 471,
          184, 543, 113, 50, 146, 359, 203, 436, 274, 285, 77, 384, 261, 143,
          247, 190, 446, 462, 329, 165, 246, 11, 171, 195, 294, 163, 514, 499,
          361, 416, 97, 168, 55, 75, 220, 421, 248, 414, 268, 372, 107, 417,
          117, 91, 63, 287, 314, 510, 298, 304, 430, 196, 470, 20, 64, 181, 237,
          81, 345, 69, 88, 79, 393, 383, 373, 239, 400, 432, 73, 486, 8, 402,
          32, 46, 3, 244, 377, 460, 209, 18, 86, 389, 223, 43, 457, 297, 193,
          264, 431, 40, 173, 260, 179, 569, 380, 307, 185, 593, 187, 449, 565,
          213, 437, 496, 484, 144, 482, 590, 577, 592, 313, 539, 15, 524, 584,
          12, 320, 279, 242, 230, 151, 477, 87, 154, 54, 370, 95, 330, 58, 47,
          161, 218, 48, 108, 358, 392, 254, 24, 262, 508, 349, 119, 511, 529,
          229, 284, 438, 215, 419, 172, 549, 321, 387, 7, 367, 124, 525, 83,
          385, 164, 41, 498, 106, 299, 344, 520, 253, 568, 167, 458, 114, 121,
          240, 352, 265, 426, 60, 531, 85, 6, 135, 134, 544, 219, 216, 52, 424,
          236, 289, 467, 94, 339, 530, 191, 362, 238, 536, 396, 39, 598, 333,
          346, 429, 427, 405, 276, 442, 118, 469, 552, 342, 382, 596, 542, 282,
          412, 401, 13, 96, 288, 495, 595, 476, 61, 66, 76, 439, 156, 334, 36,
          245, 551, 522, 136, 409, 434, 519, 4, 448, 30, 440, 347, 150, 554,
          255, 19, 204, 509, 365, 14, 129, 410, 516, 492, 49, 326, 556, 308,
          566, 176, 444, 517, 533, 104, 366, 231, 397, 59, 125, 451, 82, 404,
          464, 214, 68, 591, 149, 98, 192, 206, 474, 491, 461, 189, 478, 340,
          376, 5, 562, 328, 155, 521, 0, 21, 115, 489, 502, 561, 455, 266, 371,
          545, 323, 109, 557, 269, 44, 573, 277, 580, 174, 532, 62, 56, 418,
          234, 547, 138, 567, 398, 210, 278, 445, 341, 571, 348, 270, 316, 452,
          563, 368, 256, 221, 271, 582, 413, 272, 375, 353, 251, 327, 10, 507,
          103, 465, 312, 160, 558, 578, 512, 286, 468, 194, 433, 267, 466, 233,
          80, 559, 589, 497, 292, 208, 243, 142, 337, 527, 423, 570, 133, 537,
          217, 407, 526, 202, 257, 500, 205, 92, 232, 225, 518, 300, 535, 406,
          585, 490, 594, 493, 310, 574, 378, 100, 147, 226, 201, 579, 523, 141,
          408, 324, 148, 175, 207, 420, 145, 506, 501, 258, 386, 504, 483, 415,
          200, 290, 93, 162, 126, 178, 428, 78, 575, 199, 548, 45, 369, 515,
          170, 140, 503, 25, 70, 351, 331, 152, 319, 357, 350, 280, 363, 588,
          177, 335, 293, 259, 74, 111, 538, 132, 250, 390, 249, 540, 128, 275,
          587, 599, 227, 459, 130, 180, 57, 33, 435, 241, 224, 212, 263, 302,
          38, 303, 336, 301, 28, 453, 306, 546, 273, 102, 425, 72, 441, 374, 65,
          597, 159, 586, 354, 252, 572, 71, 291, 356, 116, 296, 99, 222, 169,
          22, 411, 305, 454, 403, 26, 101, 395, 422, 576, 463, 338, 112, 183,
          322, 166, 186, 53, 309, 295, 317, 528, 388, 198, 235, 283, 560, 281,
          583, 360, 120, 564, 447, 381, 399, 550, 475, 325, 67, 311, 541, 581,
          480, 122, 472, 127, 84, 17, 23, 51, 479, 105, 123, 315, 2, 31, 211,
          318,
        ],
      ],
      c3: [
        [
          154, 75, 2, 89, 1, 76, 332, 134, 347, 18, 563, 334, 382, 524, 316,
          274, 449, 305, 521, 455, 480, 428, 356, 193, 572, 536, 446, 453, 121,
          92, 379, 415, 461, 230, 354, 578, 203, 599, 385, 99, 6, 211, 368, 241,
          366, 44, 544, 204, 152, 123, 173, 22, 456, 346, 258, 459, 404, 430,
          54, 479, 238, 395, 598, 33, 207, 288, 435, 485, 539, 122, 215, 586,
          131, 298, 419, 21, 126, 214, 416, 37, 596, 384, 171, 321, 113, 78,
          111, 293, 381, 119, 242, 130, 270, 487, 94, 13, 30, 186, 23, 361, 522,
          414, 567, 227, 490, 589, 261, 470, 508, 491, 60, 137, 10, 339, 84,
          464, 444, 112, 530, 136, 64, 140, 244, 5, 289, 442, 148, 484, 481,
          486, 580, 426, 541, 264, 498, 105, 172, 365, 482, 472, 254, 259, 228,
          185, 132, 153, 52, 545, 155, 429, 102, 164, 433, 43, 4, 88, 107, 448,
          556, 531, 306, 399, 328, 233, 434, 14, 208, 500, 388, 135, 182, 220,
          507, 387, 292, 494, 157, 247, 139, 542, 100, 412, 8, 285, 48, 120,
          184, 505, 465, 199, 340, 138, 90, 420, 353, 127, 398, 520, 63, 569,
          467, 407, 516, 423, 7, 310, 574, 502, 179, 454, 296, 197, 269, 499,
          224, 307, 432, 529, 548, 588, 181, 170, 66, 400, 403, 83, 219, 45,
          281, 265, 391, 303, 413, 348, 279, 518, 104, 221, 562, 245, 473, 196,
          282, 167, 408, 553, 551, 514, 525, 405, 506, 509, 229, 287, 380, 409,
          513, 359, 528, 118, 424, 79, 501, 443, 523, 439, 62, 590, 314, 163,
          12, 471, 246, 330, 116, 489, 271, 223, 59, 534, 344, 222, 77, 28, 178,
          300, 162, 593, 583, 352, 125, 156, 421, 483, 312, 96, 295, 582, 511,
          291, 290, 183, 322, 248, 538, 239, 463, 82, 406, 39, 350, 69, 594,
          533, 180, 275, 577, 564, 143, 360, 61, 342, 256, 19, 56, 438, 98, 571,
          343, 369, 176, 195, 417, 318, 142, 141, 49, 349, 584, 253, 565, 587,
          543, 560, 488, 133, 437, 319, 103, 377, 169, 357, 151, 101, 549, 492,
          243, 351, 249, 283, 11, 313, 73, 517, 320, 374, 477, 36, 189, 547,
          554, 452, 158, 25, 31, 386, 20, 174, 458, 336, 311, 108, 371, 372,
          576, 168, 373, 393, 51, 175, 315, 50, 72, 226, 192, 476, 468, 326,
          308, 16, 375, 445, 202, 198, 304, 65, 277, 70, 510, 110, 273, 205,
          504, 225, 17, 9, 570, 284, 338, 301, 469, 401, 85, 519, 335, 95, 231,
          527, 526, 145, 431, 402, 418, 427, 109, 540, 235, 355, 537, 337, 209,
          97, 194, 114, 397, 436, 457, 462, 32, 237, 146, 216, 144, 552, 80,
          218, 41, 257, 46, 475, 201, 55, 297, 591, 206, 117, 495, 190, 581,
          441, 333, 559, 200, 383, 106, 240, 268, 40, 212, 191, 425, 532, 550,
          91, 165, 585, 67, 558, 358, 561, 34, 232, 38, 128, 364, 236, 267, 363,
          87, 159, 466, 299, 394, 396, 272, 35, 286, 217, 515, 26, 276, 496,
          557, 149, 260, 512, 302, 280, 93, 251, 566, 309, 389, 555, 71, 575,
          392, 497, 57, 24, 278, 161, 362, 327, 450, 331, 86, 177, 324, 81, 3,
          595, 262, 341, 74, 422, 579, 53, 493, 294, 252, 129, 213, 124, 535,
          592, 573, 390, 323, 546, 447, 503, 376, 367, 147, 263, 597, 187, 188,
          451, 115, 411, 150, 568, 29, 410, 255, 47, 440, 317, 42, 378, 15, 166,
          266, 210, 325, 345, 68, 250, 329, 234, 474, 0, 27, 160, 478, 58, 370,
          460,
        ],
        [
          81, 169, 473, 318, 488, 182, 559, 382, 223, 41, 576, 263, 159, 367,
          163, 482, 156, 593, 532, 128, 233, 563, 523, 54, 526, 394, 448, 164,
          590, 493, 278, 333, 95, 554, 462, 76, 121, 294, 60, 73, 364, 469, 348,
          309, 422, 599, 260, 385, 150, 235, 596, 537, 517, 145, 210, 279, 215,
          63, 577, 426, 295, 362, 420, 69, 131, 179, 247, 558, 20, 569, 290,
          365, 183, 378, 544, 168, 3, 479, 579, 270, 581, 239, 368, 21, 433, 46,
          141, 113, 218, 114, 530, 195, 173, 496, 50, 0, 539, 379, 181, 240,
          105, 186, 267, 319, 373, 437, 254, 534, 75, 283, 316, 272, 522, 311,
          323, 472, 229, 224, 587, 198, 232, 458, 336, 57, 464, 485, 425, 66,
          390, 176, 144, 77, 412, 356, 265, 328, 112, 529, 51, 327, 521, 531,
          551, 101, 444, 27, 400, 351, 196, 570, 125, 325, 241, 350, 84, 370,
          151, 158, 527, 89, 584, 299, 234, 110, 533, 139, 310, 1, 541, 116,
          338, 140, 134, 491, 59, 561, 575, 148, 43, 515, 297, 220, 312, 411,
          315, 430, 428, 18, 266, 480, 380, 117, 528, 343, 589, 585, 427, 359,
          583, 111, 555, 484, 591, 65, 512, 586, 403, 360, 172, 217, 347, 330,
          71, 209, 109, 45, 33, 408, 49, 588, 269, 300, 68, 334, 366, 446, 6,
          185, 35, 326, 302, 391, 108, 15, 536, 197, 14, 547, 453, 31, 26, 386,
          124, 519, 222, 132, 393, 133, 490, 502, 171, 99, 545, 303, 208, 48,
          516, 396, 104, 304, 277, 248, 449, 115, 441, 74, 246, 256, 511, 16,
          194, 298, 296, 149, 280, 11, 188, 64, 206, 553, 177, 271, 460, 292,
          291, 126, 504, 342, 8, 500, 44, 346, 249, 231, 486, 494, 259, 10, 384,
          560, 230, 237, 371, 413, 226, 514, 307, 203, 135, 477, 443, 535, 90,
          395, 592, 447, 61, 52, 415, 435, 47, 244, 552, 153, 436, 565, 122, 19,
          207, 439, 345, 38, 375, 506, 352, 102, 475, 58, 293, 574, 213, 355,
          190, 508, 337, 489, 192, 409, 329, 471, 463, 29, 543, 262, 354, 361,
          566, 39, 166, 103, 424, 513, 53, 550, 285, 568, 189, 461, 165, 152, 7,
          377, 451, 67, 250, 487, 478, 313, 193, 255, 468, 578, 42, 72, 317,
          499, 287, 465, 275, 410, 388, 452, 219, 273, 597, 457, 542, 392, 30,
          582, 549, 191, 314, 22, 200, 36, 178, 498, 32, 572, 227, 308, 253,
          483, 228, 85, 238, 286, 9, 214, 414, 594, 495, 225, 143, 505, 86, 160,
          341, 474, 284, 454, 369, 557, 127, 481, 82, 5, 442, 175, 580, 538,
          320, 136, 562, 416, 598, 17, 399, 34, 349, 567, 501, 92, 282, 157, 98,
          13, 204, 398, 251, 123, 289, 397, 93, 40, 243, 87, 257, 24, 404, 216,
          205, 301, 106, 146, 429, 155, 374, 2, 476, 268, 556, 212, 97, 405,
          258, 242, 376, 357, 70, 340, 120, 525, 28, 236, 455, 264, 88, 432,
          161, 383, 245, 401, 440, 305, 353, 510, 100, 503, 407, 450, 78, 518,
          79, 91, 540, 107, 37, 423, 252, 418, 520, 202, 322, 434, 130, 306,
          445, 507, 417, 492, 12, 167, 56, 459, 595, 406, 331, 276, 187, 137,
          381, 344, 274, 546, 456, 339, 199, 389, 466, 142, 387, 118, 129, 358,
          335, 571, 154, 170, 119, 25, 62, 509, 363, 470, 221, 80, 162, 421,
          564, 524, 180, 372, 321, 4, 288, 138, 431, 419, 174, 402, 573, 548,
          201, 324, 94, 438, 281, 96, 211, 184, 23, 467, 261, 83, 147, 497, 332,
          55,
        ],
        [
          432, 60, 559, 553, 123, 418, 126, 491, 499, 46, 36, 197, 422, 222,
          555, 37, 251, 462, 42, 185, 261, 273, 496, 389, 255, 357, 399, 161,
          279, 425, 136, 264, 241, 250, 433, 514, 313, 353, 48, 385, 121, 15,
          441, 540, 477, 447, 81, 510, 306, 232, 196, 440, 571, 128, 342, 435,
          459, 253, 419, 557, 482, 146, 337, 243, 409, 69, 129, 343, 442, 244,
          479, 526, 531, 333, 283, 142, 248, 473, 157, 302, 147, 183, 246, 293,
          299, 231, 189, 312, 65, 592, 16, 217, 500, 421, 155, 428, 159, 148,
          280, 41, 247, 535, 466, 8, 568, 512, 544, 583, 107, 245, 402, 286, 67,
          34, 225, 71, 47, 373, 190, 407, 562, 483, 533, 230, 445, 551, 465,
          403, 166, 70, 330, 519, 140, 33, 417, 549, 396, 383, 83, 416, 460, 68,
          341, 576, 289, 132, 315, 292, 379, 572, 214, 516, 426, 218, 565, 525,
          108, 437, 537, 365, 270, 414, 221, 359, 521, 314, 172, 561, 118, 281,
          198, 137, 237, 468, 229, 517, 114, 258, 143, 351, 133, 144, 49, 470,
          180, 431, 324, 191, 564, 11, 163, 336, 291, 205, 305, 474, 382, 325,
          501, 370, 168, 113, 30, 285, 423, 182, 177, 475, 115, 22, 539, 252,
          464, 66, 511, 109, 490, 239, 240, 575, 560, 0, 404, 392, 585, 164,
          361, 369, 367, 93, 53, 415, 328, 3, 536, 310, 88, 24, 284, 43, 444,
          406, 502, 566, 277, 523, 87, 179, 276, 256, 17, 472, 547, 577, 322,
          412, 127, 362, 434, 74, 567, 420, 588, 171, 184, 467, 10, 7, 105, 104,
          582, 188, 54, 294, 469, 151, 31, 210, 318, 534, 85, 488, 175, 492,
          103, 215, 430, 327, 375, 213, 578, 332, 573, 307, 457, 167, 6, 259,
          290, 14, 223, 360, 349, 388, 338, 102, 449, 20, 590, 202, 393, 405,
          347, 100, 204, 381, 563, 368, 591, 45, 329, 323, 19, 377, 233, 79,
          443, 364, 154, 174, 345, 390, 203, 504, 541, 57, 548, 391, 530, 181,
          348, 131, 438, 356, 9, 484, 61, 228, 84, 461, 586, 596, 268, 300, 130,
          513, 520, 397, 170, 120, 374, 27, 160, 429, 56, 39, 254, 274, 528,
          267, 569, 542, 481, 153, 169, 387, 219, 363, 581, 58, 116, 2, 340, 55,
          62, 77, 380, 89, 211, 594, 76, 487, 263, 456, 424, 78, 238, 227, 508,
          249, 29, 546, 98, 262, 96, 515, 135, 165, 173, 162, 505, 503, 371,
          298, 580, 234, 339, 194, 110, 111, 35, 446, 80, 265, 152, 44, 589,
          200, 73, 366, 427, 529, 334, 579, 91, 112, 82, 550, 355, 295, 278,
          463, 386, 1, 395, 308, 26, 145, 257, 410, 454, 23, 532, 543, 485, 297,
          199, 394, 346, 599, 75, 320, 224, 176, 598, 401, 321, 545, 372, 187,
          458, 451, 134, 570, 119, 94, 288, 309, 509, 413, 193, 86, 554, 236,
          272, 378, 522, 506, 101, 595, 398, 593, 212, 287, 282, 25, 260, 316,
          95, 125, 150, 12, 124, 587, 195, 122, 493, 275, 326, 452, 331, 317,
          99, 350, 538, 344, 384, 38, 40, 494, 524, 311, 450, 106, 72, 498, 207,
          527, 471, 478, 303, 296, 476, 220, 178, 242, 216, 206, 518, 556, 400,
          558, 141, 92, 584, 138, 32, 156, 358, 507, 201, 50, 64, 408, 271, 497,
          59, 266, 13, 486, 439, 304, 235, 455, 97, 208, 139, 18, 192, 376, 186,
          352, 335, 28, 453, 354, 480, 149, 319, 448, 597, 158, 52, 552, 226,
          436, 269, 495, 4, 63, 90, 411, 5, 301, 489, 209, 117, 51, 574, 21,
        ],
        [
          396, 6, 306, 436, 360, 427, 292, 33, 464, 335, 558, 339, 194, 126, 13,
          295, 499, 265, 432, 582, 359, 136, 314, 19, 243, 209, 232, 195, 357,
          583, 317, 369, 233, 458, 240, 486, 38, 382, 434, 90, 111, 156, 304,
          238, 463, 72, 138, 227, 151, 480, 552, 561, 261, 413, 402, 84, 319,
          327, 350, 108, 140, 69, 170, 117, 149, 371, 568, 479, 400, 456, 468,
          129, 418, 433, 523, 504, 422, 53, 49, 258, 278, 485, 448, 446, 380,
          440, 14, 338, 15, 266, 146, 529, 175, 518, 305, 176, 216, 528, 182,
          162, 536, 591, 441, 542, 183, 256, 474, 259, 95, 101, 599, 159, 555,
          2, 597, 531, 442, 73, 478, 590, 214, 1, 144, 296, 273, 481, 405, 475,
          443, 537, 521, 280, 163, 316, 219, 315, 567, 472, 573, 425, 113, 498,
          248, 269, 554, 374, 203, 48, 570, 123, 242, 449, 291, 141, 120, 524,
          68, 520, 565, 410, 510, 147, 532, 180, 368, 28, 493, 178, 249, 116,
          263, 177, 254, 267, 431, 401, 513, 204, 390, 193, 213, 218, 281, 506,
          452, 198, 465, 547, 592, 415, 192, 354, 563, 89, 185, 356, 501, 328,
          426, 11, 139, 391, 79, 93, 270, 336, 187, 409, 20, 229, 321, 119, 54,
          579, 186, 414, 569, 541, 152, 78, 584, 490, 122, 539, 496, 355, 284,
          32, 25, 154, 167, 43, 205, 5, 514, 549, 88, 300, 439, 244, 121, 534,
          250, 21, 255, 572, 505, 594, 408, 342, 596, 57, 181, 331, 285, 358,
          337, 56, 469, 135, 560, 551, 502, 158, 290, 546, 45, 482, 44, 260,
          191, 491, 363, 224, 225, 577, 160, 128, 588, 394, 334, 153, 106, 550,
          423, 575, 190, 35, 228, 42, 207, 133, 562, 429, 279, 288, 36, 470,
          525, 385, 461, 412, 326, 361, 174, 467, 148, 322, 92, 365, 353, 96,
          217, 576, 157, 509, 527, 184, 231, 83, 124, 366, 246, 66, 24, 241,
          206, 80, 223, 18, 403, 507, 257, 454, 268, 428, 489, 580, 377, 388,
          349, 332, 200, 109, 22, 301, 234, 311, 497, 61, 526, 64, 107, 320,
          535, 201, 85, 164, 65, 540, 172, 60, 393, 211, 325, 52, 330, 522, 373,
          8, 344, 416, 40, 221, 341, 312, 444, 245, 276, 329, 548, 236, 512,
          340, 578, 239, 226, 519, 309, 161, 404, 142, 0, 55, 222, 424, 516,
          384, 333, 445, 115, 420, 379, 389, 81, 12, 179, 7, 294, 430, 417, 127,
          367, 387, 264, 165, 37, 492, 272, 9, 26, 17, 208, 581, 67, 372, 476,
          251, 298, 58, 466, 557, 102, 406, 566, 307, 125, 46, 395, 530, 114,
          87, 421, 495, 589, 488, 112, 155, 503, 515, 10, 188, 324, 110, 212,
          77, 104, 411, 538, 76, 348, 508, 511, 343, 173, 593, 30, 31, 137, 23,
          97, 287, 253, 252, 202, 517, 283, 451, 289, 351, 3, 230, 450, 346,
          293, 150, 587, 99, 553, 559, 171, 34, 477, 494, 455, 27, 86, 75, 132,
          487, 347, 39, 453, 41, 533, 564, 352, 397, 282, 168, 118, 189, 62,
          435, 130, 318, 197, 215, 47, 586, 29, 247, 220, 299, 362, 297, 310,
          302, 345, 277, 82, 94, 103, 398, 595, 545, 484, 437, 169, 274, 63, 59,
          473, 210, 50, 544, 98, 199, 483, 460, 237, 471, 131, 407, 399, 364,
          100, 196, 457, 543, 381, 571, 271, 386, 370, 16, 308, 462, 447, 378,
          262, 598, 51, 313, 286, 4, 438, 134, 459, 574, 91, 74, 166, 323, 585,
          235, 392, 383, 145, 419, 275, 70, 303, 143, 71, 375, 556, 105, 376,
          500,
        ],
        [
          219, 220, 384, 430, 147, 172, 166, 198, 121, 342, 586, 382, 319, 73,
          296, 438, 114, 126, 433, 289, 322, 25, 350, 101, 93, 346, 237, 515,
          41, 294, 404, 150, 278, 132, 593, 61, 597, 385, 217, 410, 211, 304,
          376, 519, 476, 216, 581, 234, 474, 325, 400, 138, 551, 226, 394, 221,
          86, 95, 402, 379, 347, 381, 169, 175, 107, 587, 210, 556, 463, 190,
          287, 113, 167, 279, 401, 530, 466, 445, 276, 205, 468, 577, 435, 368,
          293, 285, 242, 66, 421, 387, 145, 364, 480, 340, 558, 356, 225, 176,
          173, 35, 555, 141, 131, 182, 529, 371, 482, 228, 88, 230, 281, 450,
          117, 470, 49, 592, 299, 187, 209, 494, 63, 419, 45, 274, 179, 420, 44,
          152, 328, 33, 423, 75, 208, 253, 201, 295, 583, 191, 292, 4, 351, 353,
          578, 55, 212, 57, 502, 46, 535, 552, 359, 51, 434, 373, 218, 564, 128,
          203, 416, 525, 317, 399, 6, 290, 479, 437, 85, 380, 574, 186, 537,
          170, 18, 284, 459, 1, 323, 361, 572, 248, 575, 446, 477, 275, 104,
          491, 332, 553, 156, 12, 393, 329, 541, 116, 389, 238, 79, 594, 52,
          467, 252, 27, 509, 455, 560, 478, 458, 499, 154, 492, 180, 277, 142,
          144, 526, 365, 396, 133, 251, 74, 196, 269, 481, 456, 130, 80, 111,
          58, 348, 38, 311, 406, 453, 64, 549, 501, 367, 443, 280, 149, 461,
          259, 197, 517, 10, 343, 312, 320, 183, 171, 302, 204, 99, 94, 96, 17,
          109, 338, 243, 105, 235, 366, 31, 349, 498, 441, 163, 102, 215, 374,
          487, 153, 489, 567, 71, 414, 87, 98, 246, 47, 383, 452, 265, 14, 160,
          536, 262, 561, 425, 318, 23, 464, 303, 76, 143, 140, 8, 475, 112, 69,
          305, 590, 286, 59, 388, 518, 42, 512, 554, 540, 256, 158, 260, 78,
          341, 227, 568, 473, 496, 214, 189, 24, 255, 257, 240, 442, 415, 409,
          539, 224, 134, 550, 181, 195, 378, 159, 263, 569, 566, 90, 26, 352,
          316, 291, 40, 91, 258, 397, 223, 429, 103, 485, 261, 239, 528, 120,
          495, 507, 330, 580, 440, 449, 545, 579, 254, 62, 83, 598, 584, 9, 136,
          333, 70, 448, 534, 427, 490, 122, 137, 119, 497, 264, 562, 408, 21,
          324, 377, 557, 28, 129, 335, 548, 39, 301, 82, 503, 272, 418, 357,
          392, 288, 596, 206, 326, 460, 391, 360, 193, 570, 520, 589, 164, 241,
          412, 249, 472, 247, 486, 527, 457, 125, 533, 29, 161, 139, 334, 462,
          484, 178, 124, 32, 268, 544, 411, 504, 521, 162, 571, 37, 582, 20,
          523, 106, 565, 327, 313, 34, 588, 53, 60, 511, 273, 413, 100, 506,
          422, 454, 157, 331, 510, 300, 77, 283, 151, 542, 36, 538, 516, 465,
          524, 185, 229, 591, 428, 48, 547, 68, 405, 135, 508, 354, 245, 505,
          177, 165, 81, 407, 451, 54, 308, 306, 432, 233, 424, 386, 84, 127,
          483, 546, 266, 67, 559, 500, 19, 369, 417, 436, 444, 65, 232, 522, 97,
          576, 271, 403, 370, 307, 563, 447, 471, 43, 0, 339, 207, 250, 188,
          148, 13, 439, 345, 493, 390, 5, 362, 298, 155, 202, 531, 89, 15, 375,
          22, 184, 192, 310, 398, 50, 573, 146, 358, 599, 270, 469, 315, 30,
          297, 16, 200, 372, 585, 363, 532, 115, 3, 231, 543, 194, 11, 426, 344,
          337, 282, 222, 199, 314, 355, 488, 431, 395, 110, 92, 7, 595, 2, 321,
          309, 56, 213, 513, 118, 123, 174, 244, 336, 168, 514, 267, 108, 236,
          72,
        ],
        [
          296, 107, 417, 349, 96, 209, 112, 347, 524, 487, 539, 154, 149, 299,
          139, 200, 228, 404, 452, 437, 416, 69, 370, 172, 190, 260, 358, 426,
          73, 297, 62, 521, 551, 398, 108, 115, 566, 177, 501, 81, 180, 482,
          465, 239, 4, 27, 319, 581, 192, 400, 406, 462, 421, 469, 285, 15, 597,
          594, 72, 360, 268, 376, 120, 304, 460, 558, 295, 476, 377, 307, 396,
          405, 136, 384, 8, 140, 430, 124, 504, 152, 463, 490, 218, 320, 516,
          357, 567, 106, 147, 76, 509, 543, 252, 201, 256, 93, 111, 518, 249,
          401, 457, 155, 250, 53, 578, 427, 456, 447, 454, 84, 271, 208, 31,
          564, 388, 284, 599, 448, 491, 542, 67, 368, 290, 472, 219, 196, 424,
          37, 39, 595, 281, 114, 207, 104, 333, 188, 94, 71, 90, 301, 99, 278,
          411, 510, 486, 554, 374, 380, 546, 544, 166, 538, 242, 287, 163, 183,
          86, 179, 534, 125, 341, 88, 312, 109, 191, 227, 344, 573, 306, 264,
          340, 92, 226, 174, 110, 292, 141, 145, 101, 187, 348, 571, 276, 248,
          563, 236, 54, 553, 78, 46, 485, 102, 569, 35, 41, 258, 555, 257, 332,
          25, 175, 119, 269, 478, 541, 506, 395, 21, 392, 529, 444, 342, 58,
          540, 305, 105, 63, 557, 212, 525, 532, 431, 423, 22, 291, 19, 492,
          259, 74, 586, 203, 34, 592, 161, 66, 255, 184, 559, 150, 165, 288, 17,
          6, 520, 64, 479, 439, 294, 195, 474, 130, 129, 157, 220, 313, 382,
          234, 379, 399, 142, 57, 565, 408, 18, 440, 229, 441, 98, 246, 160,
          409, 56, 210, 261, 363, 493, 500, 138, 80, 508, 574, 42, 458, 126,
          189, 197, 314, 55, 336, 146, 214, 243, 49, 170, 322, 158, 20, 40, 579,
          164, 198, 364, 302, 378, 44, 293, 577, 28, 327, 280, 233, 330, 14,
          568, 9, 238, 429, 438, 514, 593, 328, 265, 420, 52, 391, 315, 561,
          217, 225, 194, 550, 251, 359, 103, 587, 345, 428, 132, 0, 131, 499,
          199, 549, 470, 83, 481, 483, 168, 61, 397, 576, 560, 273, 552, 3, 513,
          50, 531, 144, 65, 412, 274, 537, 361, 224, 523, 394, 435, 335, 366,
          32, 100, 127, 13, 436, 51, 425, 422, 515, 517, 502, 381, 519, 151,
          410, 321, 116, 279, 254, 434, 367, 275, 213, 575, 455, 362, 36, 267,
          528, 237, 489, 365, 29, 372, 507, 503, 326, 173, 91, 311, 181, 222,
          262, 369, 512, 16, 206, 451, 533, 582, 244, 70, 351, 536, 464, 162, 7,
          241, 590, 45, 356, 350, 204, 216, 346, 1, 353, 526, 266, 390, 289,
          354, 480, 556, 134, 171, 59, 223, 334, 309, 453, 230, 375, 176, 471,
          407, 572, 159, 588, 468, 442, 277, 496, 548, 371, 584, 215, 589, 466,
          432, 418, 459, 5, 75, 24, 12, 393, 95, 373, 38, 494, 591, 137, 253,
          488, 477, 443, 283, 303, 585, 317, 87, 308, 263, 193, 85, 270, 527,
          570, 386, 128, 211, 343, 383, 97, 185, 310, 23, 325, 33, 329, 113, 60,
          450, 122, 47, 387, 282, 324, 167, 245, 11, 79, 323, 182, 82, 231, 118,
          467, 449, 580, 389, 30, 461, 89, 202, 530, 143, 419, 497, 156, 247,
          123, 415, 505, 117, 272, 153, 338, 498, 522, 331, 240, 148, 133, 414,
          121, 26, 221, 339, 10, 446, 413, 433, 545, 403, 495, 484, 232, 598,
          178, 385, 355, 596, 352, 300, 562, 186, 169, 547, 445, 68, 43, 318,
          48, 535, 316, 286, 475, 402, 583, 337, 2, 511, 235, 77, 473, 135, 205,
          298,
        ],
      ],
      c4: [
        [
          305, 76, 161, 229, 259, 177, 51, 62, 558, 551, 3, 59, 337, 504, 231,
          102, 214, 561, 255, 350, 244, 286, 466, 408, 221, 528, 483, 298, 261,
          433, 584, 578, 595, 247, 235, 339, 26, 203, 6, 470, 367, 196, 32, 118,
          414, 33, 501, 438, 592, 332, 80, 576, 71, 378, 445, 546, 362, 327, 66,
          598, 430, 365, 338, 128, 81, 477, 290, 42, 345, 310, 545, 103, 421,
          575, 416, 428, 415, 256, 31, 15, 348, 486, 176, 565, 175, 413, 532,
          591, 187, 122, 40, 513, 94, 300, 274, 342, 184, 270, 230, 107, 285,
          489, 364, 523, 301, 241, 312, 491, 515, 404, 340, 475, 216, 506, 469,
          317, 566, 79, 361, 464, 539, 560, 284, 373, 246, 315, 547, 84, 53,
          319, 131, 139, 406, 341, 141, 334, 162, 226, 157, 352, 485, 395, 435,
          143, 360, 423, 324, 115, 336, 134, 393, 260, 52, 358, 372, 444, 210,
          463, 12, 478, 467, 120, 398, 589, 28, 328, 289, 371, 574, 419, 11,
          205, 295, 497, 109, 64, 73, 498, 458, 303, 349, 508, 228, 460, 410,
          540, 407, 174, 195, 249, 58, 570, 557, 325, 193, 87, 396, 559, 165,
          183, 267, 465, 562, 179, 411, 104, 363, 169, 287, 279, 356, 521, 437,
          597, 403, 97, 579, 146, 180, 119, 61, 25, 353, 240, 384, 223, 85, 455,
          207, 296, 222, 24, 368, 323, 417, 238, 273, 197, 234, 529, 145, 218,
          56, 212, 213, 383, 440, 443, 88, 173, 397, 57, 526, 311, 60, 409, 237,
          580, 275, 426, 160, 170, 308, 385, 509, 200, 199, 422, 265, 357, 65,
          446, 288, 101, 188, 596, 67, 333, 522, 217, 500, 484, 330, 510, 232,
          554, 599, 511, 181, 391, 1, 82, 429, 472, 496, 164, 10, 192, 304, 536,
          544, 283, 129, 190, 63, 392, 493, 588, 19, 248, 573, 167, 125, 8, 329,
          386, 36, 124, 516, 39, 375, 441, 299, 21, 72, 166, 110, 211, 425, 0,
          35, 54, 44, 556, 503, 297, 520, 542, 534, 121, 18, 92, 309, 132, 140,
          293, 268, 243, 34, 548, 41, 582, 282, 585, 186, 335, 474, 23, 313, 14,
          569, 49, 254, 389, 9, 280, 476, 105, 471, 46, 209, 376, 525, 481, 524,
          480, 7, 151, 495, 220, 487, 418, 381, 507, 388, 553, 198, 189, 16,
          537, 453, 518, 369, 502, 90, 136, 420, 224, 314, 13, 144, 387, 400,
          549, 530, 251, 153, 150, 242, 99, 514, 563, 377, 262, 541, 519, 233,
          5, 343, 452, 204, 127, 86, 291, 394, 202, 215, 490, 307, 178, 424, 70,
          527, 191, 55, 366, 96, 137, 159, 468, 405, 581, 505, 302, 543, 531,
          494, 572, 142, 456, 535, 68, 488, 436, 69, 93, 185, 2, 147, 77, 399,
          106, 272, 50, 590, 473, 258, 276, 321, 462, 442, 577, 236, 370, 138,
          454, 434, 401, 594, 182, 593, 586, 538, 126, 172, 447, 326, 427, 479,
          148, 512, 45, 135, 133, 152, 123, 320, 269, 322, 264, 194, 354, 359,
          431, 382, 568, 451, 351, 154, 30, 482, 17, 78, 37, 517, 158, 499, 95,
          108, 331, 587, 155, 83, 306, 4, 583, 111, 225, 263, 48, 250, 449, 74,
          117, 390, 219, 149, 380, 130, 98, 450, 346, 27, 239, 277, 20, 266,
          112, 448, 281, 245, 292, 89, 227, 379, 555, 347, 257, 492, 432, 567,
          253, 91, 100, 29, 47, 459, 208, 552, 116, 294, 355, 571, 457, 38, 412,
          564, 43, 114, 252, 374, 201, 113, 171, 22, 439, 550, 344, 168, 533,
          318, 75, 163, 156, 402, 271, 206, 461, 316, 278,
        ],
        [
          439, 505, 264, 86, 268, 248, 218, 481, 136, 90, 200, 386, 487, 96, 98,
          378, 466, 67, 417, 42, 238, 521, 360, 362, 327, 69, 272, 95, 219, 28,
          595, 493, 444, 80, 25, 198, 171, 588, 351, 114, 468, 398, 100, 145,
          496, 99, 463, 132, 117, 568, 585, 458, 169, 137, 295, 330, 324, 504,
          586, 6, 245, 231, 159, 22, 477, 434, 75, 221, 355, 174, 315, 543, 333,
          195, 424, 560, 215, 286, 565, 549, 102, 385, 425, 130, 224, 537, 48,
          524, 61, 341, 284, 112, 401, 151, 303, 408, 274, 220, 452, 306, 19,
          192, 273, 63, 574, 30, 579, 492, 485, 20, 479, 213, 488, 208, 56, 97,
          270, 49, 409, 44, 446, 310, 191, 509, 352, 3, 445, 84, 314, 165, 528,
          414, 322, 591, 449, 443, 79, 254, 347, 363, 154, 413, 91, 88, 460,
          450, 133, 239, 89, 323, 520, 461, 120, 16, 308, 8, 421, 407, 4, 59,
          473, 189, 242, 437, 371, 14, 129, 291, 53, 26, 131, 536, 513, 37, 280,
          10, 134, 380, 146, 138, 301, 431, 559, 164, 46, 85, 572, 429, 135,
          589, 576, 584, 51, 305, 15, 375, 104, 247, 395, 212, 179, 41, 269,
          547, 60, 172, 162, 447, 64, 497, 227, 246, 225, 87, 287, 471, 65, 62,
          534, 332, 128, 207, 281, 92, 229, 201, 309, 515, 204, 532, 182, 36,
          387, 205, 194, 78, 116, 345, 140, 498, 459, 293, 109, 557, 187, 70,
          594, 183, 294, 403, 319, 364, 217, 202, 321, 103, 223, 573, 544, 511,
          17, 597, 418, 369, 188, 377, 343, 367, 328, 353, 292, 465, 454, 456,
          325, 181, 39, 366, 394, 525, 435, 276, 52, 373, 495, 348, 397, 598,
          416, 317, 211, 374, 406, 438, 35, 307, 251, 237, 346, 405, 500, 253,
          566, 577, 376, 302, 436, 569, 163, 476, 228, 336, 125, 469, 38, 372,
          358, 529, 118, 393, 241, 400, 399, 523, 24, 54, 158, 233, 300, 143,
          21, 261, 105, 499, 283, 157, 47, 45, 368, 257, 556, 340, 31, 180, 412,
          546, 564, 160, 419, 81, 178, 506, 258, 175, 320, 282, 599, 519, 142,
          263, 326, 235, 11, 581, 472, 389, 122, 390, 552, 152, 575, 57, 297,
          489, 592, 494, 255, 440, 593, 370, 249, 410, 289, 216, 316, 318, 149,
          359, 126, 144, 278, 108, 199, 127, 285, 290, 32, 530, 110, 455, 548,
          517, 186, 522, 587, 311, 422, 474, 147, 491, 243, 124, 578, 379, 526,
          107, 196, 462, 121, 426, 197, 531, 153, 93, 507, 230, 83, 155, 402,
          512, 29, 590, 558, 391, 139, 342, 388, 428, 167, 383, 384, 392, 570,
          423, 123, 354, 267, 209, 464, 381, 596, 467, 582, 561, 190, 503, 71,
          203, 442, 357, 448, 542, 337, 441, 567, 415, 210, 583, 349, 483, 177,
          313, 226, 256, 275, 430, 541, 527, 55, 176, 141, 250, 111, 74, 260,
          266, 288, 73, 262, 150, 478, 329, 170, 2, 82, 475, 339, 299, 50, 0,
          533, 23, 296, 433, 66, 148, 538, 193, 361, 502, 411, 33, 43, 7, 34,
          259, 298, 119, 540, 185, 166, 68, 580, 508, 535, 427, 312, 271, 18,
          106, 252, 331, 551, 234, 156, 240, 490, 334, 335, 550, 222, 545, 350,
          58, 94, 232, 206, 365, 168, 420, 571, 344, 277, 555, 5, 482, 265, 396,
          338, 113, 553, 1, 356, 518, 161, 27, 510, 9, 382, 279, 554, 562, 563,
          244, 470, 480, 539, 432, 184, 101, 453, 115, 484, 404, 214, 304, 514,
          40, 457, 486, 173, 76, 13, 451, 236, 516, 77, 501, 72, 12,
        ],
        [
          340, 346, 281, 255, 402, 10, 218, 490, 158, 445, 560, 68, 221, 209,
          204, 353, 59, 190, 543, 106, 31, 453, 278, 540, 327, 555, 581, 79,
          567, 215, 133, 482, 432, 287, 532, 152, 444, 277, 425, 329, 189, 401,
          247, 381, 426, 58, 113, 422, 339, 203, 358, 536, 360, 306, 574, 439,
          489, 317, 18, 514, 526, 34, 56, 336, 572, 597, 431, 589, 380, 424,
          268, 469, 111, 154, 462, 524, 459, 15, 446, 457, 593, 181, 112, 104,
          265, 382, 513, 98, 332, 122, 522, 300, 140, 283, 199, 493, 12, 150,
          420, 566, 518, 6, 450, 359, 377, 43, 3, 270, 579, 291, 227, 135, 65,
          354, 117, 416, 129, 213, 506, 575, 373, 413, 417, 101, 17, 70, 51,
          145, 97, 253, 119, 208, 44, 304, 94, 100, 5, 223, 535, 175, 582, 225,
          385, 50, 82, 305, 188, 312, 299, 368, 516, 178, 166, 163, 334, 460,
          220, 172, 474, 310, 348, 85, 248, 565, 134, 180, 217, 128, 408, 92,
          449, 475, 229, 434, 8, 114, 495, 531, 472, 344, 290, 503, 315, 169,
          219, 60, 517, 313, 81, 120, 273, 586, 242, 570, 419, 230, 384, 598,
          69, 86, 262, 429, 352, 341, 126, 89, 379, 370, 525, 409, 455, 202,
          191, 554, 553, 512, 63, 160, 266, 448, 588, 143, 563, 252, 533, 263,
          280, 551, 224, 75, 404, 367, 580, 573, 477, 561, 468, 308, 161, 254,
          234, 173, 357, 25, 481, 71, 23, 499, 529, 200, 507, 389, 159, 309,
          521, 293, 127, 211, 155, 147, 9, 484, 77, 374, 48, 239, 504, 14, 41,
          519, 511, 390, 433, 443, 301, 64, 251, 378, 49, 393, 483, 11, 496,
          596, 245, 72, 349, 105, 364, 165, 96, 539, 2, 57, 4, 102, 170, 534,
          557, 441, 319, 406, 19, 421, 405, 259, 502, 66, 523, 552, 121, 207,
          438, 350, 233, 171, 430, 307, 544, 392, 275, 548, 289, 559, 576, 52,
          397, 241, 423, 282, 38, 146, 184, 195, 556, 74, 520, 505, 322, 330,
          583, 83, 164, 537, 168, 321, 222, 130, 538, 87, 411, 325, 356, 22,
          303, 182, 328, 549, 478, 296, 47, 194, 558, 260, 142, 363, 298, 467,
          267, 250, 93, 571, 136, 269, 256, 394, 418, 464, 115, 237, 95, 210,
          355, 480, 206, 295, 16, 20, 591, 257, 78, 108, 110, 320, 294, 414,
          274, 156, 174, 541, 76, 40, 88, 186, 351, 246, 109, 292, 232, 138,
          412, 311, 369, 192, 205, 376, 33, 201, 264, 131, 473, 338, 388, 141,
          39, 27, 216, 345, 91, 407, 35, 149, 343, 366, 437, 342, 238, 542, 347,
          442, 167, 279, 103, 471, 386, 398, 240, 261, 569, 463, 400, 396, 7,
          427, 185, 302, 13, 587, 61, 187, 28, 314, 73, 45, 276, 331, 46, 118,
          590, 32, 494, 510, 148, 123, 228, 177, 372, 501, 498, 335, 550, 465,
          297, 447, 37, 125, 435, 153, 0, 458, 249, 492, 491, 179, 333, 515,
          183, 375, 243, 29, 440, 564, 286, 362, 383, 470, 316, 24, 545, 258,
          318, 562, 144, 436, 80, 547, 116, 271, 323, 324, 508, 530, 399, 288,
          371, 21, 546, 488, 1, 214, 162, 176, 428, 67, 55, 528, 599, 500, 157,
          62, 415, 193, 584, 527, 197, 198, 479, 391, 466, 53, 26, 30, 594, 578,
          236, 124, 231, 451, 487, 577, 36, 226, 365, 497, 568, 285, 476, 107,
          139, 456, 387, 361, 403, 595, 42, 84, 54, 284, 337, 196, 395, 486,
          272, 151, 485, 592, 132, 452, 244, 137, 454, 99, 90, 212, 461, 235,
          326, 410, 585, 509,
        ],
        [
          560, 554, 434, 126, 144, 361, 14, 275, 475, 571, 456, 295, 431, 31,
          46, 474, 439, 204, 521, 426, 203, 86, 591, 306, 110, 194, 52, 549,
          212, 565, 66, 170, 387, 556, 471, 385, 360, 390, 532, 253, 16, 509,
          19, 251, 494, 381, 363, 427, 61, 519, 211, 403, 103, 457, 505, 374,
          102, 281, 140, 334, 380, 43, 512, 184, 491, 378, 54, 151, 370, 37,
          449, 56, 95, 339, 539, 593, 436, 351, 284, 511, 578, 121, 314, 429,
          277, 575, 17, 487, 564, 452, 85, 444, 130, 348, 340, 76, 232, 562, 99,
          91, 137, 468, 182, 112, 237, 274, 414, 163, 35, 39, 303, 418, 443,
          117, 336, 153, 473, 557, 132, 315, 319, 149, 188, 1, 495, 497, 572,
          514, 48, 223, 503, 441, 248, 239, 40, 158, 164, 458, 331, 28, 536,
          264, 502, 343, 323, 568, 255, 344, 21, 138, 155, 586, 515, 24, 41,
          349, 357, 464, 442, 67, 369, 96, 224, 124, 341, 62, 346, 191, 535, 51,
          406, 373, 307, 508, 342, 34, 372, 210, 12, 60, 382, 245, 93, 308, 394,
          114, 433, 375, 309, 371, 333, 537, 11, 301, 10, 13, 459, 259, 313,
          262, 448, 558, 364, 177, 305, 401, 365, 189, 561, 78, 356, 555, 220,
          460, 480, 205, 82, 530, 69, 388, 422, 318, 421, 576, 25, 63, 89, 276,
          176, 145, 165, 501, 316, 399, 582, 599, 157, 55, 71, 310, 389, 362,
          419, 150, 513, 27, 249, 94, 496, 332, 105, 311, 397, 325, 32, 139,
          383, 2, 358, 113, 587, 147, 386, 290, 100, 354, 589, 490, 353, 597,
          533, 120, 392, 454, 300, 243, 111, 218, 252, 321, 451, 355, 119, 186,
          486, 287, 106, 263, 467, 455, 396, 504, 280, 133, 183, 288, 214, 327,
          320, 326, 127, 523, 329, 350, 413, 563, 484, 580, 477, 293, 548, 299,
          545, 227, 225, 250, 226, 272, 200, 36, 465, 415, 412, 30, 542, 488,
          195, 485, 294, 154, 152, 569, 128, 181, 594, 0, 577, 87, 425, 196, 68,
          222, 142, 38, 450, 520, 156, 286, 42, 469, 20, 410, 70, 420, 268, 585,
          229, 134, 72, 476, 83, 461, 538, 206, 90, 260, 338, 256, 26, 179, 125,
          208, 398, 507, 544, 424, 219, 463, 129, 7, 335, 296, 201, 80, 167,
          143, 528, 23, 352, 230, 233, 266, 499, 324, 297, 590, 598, 522, 77,
          216, 543, 175, 236, 379, 202, 279, 573, 391, 213, 141, 405, 162, 377,
          169, 291, 108, 168, 267, 273, 527, 541, 257, 282, 302, 5, 407, 22,
          261, 107, 4, 217, 6, 74, 49, 8, 312, 552, 197, 472, 97, 345, 116, 595,
          53, 221, 482, 432, 489, 322, 278, 584, 271, 531, 193, 190, 122, 466,
          366, 409, 209, 376, 131, 73, 171, 574, 192, 174, 289, 246, 435, 228,
          588, 423, 88, 29, 550, 44, 269, 453, 328, 330, 160, 567, 199, 438, 18,
          446, 581, 235, 58, 430, 75, 172, 178, 416, 234, 247, 402, 596, 546,
          529, 47, 50, 166, 9, 84, 241, 447, 98, 417, 265, 462, 368, 92, 400,
          492, 159, 470, 517, 493, 570, 579, 395, 534, 479, 500, 64, 384, 115,
          408, 59, 244, 45, 518, 506, 411, 123, 359, 524, 283, 445, 15, 135,
          437, 118, 337, 553, 198, 481, 185, 148, 404, 238, 57, 592, 180, 187,
          161, 498, 242, 526, 483, 367, 540, 317, 298, 109, 65, 146, 285, 207,
          104, 79, 510, 258, 583, 254, 292, 566, 81, 525, 240, 516, 393, 136,
          547, 215, 270, 478, 428, 304, 551, 347, 173, 231, 101, 559, 440, 3,
          33,
        ],
        [
          279, 400, 129, 307, 285, 487, 528, 31, 502, 209, 426, 269, 281, 574,
          357, 563, 520, 306, 223, 489, 248, 530, 388, 66, 205, 46, 77, 85, 290,
          47, 71, 125, 268, 241, 329, 29, 421, 118, 127, 28, 417, 596, 161, 39,
          93, 308, 586, 5, 454, 275, 163, 258, 40, 143, 37, 96, 350, 387, 432,
          406, 313, 183, 408, 49, 121, 254, 318, 90, 144, 110, 325, 215, 478,
          78, 568, 60, 191, 514, 111, 156, 87, 79, 434, 584, 448, 588, 64, 30,
          20, 476, 592, 122, 15, 428, 358, 197, 564, 567, 14, 164, 486, 485,
          198, 582, 452, 422, 575, 45, 351, 469, 92, 508, 187, 220, 330, 237, 2,
          334, 495, 7, 288, 471, 204, 11, 103, 376, 63, 550, 174, 473, 498, 203,
          509, 12, 383, 468, 152, 532, 266, 466, 151, 380, 322, 72, 132, 120,
          403, 218, 490, 522, 386, 162, 43, 368, 470, 179, 321, 240, 199, 503,
          414, 50, 484, 234, 525, 324, 416, 9, 587, 419, 389, 365, 561, 145,
          544, 505, 3, 533, 493, 135, 450, 407, 355, 105, 560, 463, 576, 158,
          294, 379, 392, 257, 26, 17, 33, 516, 354, 76, 172, 332, 168, 460, 211,
          23, 359, 347, 136, 337, 89, 229, 436, 251, 182, 425, 252, 496, 547,
          48, 459, 352, 65, 409, 559, 453, 180, 553, 213, 442, 59, 21, 316, 32,
          511, 253, 280, 444, 385, 446, 293, 491, 345, 224, 309, 545, 565, 341,
          384, 585, 68, 506, 458, 73, 531, 556, 571, 393, 338, 519, 299, 244,
          261, 300, 492, 303, 173, 430, 296, 437, 500, 497, 395, 367, 246, 427,
          590, 577, 291, 38, 91, 295, 52, 551, 235, 219, 247, 214, 589, 86, 535,
          263, 447, 572, 231, 210, 154, 517, 326, 371, 364, 212, 153, 133, 315,
          418, 477, 6, 461, 271, 176, 372, 543, 598, 109, 44, 510, 196, 192,
          346, 1, 413, 524, 206, 287, 166, 35, 456, 507, 297, 273, 0, 597, 148,
          369, 97, 175, 100, 250, 381, 377, 479, 19, 282, 518, 362, 138, 201,
          82, 36, 431, 411, 225, 302, 272, 599, 217, 443, 177, 580, 142, 108,
          260, 504, 396, 8, 562, 569, 363, 227, 146, 537, 255, 542, 200, 170,
          83, 270, 265, 304, 226, 412, 104, 546, 397, 56, 228, 292, 552, 410,
          284, 233, 25, 305, 137, 298, 106, 310, 134, 401, 178, 264, 343, 538,
          335, 474, 186, 328, 126, 348, 185, 286, 245, 51, 405, 160, 314, 579,
          193, 557, 112, 439, 433, 139, 549, 390, 451, 57, 190, 130, 189, 101,
          184, 107, 353, 62, 232, 150, 53, 339, 404, 124, 195, 342, 539, 378,
          301, 69, 494, 526, 159, 566, 54, 181, 382, 333, 221, 541, 55, 501, 95,
          488, 171, 208, 423, 311, 420, 81, 340, 34, 222, 356, 116, 140, 70,
          472, 475, 16, 27, 578, 521, 499, 242, 366, 512, 202, 259, 278, 375,
          402, 429, 523, 141, 465, 94, 482, 113, 527, 80, 424, 169, 216, 128,
          540, 515, 440, 344, 398, 583, 399, 548, 67, 24, 558, 462, 513, 449,
          123, 594, 481, 445, 536, 230, 149, 18, 13, 570, 455, 147, 274, 289,
          480, 194, 534, 467, 256, 262, 317, 554, 361, 99, 10, 483, 327, 188,
          165, 267, 84, 529, 581, 441, 277, 102, 394, 114, 98, 155, 373, 391,
          61, 374, 591, 167, 331, 22, 312, 573, 4, 117, 283, 370, 336, 88, 58,
          457, 207, 435, 42, 323, 438, 74, 360, 157, 555, 41, 115, 319, 119,
          131, 595, 415, 238, 593, 239, 276, 249, 236, 75, 349, 464, 320, 243,
        ],
        [
          144, 235, 109, 190, 384, 303, 359, 176, 553, 121, 247, 210, 189, 464,
          403, 281, 267, 301, 461, 54, 571, 408, 274, 175, 248, 141, 511, 91,
          317, 325, 97, 468, 410, 362, 61, 445, 80, 65, 346, 73, 569, 4, 519,
          296, 490, 387, 474, 160, 444, 88, 67, 425, 221, 580, 495, 26, 147,
          257, 579, 170, 367, 83, 559, 59, 17, 515, 551, 180, 522, 437, 162,
          199, 172, 570, 84, 520, 502, 405, 482, 112, 179, 450, 198, 541, 0, 37,
          187, 454, 305, 351, 241, 69, 164, 51, 234, 7, 483, 81, 40, 116, 222,
          11, 516, 256, 338, 371, 139, 244, 452, 401, 85, 136, 435, 100, 316,
          77, 285, 39, 456, 211, 137, 278, 500, 28, 380, 557, 153, 115, 30, 572,
          82, 458, 311, 310, 315, 182, 518, 264, 531, 286, 562, 414, 583, 427,
          226, 326, 556, 145, 512, 480, 352, 432, 509, 492, 183, 214, 552, 578,
          321, 265, 1, 223, 529, 5, 293, 411, 330, 545, 543, 117, 186, 575, 102,
          323, 379, 399, 288, 402, 534, 237, 50, 366, 31, 275, 340, 168, 138,
          201, 259, 128, 2, 75, 501, 47, 298, 581, 185, 126, 365, 44, 309, 582,
          360, 18, 64, 457, 268, 282, 131, 395, 60, 493, 313, 16, 484, 573, 191,
          218, 596, 119, 508, 491, 177, 22, 357, 533, 254, 319, 372, 441, 12,
          527, 34, 21, 594, 232, 76, 597, 27, 595, 23, 375, 521, 271, 328, 487,
          273, 538, 63, 503, 467, 548, 55, 86, 123, 291, 163, 113, 188, 181,
          514, 455, 197, 149, 378, 92, 227, 110, 540, 171, 423, 253, 415, 129,
          236, 542, 430, 355, 324, 496, 354, 335, 394, 276, 53, 304, 393, 38,
          159, 392, 314, 443, 574, 95, 406, 544, 104, 436, 318, 539, 599, 472,
          215, 469, 255, 122, 167, 349, 535, 124, 585, 363, 460, 473, 193, 206,
          407, 20, 385, 433, 294, 269, 207, 219, 111, 418, 386, 216, 396, 550,
          488, 290, 169, 479, 240, 525, 29, 295, 524, 390, 158, 439, 99, 150,
          96, 280, 377, 475, 79, 376, 70, 577, 419, 224, 228, 287, 283, 416,
          555, 125, 155, 302, 489, 101, 213, 564, 195, 413, 560, 36, 196, 299,
          494, 590, 242, 127, 33, 431, 364, 331, 142, 417, 517, 476, 194, 505,
          106, 204, 381, 391, 547, 19, 230, 442, 261, 369, 409, 513, 400, 279,
          114, 205, 412, 343, 462, 140, 471, 361, 404, 447, 358, 348, 388, 13,
          422, 536, 342, 532, 370, 154, 593, 148, 438, 251, 426, 246, 481, 397,
          339, 166, 507, 90, 266, 245, 499, 486, 356, 587, 374, 146, 292, 24, 6,
          217, 108, 260, 229, 233, 453, 546, 263, 337, 463, 165, 48, 498, 161,
          563, 350, 57, 566, 9, 308, 66, 537, 35, 15, 312, 368, 132, 89, 103,
          62, 434, 567, 203, 45, 156, 107, 14, 465, 341, 272, 49, 421, 173, 220,
          322, 262, 105, 134, 306, 504, 466, 130, 565, 477, 250, 345, 32, 118,
          526, 336, 592, 25, 74, 589, 373, 208, 78, 270, 143, 586, 192, 58, 528,
          238, 135, 327, 184, 398, 71, 225, 8, 52, 202, 46, 353, 212, 383, 561,
          289, 56, 591, 549, 87, 284, 554, 446, 178, 43, 428, 584, 239, 249,
          530, 200, 252, 300, 320, 344, 94, 93, 258, 10, 382, 120, 510, 174,
          389, 588, 333, 598, 497, 307, 68, 231, 3, 277, 420, 448, 347, 558,
          429, 243, 470, 133, 332, 424, 523, 485, 329, 41, 42, 506, 72, 568,
          576, 451, 440, 478, 157, 459, 151, 152, 334, 209, 449, 98, 297,
        ],
      ],
      c5: [
        [
          582, 546, 376, 462, 138, 453, 96, 516, 243, 332, 186, 185, 31, 476,
          24, 472, 412, 456, 351, 518, 104, 352, 90, 283, 422, 593, 523, 207,
          101, 119, 577, 471, 255, 69, 340, 128, 34, 208, 124, 450, 77, 493,
          170, 570, 135, 117, 266, 401, 460, 113, 86, 227, 444, 559, 561, 483,
          382, 538, 528, 294, 152, 214, 545, 221, 404, 40, 72, 234, 350, 367,
          269, 78, 68, 33, 43, 399, 594, 64, 242, 481, 109, 560, 229, 369, 326,
          414, 480, 60, 543, 501, 76, 363, 195, 541, 394, 368, 169, 353, 30,
          384, 275, 201, 316, 200, 419, 344, 49, 79, 95, 532, 157, 478, 579,
          257, 144, 509, 159, 97, 425, 477, 573, 496, 223, 179, 590, 434, 107,
          233, 432, 28, 70, 130, 54, 360, 8, 319, 391, 585, 439, 383, 536, 441,
          520, 81, 574, 198, 230, 408, 519, 413, 415, 473, 0, 522, 553, 356,
          311, 116, 83, 334, 505, 205, 459, 513, 377, 132, 22, 572, 10, 154, 52,
          470, 420, 174, 511, 329, 248, 1, 287, 499, 280, 58, 547, 140, 315,
          178, 454, 108, 330, 14, 544, 36, 244, 32, 176, 59, 526, 51, 586, 137,
          105, 487, 388, 276, 173, 272, 151, 12, 581, 165, 295, 158, 469, 361,
          93, 73, 106, 306, 163, 438, 333, 310, 445, 492, 177, 366, 300, 149,
          317, 433, 45, 237, 584, 379, 498, 123, 188, 423, 274, 373, 421, 228,
          290, 270, 297, 267, 451, 335, 380, 279, 94, 42, 103, 301, 575, 129,
          253, 389, 429, 550, 298, 512, 288, 336, 261, 563, 491, 292, 565, 166,
          55, 39, 578, 120, 46, 557, 3, 598, 134, 256, 398, 313, 222, 102, 21,
          9, 258, 164, 75, 467, 251, 126, 88, 63, 506, 517, 323, 488, 362, 458,
          358, 2, 587, 308, 150, 405, 486, 285, 259, 381, 345, 515, 411, 147,
          29, 25, 20, 121, 484, 56, 452, 162, 503, 156, 187, 13, 410, 355, 273,
          189, 260, 225, 224, 304, 396, 327, 180, 448, 213, 539, 278, 386, 27,
          247, 461, 312, 375, 475, 466, 50, 449, 61, 111, 479, 172, 191, 47,
          239, 495, 146, 7, 231, 457, 143, 339, 241, 426, 554, 537, 307, 190,
          53, 226, 37, 206, 17, 265, 112, 254, 596, 455, 325, 6, 203, 504, 437,
          19, 66, 542, 80, 406, 359, 87, 370, 402, 84, 303, 209, 416, 347, 305,
          252, 534, 502, 576, 474, 527, 246, 268, 211, 299, 393, 175, 153, 250,
          440, 524, 583, 38, 378, 184, 71, 148, 435, 219, 160, 318, 139, 409,
          490, 62, 494, 343, 183, 442, 571, 44, 122, 551, 529, 133, 15, 591,
          232, 41, 390, 597, 26, 436, 192, 217, 277, 5, 500, 65, 508, 131, 349,
          392, 599, 48, 296, 443, 531, 324, 320, 92, 446, 4, 385, 181, 136, 564,
          284, 74, 514, 249, 556, 403, 82, 555, 293, 407, 141, 530, 400, 337,
          595, 568, 263, 346, 220, 338, 592, 387, 431, 99, 397, 197, 341, 424,
          430, 215, 194, 216, 552, 567, 235, 196, 142, 67, 535, 118, 558, 588,
          464, 212, 35, 167, 589, 161, 417, 236, 155, 395, 238, 510, 125, 342,
          497, 566, 525, 218, 463, 193, 89, 204, 100, 365, 16, 286, 110, 374,
          562, 482, 321, 489, 331, 264, 468, 85, 115, 182, 580, 271, 485, 569,
          540, 427, 202, 114, 289, 18, 548, 507, 314, 127, 348, 372, 549, 309,
          245, 262, 291, 210, 91, 282, 199, 533, 23, 354, 145, 447, 418, 328,
          98, 240, 171, 521, 371, 281, 322, 168, 11, 302, 364, 357, 465, 428,
          57,
        ],
        [
          145, 496, 115, 132, 33, 345, 120, 418, 191, 313, 112, 324, 25, 136,
          90, 44, 399, 88, 542, 23, 361, 193, 166, 108, 203, 562, 300, 273, 575,
          367, 146, 493, 195, 119, 230, 382, 337, 394, 502, 393, 49, 298, 586,
          254, 518, 517, 0, 302, 420, 304, 325, 186, 597, 122, 490, 9, 258, 356,
          26, 171, 106, 491, 210, 576, 131, 278, 461, 176, 100, 409, 552, 276,
          86, 73, 156, 214, 142, 218, 507, 412, 250, 388, 495, 340, 548, 84,
          450, 50, 299, 216, 261, 402, 43, 504, 397, 599, 566, 287, 38, 288,
          159, 188, 500, 440, 130, 212, 114, 184, 521, 428, 569, 405, 154, 307,
          141, 466, 189, 462, 501, 291, 373, 6, 391, 584, 248, 265, 524, 281,
          374, 513, 465, 238, 93, 359, 459, 190, 453, 180, 237, 326, 594, 13,
          344, 65, 155, 289, 134, 110, 469, 354, 489, 478, 182, 362, 224, 570,
          349, 187, 442, 55, 525, 260, 363, 251, 371, 39, 556, 506, 539, 514,
          360, 183, 129, 162, 15, 421, 535, 437, 139, 275, 401, 318, 70, 439,
          377, 118, 328, 97, 543, 427, 398, 2, 536, 270, 457, 56, 8, 355, 332,
          243, 338, 116, 241, 347, 585, 480, 301, 529, 211, 272, 381, 526, 27,
          296, 485, 568, 498, 5, 277, 124, 282, 95, 53, 105, 497, 475, 202, 319,
          595, 220, 96, 208, 252, 589, 137, 221, 269, 172, 330, 222, 10, 82,
          144, 564, 476, 245, 378, 244, 174, 511, 400, 225, 111, 309, 66, 353,
          16, 467, 315, 46, 509, 256, 71, 28, 403, 303, 488, 113, 336, 233, 547,
          551, 52, 510, 410, 34, 343, 204, 598, 175, 264, 151, 232, 94, 279,
          422, 79, 553, 64, 170, 12, 538, 163, 483, 147, 219, 99, 494, 60, 390,
          104, 446, 40, 487, 579, 499, 101, 67, 4, 417, 474, 334, 68, 3, 246,
          196, 200, 560, 438, 226, 138, 192, 385, 185, 464, 357, 294, 11, 429,
          386, 215, 559, 346, 257, 165, 376, 164, 81, 140, 205, 234, 370, 592,
          484, 375, 316, 392, 320, 454, 121, 249, 520, 550, 21, 572, 588, 534,
          416, 512, 445, 259, 236, 545, 472, 364, 57, 75, 177, 308, 368, 207,
          380, 425, 143, 463, 540, 333, 223, 431, 305, 285, 396, 434, 262, 286,
          317, 435, 247, 69, 593, 92, 351, 125, 352, 209, 36, 527, 558, 312,
          292, 519, 123, 544, 549, 197, 383, 583, 430, 365, 239, 322, 581, 471,
          587, 87, 348, 29, 157, 530, 408, 1, 426, 117, 577, 213, 481, 395, 455,
          179, 240, 533, 505, 263, 567, 199, 522, 51, 311, 321, 293, 596, 563,
          516, 194, 167, 62, 546, 158, 31, 61, 424, 477, 447, 331, 341, 271,
          379, 369, 451, 470, 419, 590, 268, 228, 468, 407, 452, 531, 578, 389,
          173, 515, 554, 310, 135, 314, 91, 290, 358, 14, 206, 32, 168, 148,
          229, 153, 327, 83, 178, 80, 169, 561, 473, 253, 19, 242, 443, 102, 7,
          441, 22, 413, 35, 528, 571, 63, 436, 297, 149, 486, 37, 591, 127, 255,
          523, 150, 295, 267, 573, 448, 59, 98, 306, 449, 74, 479, 456, 198,
          323, 532, 492, 284, 565, 17, 280, 458, 503, 444, 460, 128, 274, 89,
          58, 152, 335, 161, 339, 20, 541, 404, 30, 181, 72, 54, 423, 18, 574,
          342, 508, 432, 350, 160, 41, 482, 415, 387, 283, 133, 126, 366, 235,
          537, 77, 582, 372, 201, 555, 47, 107, 48, 266, 557, 433, 85, 78, 231,
          329, 24, 406, 411, 45, 227, 109, 414, 76, 103, 384, 42, 217, 580,
        ],
        [
          555, 87, 4, 399, 9, 232, 164, 120, 143, 161, 8, 183, 220, 333, 218,
          572, 430, 369, 60, 137, 414, 429, 548, 222, 486, 389, 527, 446, 597,
          302, 459, 417, 28, 477, 553, 354, 376, 238, 102, 493, 528, 470, 133,
          476, 561, 532, 36, 158, 134, 489, 42, 233, 163, 510, 252, 534, 367,
          460, 562, 331, 545, 47, 315, 301, 314, 188, 451, 263, 353, 223, 494,
          568, 547, 350, 106, 175, 51, 518, 180, 74, 454, 160, 237, 549, 526,
          368, 543, 104, 341, 210, 521, 405, 439, 73, 282, 95, 16, 567, 478, 54,
          563, 307, 94, 473, 464, 29, 159, 424, 558, 63, 364, 66, 227, 33, 360,
          271, 372, 546, 522, 44, 580, 70, 235, 463, 182, 499, 81, 322, 115,
          596, 574, 113, 465, 348, 582, 338, 189, 456, 525, 351, 56, 593, 537,
          358, 269, 166, 363, 575, 111, 508, 519, 299, 107, 193, 472, 179, 15,
          344, 511, 595, 114, 291, 362, 194, 259, 123, 397, 361, 99, 442, 447,
          530, 401, 498, 557, 458, 402, 204, 32, 427, 265, 157, 191, 449, 594,
          577, 395, 177, 284, 591, 84, 241, 229, 544, 243, 592, 230, 598, 69,
          491, 425, 250, 31, 584, 484, 488, 469, 67, 466, 355, 5, 304, 215, 516,
          404, 441, 170, 195, 352, 413, 228, 444, 462, 125, 564, 378, 264, 337,
          398, 309, 576, 152, 147, 128, 490, 342, 200, 0, 10, 412, 394, 373,
          578, 244, 192, 76, 538, 248, 274, 310, 136, 75, 202, 329, 146, 203,
          174, 581, 130, 165, 579, 85, 196, 566, 34, 178, 556, 13, 323, 319,
          552, 38, 588, 492, 507, 124, 385, 254, 305, 277, 256, 312, 176, 386,
          90, 52, 383, 426, 297, 392, 258, 509, 96, 240, 206, 448, 408, 19, 41,
          327, 334, 162, 285, 77, 117, 296, 225, 103, 199, 531, 375, 467, 440,
          24, 190, 167, 512, 346, 39, 168, 221, 209, 445, 573, 17, 118, 135,
          471, 390, 43, 403, 251, 279, 132, 154, 313, 536, 485, 515, 275, 239,
          500, 173, 298, 503, 450, 231, 343, 65, 79, 122, 382, 78, 289, 535,
          565, 520, 12, 529, 62, 415, 253, 83, 300, 49, 25, 45, 423, 261, 141,
          505, 105, 452, 89, 396, 23, 497, 20, 422, 432, 340, 585, 347, 131, 21,
          502, 198, 50, 406, 59, 457, 61, 388, 129, 3, 281, 288, 542, 409, 294,
          290, 145, 332, 53, 186, 197, 461, 559, 11, 71, 587, 213, 308, 524,
          453, 365, 217, 270, 72, 357, 35, 185, 326, 207, 126, 523, 55, 370,
          554, 156, 86, 116, 411, 560, 306, 266, 139, 431, 287, 14, 317, 30,
          249, 91, 108, 320, 88, 381, 293, 583, 219, 318, 387, 142, 1, 187, 421,
          184, 384, 7, 393, 208, 416, 22, 551, 57, 268, 201, 109, 339, 64, 570,
          150, 247, 112, 245, 400, 255, 148, 474, 92, 380, 328, 97, 119, 435,
          514, 479, 455, 153, 374, 257, 144, 324, 377, 428, 379, 501, 349, 236,
          18, 371, 27, 391, 280, 169, 26, 410, 283, 216, 569, 540, 433, 234,
          504, 224, 436, 533, 127, 483, 181, 214, 2, 443, 151, 101, 272, 292,
          212, 541, 539, 82, 226, 273, 138, 155, 172, 366, 246, 48, 171, 481,
          98, 496, 262, 303, 276, 335, 437, 149, 506, 475, 434, 359, 590, 407,
          80, 517, 321, 487, 419, 100, 205, 311, 330, 267, 295, 46, 6, 336, 480,
          316, 140, 586, 260, 37, 345, 356, 495, 242, 211, 571, 286, 513, 93,
          68, 110, 599, 278, 420, 550, 325, 418, 121, 468, 58, 589, 40, 438,
          482,
        ],
        [
          191, 418, 97, 528, 236, 461, 539, 249, 350, 150, 400, 131, 392, 487,
          64, 188, 45, 250, 423, 159, 331, 25, 523, 493, 507, 86, 171, 198, 3,
          443, 253, 345, 299, 233, 417, 294, 465, 577, 169, 69, 203, 332, 519,
          123, 470, 573, 356, 395, 166, 147, 387, 549, 459, 377, 305, 444, 312,
          457, 543, 257, 93, 234, 344, 121, 38, 72, 182, 496, 49, 473, 62, 181,
          194, 530, 471, 132, 178, 597, 158, 424, 41, 432, 139, 71, 456, 405,
          212, 163, 585, 355, 336, 390, 552, 237, 505, 584, 197, 488, 504, 436,
          506, 458, 285, 562, 306, 514, 319, 111, 100, 381, 467, 183, 99, 274,
          88, 103, 110, 472, 574, 527, 119, 541, 245, 282, 186, 81, 351, 320,
          177, 583, 322, 61, 11, 441, 302, 298, 449, 151, 172, 192, 420, 323,
          83, 193, 74, 592, 556, 558, 570, 546, 371, 460, 325, 391, 190, 278,
          54, 318, 446, 401, 201, 239, 35, 136, 362, 431, 311, 561, 452, 205,
          453, 568, 120, 307, 337, 215, 185, 566, 280, 94, 421, 428, 447, 195,
          448, 104, 264, 246, 413, 55, 148, 407, 563, 411, 475, 1, 184, 445,
          216, 366, 383, 29, 7, 173, 464, 521, 554, 101, 526, 388, 576, 538,
          548, 303, 518, 578, 63, 189, 259, 454, 329, 117, 214, 588, 268, 73,
          33, 598, 19, 143, 341, 480, 145, 313, 170, 261, 586, 542, 580, 408,
          599, 241, 516, 513, 353, 68, 321, 174, 39, 396, 468, 492, 8, 517, 590,
          10, 180, 206, 380, 31, 498, 439, 211, 379, 76, 179, 571, 463, 244,
          333, 450, 266, 410, 572, 476, 368, 482, 373, 425, 557, 222, 342, 358,
          497, 227, 252, 75, 440, 230, 503, 334, 483, 43, 144, 489, 275, 559,
          484, 511, 276, 481, 283, 491, 202, 529, 347, 164, 495, 532, 98, 106,
          223, 397, 263, 354, 339, 593, 127, 553, 433, 240, 419, 297, 221, 17,
          21, 365, 309, 14, 291, 589, 231, 375, 51, 213, 114, 16, 224, 352, 200,
          515, 140, 138, 525, 544, 80, 129, 512, 501, 385, 485, 79, 438, 82,
          416, 535, 219, 435, 228, 308, 477, 141, 478, 560, 70, 338, 399, 412,
          22, 509, 304, 265, 422, 296, 374, 137, 394, 32, 155, 534, 376, 295,
          300, 575, 91, 384, 218, 596, 349, 122, 89, 12, 167, 26, 360, 310, 343,
          591, 27, 0, 290, 24, 128, 564, 326, 581, 256, 168, 284, 149, 238, 77,
          113, 65, 269, 359, 243, 279, 455, 330, 357, 102, 116, 406, 364, 551,
          430, 520, 125, 85, 372, 87, 288, 415, 229, 154, 361, 146, 293, 161,
          301, 210, 2, 40, 255, 254, 251, 153, 404, 267, 235, 207, 273, 567,
          289, 260, 95, 555, 152, 378, 56, 37, 20, 437, 414, 142, 18, 220, 494,
          59, 502, 47, 28, 60, 398, 393, 92, 286, 389, 382, 402, 533, 165, 162,
          346, 474, 427, 587, 442, 429, 124, 490, 462, 369, 15, 108, 277, 292,
          537, 58, 451, 348, 272, 130, 271, 340, 96, 434, 217, 270, 156, 36, 50,
          479, 258, 579, 112, 126, 565, 4, 109, 486, 328, 105, 545, 52, 247,
          287, 160, 53, 508, 67, 386, 524, 176, 466, 42, 44, 594, 204, 499, 133,
          118, 9, 30, 66, 107, 426, 316, 403, 199, 5, 57, 115, 84, 363, 595,
          409, 324, 46, 34, 226, 48, 13, 510, 225, 135, 550, 78, 531, 469, 370,
          582, 23, 314, 540, 248, 232, 569, 242, 281, 317, 335, 134, 262, 157,
          208, 522, 315, 327, 209, 90, 187, 536, 196, 175, 367, 6, 547, 500,
        ],
        [
          55, 393, 376, 576, 599, 202, 249, 204, 375, 91, 98, 165, 422, 308,
          208, 561, 304, 247, 118, 396, 144, 397, 198, 88, 77, 254, 256, 593,
          337, 188, 497, 297, 127, 56, 30, 331, 102, 362, 166, 226, 556, 511,
          19, 416, 65, 387, 554, 432, 501, 566, 383, 335, 395, 313, 23, 161,
          366, 361, 117, 69, 100, 9, 96, 595, 418, 28, 228, 265, 557, 324, 573,
          53, 377, 212, 40, 542, 340, 178, 447, 121, 568, 510, 90, 257, 320,
          288, 66, 29, 205, 225, 480, 550, 108, 445, 433, 515, 130, 442, 565,
          516, 360, 169, 171, 419, 2, 240, 421, 520, 333, 420, 482, 5, 231, 532,
          519, 463, 488, 277, 300, 535, 330, 545, 495, 276, 115, 353, 448, 587,
          451, 122, 43, 80, 558, 479, 250, 444, 470, 179, 365, 428, 251, 415,
          485, 390, 42, 245, 410, 113, 437, 469, 398, 177, 97, 258, 51, 314,
          110, 399, 164, 63, 352, 598, 207, 152, 34, 549, 522, 316, 95, 31, 241,
          498, 160, 524, 370, 174, 192, 563, 155, 22, 461, 209, 468, 363, 342,
          172, 581, 131, 0, 286, 181, 348, 21, 41, 380, 76, 435, 560, 407, 67,
          553, 135, 402, 349, 163, 275, 132, 44, 270, 343, 45, 513, 151, 16,
          312, 301, 374, 216, 125, 167, 531, 299, 86, 508, 104, 162, 540, 143,
          434, 74, 272, 404, 364, 543, 430, 319, 75, 564, 20, 559, 323, 582, 13,
          133, 85, 12, 541, 484, 459, 327, 572, 203, 210, 280, 336, 412, 588,
          153, 146, 260, 150, 27, 3, 101, 253, 39, 61, 175, 334, 194, 423, 597,
          1, 347, 345, 409, 586, 283, 427, 503, 379, 149, 585, 341, 546, 33,
          111, 578, 183, 478, 292, 92, 248, 83, 490, 136, 489, 474, 70, 523,
          126, 173, 229, 243, 211, 357, 358, 494, 350, 140, 290, 68, 592, 455,
          218, 264, 157, 116, 180, 328, 129, 536, 215, 406, 156, 570, 186, 214,
          6, 344, 579, 496, 346, 339, 575, 7, 239, 46, 533, 263, 279, 232, 285,
          17, 514, 504, 453, 305, 148, 223, 147, 594, 411, 246, 109, 170, 252,
          454, 201, 62, 48, 189, 268, 405, 274, 487, 317, 589, 414, 154, 527,
          158, 224, 182, 123, 505, 539, 141, 475, 424, 159, 338, 94, 114, 11,
          289, 544, 464, 491, 59, 476, 574, 259, 233, 321, 373, 119, 25, 381,
          107, 413, 273, 213, 168, 458, 562, 452, 293, 372, 530, 471, 467, 492,
          306, 190, 367, 460, 499, 431, 220, 24, 354, 255, 506, 187, 569, 483,
          36, 307, 82, 4, 49, 134, 296, 477, 493, 526, 47, 322, 81, 359, 391,
          14, 142, 38, 217, 426, 591, 425, 32, 356, 378, 385, 54, 266, 295, 10,
          222, 386, 309, 15, 403, 551, 72, 512, 401, 417, 87, 310, 120, 235,
          197, 58, 441, 400, 534, 262, 176, 507, 389, 436, 103, 472, 105, 500,
          236, 440, 408, 227, 392, 368, 481, 329, 35, 388, 584, 326, 84, 529,
          555, 238, 473, 78, 450, 449, 502, 518, 93, 291, 394, 230, 196, 26, 37,
          590, 521, 547, 537, 302, 583, 221, 580, 596, 315, 528, 128, 355, 52,
          325, 538, 219, 525, 79, 281, 244, 99, 486, 311, 206, 271, 548, 184,
          261, 269, 237, 509, 200, 8, 466, 429, 369, 139, 73, 446, 303, 71, 137,
          371, 124, 462, 234, 185, 318, 138, 332, 191, 517, 57, 465, 60, 567,
          438, 571, 456, 199, 439, 287, 443, 242, 112, 577, 195, 18, 457, 278,
          552, 351, 382, 282, 193, 384, 284, 267, 89, 145, 64, 106, 294, 50,
          298,
        ],
        [
          388, 492, 461, 179, 518, 292, 310, 174, 434, 398, 246, 173, 331, 368,
          442, 319, 549, 343, 211, 197, 23, 107, 595, 145, 61, 390, 117, 506,
          124, 414, 473, 45, 581, 247, 104, 258, 175, 585, 43, 106, 553, 560,
          73, 282, 502, 387, 322, 397, 189, 288, 280, 287, 440, 37, 216, 260,
          180, 538, 405, 550, 542, 558, 269, 447, 235, 160, 576, 242, 465, 47,
          158, 272, 289, 79, 58, 51, 281, 225, 451, 185, 312, 166, 392, 255,
          569, 264, 149, 213, 326, 594, 6, 212, 467, 222, 63, 38, 510, 468, 249,
          587, 232, 497, 565, 21, 177, 363, 589, 75, 263, 329, 154, 147, 229,
          399, 551, 253, 2, 22, 146, 347, 413, 137, 321, 91, 421, 31, 307, 277,
          266, 349, 129, 226, 202, 446, 429, 135, 366, 204, 237, 187, 318, 95,
          483, 200, 536, 564, 444, 325, 131, 415, 155, 495, 354, 561, 139, 294,
          324, 419, 515, 119, 432, 223, 46, 338, 579, 28, 333, 369, 110, 256,
          201, 11, 238, 353, 80, 56, 463, 593, 88, 508, 168, 356, 522, 279, 298,
          245, 364, 370, 345, 395, 78, 342, 582, 94, 365, 393, 122, 151, 596,
          334, 100, 236, 54, 286, 448, 186, 220, 167, 178, 257, 548, 303, 472,
          357, 192, 580, 462, 105, 116, 267, 422, 545, 171, 57, 297, 261, 530,
          7, 182, 546, 341, 172, 408, 400, 209, 270, 410, 123, 89, 426, 157,
          113, 394, 401, 118, 215, 362, 470, 163, 520, 208, 103, 68, 586, 140,
          306, 552, 301, 320, 144, 575, 436, 464, 278, 9, 120, 283, 380, 505,
          389, 598, 332, 108, 376, 8, 500, 396, 441, 156, 328, 133, 583, 573,
          224, 112, 81, 126, 109, 191, 190, 537, 121, 26, 453, 570, 336, 125,
          142, 435, 49, 136, 82, 17, 153, 517, 450, 4, 361, 533, 170, 493, 111,
          304, 425, 317, 276, 42, 250, 459, 496, 404, 475, 59, 33, 293, 98, 25,
          359, 574, 406, 519, 439, 77, 3, 183, 99, 438, 39, 165, 274, 430, 296,
          196, 14, 230, 162, 501, 29, 481, 521, 134, 514, 412, 130, 327, 350,
          567, 460, 599, 30, 184, 485, 423, 203, 484, 372, 176, 305, 262, 592,
          367, 544, 457, 291, 60, 141, 83, 535, 590, 97, 206, 355, 534, 482, 69,
          193, 55, 572, 273, 563, 456, 241, 503, 290, 217, 449, 251, 199, 383,
          385, 491, 214, 302, 528, 1, 411, 531, 234, 374, 381, 346, 275, 532,
          27, 499, 308, 70, 311, 516, 479, 386, 115, 10, 36, 219, 313, 490, 513,
          352, 66, 427, 309, 44, 12, 480, 344, 418, 150, 72, 339, 20, 128, 507,
          541, 13, 114, 76, 244, 348, 210, 85, 254, 526, 477, 71, 407, 577, 474,
          375, 431, 159, 424, 466, 527, 420, 19, 471, 504, 557, 228, 568, 323,
          268, 452, 437, 205, 84, 445, 233, 24, 74, 314, 555, 132, 402, 494,
          161, 231, 486, 52, 556, 476, 360, 330, 64, 384, 34, 316, 433, 5, 148,
          65, 35, 523, 102, 248, 285, 96, 371, 340, 543, 597, 529, 227, 50, 428,
          458, 143, 524, 0, 584, 16, 300, 48, 416, 18, 164, 299, 138, 409, 337,
          588, 67, 243, 443, 566, 284, 271, 90, 377, 15, 539, 240, 591, 525,
          169, 487, 32, 218, 40, 195, 239, 152, 295, 373, 259, 86, 489, 547,
          498, 509, 87, 540, 382, 315, 252, 93, 559, 511, 335, 265, 488, 578,
          221, 351, 391, 571, 469, 562, 379, 454, 478, 41, 455, 417, 53, 512,
          207, 127, 403, 181, 92, 378, 101, 554, 198, 358, 194, 188, 62,
        ],
      ],
      c6: [
        [
          587, 370, 390, 466, 281, 398, 233, 197, 520, 429, 503, 75, 361, 133,
          158, 76, 310, 153, 260, 177, 415, 88, 124, 378, 430, 523, 279, 191,
          79, 400, 425, 473, 8, 90, 84, 416, 101, 149, 460, 29, 238, 569, 589,
          574, 586, 171, 507, 227, 305, 585, 100, 550, 401, 337, 319, 258, 42,
          25, 73, 104, 448, 45, 296, 546, 393, 146, 474, 349, 579, 18, 451, 536,
          387, 111, 344, 32, 127, 294, 318, 521, 195, 559, 60, 397, 533, 508,
          72, 175, 462, 6, 345, 517, 53, 212, 147, 292, 250, 241, 588, 306, 525,
          166, 565, 61, 44, 386, 223, 291, 475, 263, 301, 573, 542, 313, 26, 35,
          130, 453, 326, 201, 410, 315, 83, 511, 262, 540, 186, 576, 169, 458,
          59, 391, 396, 456, 71, 308, 584, 81, 459, 231, 432, 170, 323, 37, 4,
          183, 287, 510, 338, 272, 282, 275, 283, 423, 534, 467, 243, 68, 537,
          594, 428, 575, 16, 145, 407, 134, 515, 33, 394, 592, 402, 255, 268,
          267, 330, 509, 253, 359, 2, 514, 367, 108, 248, 188, 67, 530, 46, 368,
          141, 235, 144, 431, 9, 470, 572, 595, 364, 513, 113, 220, 307, 471,
          469, 102, 114, 137, 269, 123, 266, 329, 103, 414, 528, 341, 49, 527,
          228, 472, 328, 445, 543, 12, 20, 242, 107, 478, 438, 489, 244, 30,
          374, 109, 443, 58, 480, 309, 74, 531, 436, 519, 96, 567, 502, 495,
          454, 36, 297, 353, 366, 555, 422, 256, 225, 77, 580, 200, 185, 437,
          99, 196, 214, 369, 421, 273, 490, 465, 538, 120, 371, 259, 251, 599,
          358, 167, 363, 518, 132, 43, 596, 479, 52, 27, 342, 122, 446, 50, 484,
          93, 332, 570, 271, 561, 522, 331, 117, 547, 455, 92, 545, 440, 362,
          62, 139, 316, 552, 125, 38, 529, 211, 541, 481, 380, 97, 417, 404, 22,
          274, 187, 412, 347, 216, 314, 506, 135, 298, 239, 324, 5, 333, 593,
          497, 426, 265, 154, 295, 226, 311, 119, 219, 276, 293, 245, 247, 278,
          222, 384, 47, 560, 205, 152, 41, 505, 441, 204, 492, 131, 399, 198,
          468, 181, 155, 63, 179, 190, 165, 210, 173, 164, 348, 289, 372, 194,
          566, 21, 264, 280, 427, 304, 110, 163, 150, 493, 356, 202, 91, 95,
          504, 444, 19, 582, 571, 159, 302, 240, 299, 411, 558, 483, 365, 418,
          385, 121, 350, 420, 597, 206, 208, 346, 40, 487, 55, 57, 11, 539, 598,
          157, 69, 85, 189, 449, 15, 277, 360, 419, 336, 450, 434, 105, 578,
          270, 23, 156, 142, 236, 193, 126, 379, 28, 317, 551, 70, 192, 180,
          376, 66, 491, 435, 339, 290, 583, 207, 312, 0, 136, 392, 408, 409,
          526, 457, 300, 500, 34, 160, 476, 17, 524, 482, 557, 320, 51, 512,
          389, 184, 447, 129, 544, 413, 115, 65, 581, 98, 327, 424, 442, 377,
          564, 14, 303, 176, 382, 31, 464, 257, 172, 321, 229, 178, 501, 230,
          234, 285, 64, 143, 284, 477, 237, 357, 351, 405, 334, 224, 288, 355,
          54, 1, 89, 252, 3, 381, 461, 13, 433, 535, 563, 168, 261, 549, 488,
          556, 463, 112, 118, 548, 485, 375, 352, 395, 354, 516, 553, 116, 340,
          568, 232, 213, 174, 209, 494, 590, 335, 148, 128, 591, 406, 86, 403,
          56, 151, 106, 78, 80, 322, 87, 39, 94, 554, 452, 325, 532, 383, 162,
          246, 199, 217, 577, 439, 373, 498, 218, 7, 182, 388, 221, 10, 499, 24,
          138, 254, 496, 82, 161, 140, 486, 215, 286, 249, 562, 343, 48, 203,
        ],
        [
          565, 386, 138, 221, 6, 69, 416, 363, 133, 477, 31, 216, 388, 29, 38,
          134, 266, 381, 74, 447, 279, 37, 423, 323, 254, 338, 129, 259, 535,
          397, 187, 236, 595, 298, 405, 146, 35, 574, 538, 50, 227, 476, 90, 83,
          36, 474, 240, 508, 350, 217, 198, 182, 234, 345, 571, 430, 274, 165,
          306, 86, 91, 492, 226, 68, 450, 495, 318, 4, 128, 142, 105, 171, 44,
          136, 377, 166, 206, 275, 126, 159, 550, 229, 81, 431, 307, 530, 333,
          572, 471, 286, 521, 167, 393, 340, 368, 451, 324, 419, 297, 76, 248,
          341, 180, 510, 464, 92, 17, 316, 549, 181, 249, 174, 175, 71, 97, 230,
          454, 444, 395, 308, 398, 374, 387, 101, 25, 357, 438, 482, 28, 401,
          596, 245, 424, 121, 260, 96, 433, 351, 555, 178, 319, 262, 30, 283,
          57, 79, 520, 228, 502, 421, 302, 52, 232, 204, 10, 196, 465, 346, 251,
          427, 9, 440, 526, 518, 384, 285, 256, 380, 0, 299, 506, 500, 199, 599,
          247, 379, 365, 396, 325, 486, 446, 127, 459, 499, 366, 589, 480, 290,
          415, 460, 288, 413, 287, 545, 501, 13, 448, 445, 93, 359, 343, 265,
          24, 210, 584, 525, 250, 61, 19, 404, 560, 117, 293, 581, 156, 8, 470,
          575, 237, 173, 517, 523, 458, 590, 139, 303, 466, 94, 3, 207, 409, 12,
          573, 194, 55, 453, 124, 276, 189, 188, 88, 5, 80, 339, 559, 184, 367,
          263, 267, 186, 534, 564, 554, 547, 244, 272, 579, 583, 362, 314, 566,
          417, 435, 11, 488, 403, 116, 143, 15, 268, 191, 475, 561, 64, 103,
          102, 548, 231, 371, 385, 141, 410, 289, 478, 23, 361, 531, 170, 537,
          455, 315, 414, 177, 238, 78, 582, 412, 202, 281, 507, 334, 497, 45,
          576, 22, 468, 330, 110, 220, 426, 130, 183, 99, 498, 332, 364, 112,
          163, 111, 241, 284, 118, 39, 487, 587, 162, 420, 295, 569, 205, 544,
          33, 407, 257, 356, 402, 484, 222, 73, 586, 490, 59, 376, 578, 467,
          442, 536, 132, 48, 378, 422, 355, 203, 125, 215, 277, 157, 349, 212,
          119, 558, 483, 255, 54, 400, 411, 382, 21, 469, 107, 148, 443, 472,
          342, 320, 169, 522, 593, 87, 301, 556, 100, 147, 282, 533, 372, 494,
          161, 269, 304, 75, 89, 528, 140, 336, 370, 235, 224, 392, 541, 185,
          437, 109, 239, 347, 329, 192, 408, 280, 594, 108, 373, 144, 540, 131,
          294, 270, 154, 449, 70, 160, 493, 41, 505, 390, 225, 389, 49, 113, 60,
          214, 46, 496, 34, 598, 399, 291, 503, 337, 243, 190, 120, 439, 331,
          585, 137, 479, 539, 219, 326, 209, 14, 77, 553, 546, 193, 85, 489,
          511, 515, 452, 456, 65, 51, 195, 122, 98, 20, 591, 588, 172, 432, 32,
          461, 425, 568, 567, 344, 354, 563, 418, 123, 513, 552, 18, 179, 327,
          233, 62, 529, 168, 53, 164, 516, 82, 197, 310, 311, 300, 524, 551,
          394, 429, 562, 145, 47, 592, 104, 570, 462, 67, 317, 491, 218, 406,
          26, 428, 200, 58, 485, 321, 358, 557, 292, 441, 43, 114, 1, 273, 152,
          211, 322, 352, 66, 328, 2, 504, 176, 158, 543, 264, 312, 383, 246,
          391, 252, 135, 153, 56, 532, 258, 580, 84, 360, 577, 115, 481, 42,
          296, 542, 436, 369, 150, 63, 7, 348, 463, 201, 278, 597, 353, 213, 72,
          375, 155, 106, 151, 519, 309, 335, 514, 527, 261, 208, 271, 27, 253,
          95, 512, 434, 242, 40, 16, 149, 473, 313, 223, 509, 457, 305,
        ],
        [
          390, 242, 288, 378, 511, 582, 549, 299, 499, 441, 314, 423, 478, 24,
          513, 100, 592, 413, 23, 425, 442, 407, 302, 80, 355, 321, 258, 99,
          189, 56, 143, 564, 325, 514, 518, 373, 124, 431, 70, 216, 438, 154,
          179, 543, 489, 461, 41, 586, 345, 593, 195, 533, 426, 311, 243, 584,
          550, 537, 150, 540, 45, 191, 481, 523, 2, 112, 417, 120, 385, 433,
          268, 238, 249, 328, 129, 503, 116, 532, 81, 429, 126, 22, 168, 401,
          535, 251, 59, 92, 55, 31, 368, 534, 285, 415, 78, 337, 37, 69, 230,
          334, 72, 453, 313, 598, 42, 580, 560, 485, 421, 344, 151, 147, 389,
          437, 0, 240, 106, 315, 578, 170, 244, 279, 219, 464, 241, 34, 186,
          113, 454, 504, 104, 260, 599, 125, 585, 196, 82, 466, 65, 73, 520, 16,
          462, 149, 384, 18, 184, 203, 8, 310, 109, 434, 354, 436, 406, 527,
          547, 396, 123, 352, 74, 456, 245, 477, 505, 27, 145, 487, 490, 343,
          495, 271, 360, 517, 183, 370, 88, 286, 393, 340, 84, 233, 361, 508,
          21, 48, 209, 5, 512, 40, 57, 11, 261, 291, 83, 187, 595, 444, 15, 135,
          284, 28, 439, 77, 50, 226, 269, 551, 356, 204, 32, 562, 419, 335, 588,
          229, 171, 128, 166, 386, 146, 58, 36, 64, 282, 382, 122, 525, 139,
          563, 292, 472, 210, 565, 51, 105, 372, 450, 395, 309, 376, 35, 324,
          164, 152, 293, 363, 98, 173, 577, 262, 278, 483, 208, 596, 200, 190,
          165, 182, 400, 85, 447, 326, 192, 264, 409, 357, 536, 448, 377, 475,
          237, 156, 6, 369, 486, 306, 446, 394, 62, 422, 119, 79, 440, 468, 162,
          458, 597, 319, 206, 117, 234, 515, 387, 590, 9, 428, 546, 273, 575,
          488, 294, 496, 255, 110, 281, 509, 201, 266, 392, 172, 121, 157, 103,
          474, 318, 127, 250, 307, 86, 570, 411, 43, 221, 235, 46, 25, 101, 303,
          416, 158, 277, 300, 38, 270, 362, 541, 381, 169, 359, 212, 558, 404,
          87, 341, 427, 332, 480, 96, 402, 424, 176, 248, 180, 510, 379, 140,
          568, 131, 148, 54, 308, 254, 142, 573, 222, 530, 274, 455, 49, 223,
          253, 26, 519, 160, 353, 95, 576, 581, 304, 587, 594, 295, 501, 459,
          349, 521, 144, 476, 516, 290, 39, 107, 403, 548, 296, 506, 89, 500,
          236, 217, 287, 68, 225, 531, 1, 137, 484, 365, 449, 215, 30, 161, 102,
          3, 194, 4, 397, 366, 358, 301, 465, 367, 218, 342, 391, 524, 572, 346,
          224, 29, 347, 538, 193, 507, 589, 198, 133, 418, 410, 556, 214, 571,
          185, 115, 316, 163, 567, 555, 526, 502, 348, 93, 482, 553, 350, 552,
          19, 61, 317, 522, 452, 380, 473, 333, 443, 339, 336, 305, 554, 451,
          430, 323, 202, 463, 90, 375, 111, 33, 583, 256, 53, 141, 197, 63, 298,
          297, 205, 539, 17, 220, 265, 138, 188, 276, 312, 371, 227, 263, 330,
          591, 130, 118, 280, 181, 91, 492, 471, 178, 445, 320, 542, 529, 561,
          47, 167, 52, 153, 259, 97, 329, 432, 67, 10, 267, 136, 159, 228, 239,
          252, 399, 544, 213, 579, 132, 460, 374, 13, 405, 408, 498, 14, 71,
          566, 479, 388, 108, 211, 231, 20, 545, 569, 275, 467, 207, 557, 246,
          493, 559, 497, 470, 257, 283, 174, 7, 435, 338, 528, 414, 247, 398,
          469, 322, 60, 66, 574, 12, 75, 331, 134, 364, 420, 44, 94, 272, 177,
          114, 175, 289, 155, 76, 199, 232, 457, 351, 327, 491, 494, 412, 383,
        ],
        [
          391, 4, 519, 58, 447, 34, 84, 339, 308, 347, 530, 369, 564, 195, 379,
          99, 599, 496, 289, 96, 328, 200, 29, 43, 139, 309, 583, 556, 54, 90,
          72, 206, 594, 593, 397, 433, 45, 414, 330, 567, 68, 342, 312, 364,
          228, 325, 252, 387, 436, 402, 114, 150, 160, 217, 393, 408, 265, 112,
          201, 174, 455, 298, 598, 313, 516, 468, 372, 245, 212, 173, 268, 464,
          100, 219, 209, 316, 491, 167, 242, 480, 492, 338, 17, 437, 548, 32,
          448, 352, 500, 595, 341, 146, 15, 552, 510, 523, 247, 413, 97, 355,
          528, 111, 346, 407, 282, 498, 79, 395, 345, 190, 546, 409, 83, 105,
          204, 454, 381, 472, 410, 66, 334, 152, 425, 493, 115, 291, 440, 261,
          9, 540, 489, 419, 292, 508, 520, 41, 351, 358, 277, 179, 182, 287,
          597, 299, 304, 22, 61, 113, 423, 483, 350, 513, 502, 333, 151, 31,
          406, 344, 259, 211, 251, 574, 140, 198, 488, 503, 187, 143, 86, 73,
          307, 362, 507, 300, 399, 592, 301, 8, 122, 432, 451, 239, 494, 332,
          438, 365, 103, 310, 322, 457, 485, 577, 366, 579, 125, 23, 533, 63,
          539, 535, 569, 75, 532, 267, 553, 576, 59, 87, 584, 250, 415, 133,
          412, 121, 21, 69, 260, 382, 194, 131, 537, 238, 380, 456, 430, 371,
          218, 220, 226, 215, 302, 145, 404, 224, 234, 169, 318, 3, 129, 164,
          506, 487, 463, 531, 568, 271, 88, 465, 124, 426, 434, 512, 571, 216,
          495, 469, 236, 538, 477, 484, 193, 56, 525, 171, 439, 335, 296, 411,
          165, 1, 126, 320, 48, 5, 16, 478, 207, 91, 6, 230, 2, 78, 476, 119,
          295, 109, 555, 134, 46, 28, 509, 276, 441, 306, 349, 427, 110, 431,
          222, 400, 490, 368, 51, 353, 343, 180, 385, 154, 565, 98, 544, 467,
          225, 305, 444, 418, 37, 515, 172, 235, 356, 590, 30, 35, 118, 384,
          389, 108, 13, 319, 416, 396, 348, 551, 186, 237, 213, 337, 329, 196,
          47, 205, 70, 76, 249, 324, 136, 67, 317, 547, 511, 461, 375, 178, 286,
          294, 89, 185, 452, 149, 573, 55, 390, 264, 74, 233, 208, 241, 181,
          127, 60, 534, 374, 137, 293, 482, 18, 240, 142, 417, 542, 429, 459,
          64, 392, 144, 255, 481, 585, 232, 475, 486, 197, 370, 266, 479, 12,
          311, 527, 183, 243, 106, 0, 558, 14, 57, 327, 336, 117, 388, 360, 33,
          262, 471, 543, 227, 163, 27, 284, 132, 156, 297, 422, 529, 497, 81,
          92, 591, 258, 462, 39, 175, 280, 141, 147, 52, 94, 518, 449, 549, 504,
          450, 38, 589, 581, 521, 168, 559, 159, 443, 428, 561, 363, 596, 153,
          246, 373, 470, 572, 582, 263, 357, 210, 231, 248, 314, 458, 386, 290,
          578, 273, 71, 26, 377, 398, 361, 499, 536, 378, 50, 281, 288, 128,
          104, 155, 376, 453, 158, 102, 157, 442, 202, 199, 545, 95, 466, 11,
          192, 176, 405, 557, 123, 445, 505, 541, 148, 435, 269, 526, 244, 587,
          138, 177, 562, 359, 575, 514, 49, 40, 53, 522, 563, 223, 256, 214,
          221, 315, 170, 135, 20, 275, 278, 517, 120, 254, 570, 257, 524, 560,
          303, 44, 19, 10, 474, 188, 588, 354, 554, 65, 42, 403, 367, 166, 501,
          184, 272, 274, 283, 85, 107, 189, 130, 394, 80, 253, 473, 580, 101,
          162, 586, 550, 77, 446, 285, 36, 331, 323, 191, 401, 203, 24, 25, 424,
          321, 420, 161, 383, 270, 62, 421, 82, 460, 7, 93, 566, 116, 279, 326,
          340, 229,
        ],
        [
          274, 325, 86, 567, 300, 510, 451, 339, 291, 271, 572, 323, 79, 248,
          176, 229, 178, 525, 501, 326, 333, 546, 362, 308, 361, 400, 555, 521,
          394, 277, 584, 258, 375, 579, 144, 7, 435, 0, 545, 295, 499, 342, 415,
          92, 241, 191, 18, 199, 347, 334, 245, 138, 426, 104, 594, 414, 494,
          320, 324, 370, 485, 492, 305, 232, 227, 596, 122, 278, 453, 170, 404,
          588, 541, 322, 552, 407, 257, 36, 237, 222, 251, 506, 174, 33, 238,
          181, 211, 12, 17, 481, 27, 309, 464, 69, 143, 169, 528, 155, 522, 484,
          150, 198, 66, 157, 425, 275, 24, 152, 395, 184, 282, 102, 132, 186,
          128, 121, 403, 562, 126, 13, 508, 30, 597, 504, 182, 441, 585, 357,
          63, 225, 154, 267, 44, 448, 73, 259, 108, 592, 262, 467, 573, 429,
          266, 547, 369, 185, 208, 496, 139, 580, 133, 490, 280, 99, 340, 529,
          228, 250, 461, 548, 200, 34, 234, 233, 113, 207, 93, 477, 168, 94, 96,
          253, 390, 268, 500, 221, 437, 254, 5, 576, 568, 447, 247, 405, 293,
          67, 26, 299, 239, 160, 401, 35, 442, 235, 431, 543, 520, 61, 509, 284,
          252, 70, 145, 343, 219, 21, 76, 488, 151, 329, 380, 338, 95, 224, 590,
          3, 314, 273, 387, 72, 327, 450, 413, 134, 78, 117, 41, 458, 337, 75,
          48, 365, 80, 261, 537, 518, 217, 440, 65, 511, 223, 486, 368, 423,
          495, 539, 315, 445, 89, 358, 470, 561, 443, 542, 114, 129, 419, 135,
          294, 536, 556, 383, 352, 116, 371, 468, 366, 204, 532, 125, 393, 385,
          565, 336, 559, 47, 60, 586, 71, 240, 396, 9, 599, 6, 318, 526, 302,
          351, 527, 533, 389, 215, 190, 90, 98, 22, 534, 123, 51, 244, 166, 220,
          406, 551, 264, 59, 281, 574, 432, 210, 452, 428, 446, 15, 130, 519,
          148, 140, 469, 243, 124, 359, 81, 87, 483, 582, 83, 20, 82, 119, 109,
          242, 175, 39, 38, 497, 57, 569, 514, 31, 177, 197, 206, 49, 42, 64,
          137, 187, 473, 297, 290, 563, 32, 283, 535, 476, 350, 171, 183, 216,
          420, 502, 263, 105, 498, 416, 321, 162, 316, 505, 103, 46, 479, 55,
          524, 462, 422, 43, 471, 566, 544, 101, 571, 149, 478, 434, 270, 111,
          564, 1, 581, 265, 29, 141, 163, 307, 188, 328, 591, 332, 231, 392,
          142, 489, 411, 578, 226, 397, 2, 319, 399, 288, 557, 317, 460, 427,
          466, 272, 360, 355, 512, 203, 100, 598, 156, 491, 306, 194, 54, 454,
          91, 377, 353, 593, 474, 292, 549, 367, 372, 456, 256, 335, 260, 68,
          388, 301, 213, 386, 106, 173, 363, 212, 560, 303, 153, 312, 146, 62,
          136, 409, 475, 330, 575, 444, 289, 40, 493, 201, 570, 356, 472, 52,
          172, 517, 577, 304, 56, 23, 513, 192, 25, 449, 218, 158, 193, 457,
          285, 118, 313, 53, 19, 147, 595, 530, 438, 164, 286, 127, 88, 276,
          331, 10, 531, 439, 28, 507, 341, 463, 37, 202, 424, 412, 381, 311, 58,
          77, 167, 378, 298, 455, 74, 433, 255, 14, 523, 11, 553, 354, 195, 165,
          589, 249, 410, 540, 180, 115, 587, 310, 50, 189, 45, 379, 296, 516,
          344, 408, 196, 583, 85, 348, 480, 376, 384, 345, 430, 364, 421, 515,
          179, 110, 482, 131, 107, 558, 550, 487, 214, 349, 230, 459, 503, 417,
          346, 84, 391, 402, 554, 8, 209, 16, 159, 373, 4, 287, 279, 120, 246,
          465, 269, 205, 236, 382, 418, 161, 97, 112, 374, 538, 398, 436,
        ],
        [
          555, 360, 210, 520, 151, 593, 61, 438, 285, 526, 508, 145, 381, 311,
          568, 333, 340, 229, 71, 259, 204, 128, 195, 318, 295, 530, 86, 499,
          503, 303, 0, 335, 370, 402, 96, 200, 148, 176, 91, 348, 247, 66, 203,
          269, 98, 248, 591, 406, 258, 450, 518, 213, 542, 537, 346, 214, 55,
          446, 223, 74, 554, 14, 275, 342, 517, 255, 550, 219, 190, 389, 9, 390,
          586, 225, 47, 64, 563, 63, 116, 383, 16, 422, 596, 6, 570, 244, 407,
          177, 114, 20, 4, 379, 35, 250, 31, 349, 227, 262, 312, 62, 273, 378,
          414, 337, 276, 32, 435, 106, 217, 88, 40, 198, 22, 37, 513, 107, 192,
          465, 277, 159, 231, 233, 158, 365, 78, 375, 174, 367, 566, 207, 353,
          84, 533, 152, 548, 403, 97, 301, 82, 558, 126, 574, 83, 133, 528, 260,
          451, 587, 559, 329, 511, 484, 202, 599, 288, 189, 441, 156, 579, 124,
          185, 178, 12, 268, 183, 166, 144, 101, 253, 122, 475, 162, 387, 428,
          456, 181, 54, 179, 330, 332, 458, 426, 468, 25, 540, 522, 486, 43,
          443, 358, 119, 325, 146, 105, 547, 531, 442, 322, 320, 369, 459, 228,
          27, 230, 388, 279, 139, 490, 523, 211, 474, 2, 125, 302, 394, 469,
          421, 536, 427, 69, 440, 580, 264, 314, 345, 416, 419, 77, 172, 194,
          282, 29, 90, 448, 543, 291, 313, 240, 351, 327, 447, 304, 462, 308,
          79, 44, 585, 72, 212, 75, 286, 432, 300, 326, 396, 48, 481, 154, 33,
          252, 120, 544, 461, 220, 460, 539, 592, 482, 221, 81, 538, 521, 251,
          519, 319, 545, 350, 53, 392, 93, 331, 216, 470, 413, 7, 395, 267, 123,
          476, 341, 169, 10, 226, 296, 565, 496, 506, 509, 234, 473, 51, 556,
          437, 18, 281, 372, 87, 129, 149, 478, 535, 480, 400, 23, 274, 527,
          576, 524, 502, 38, 99, 553, 577, 361, 433, 452, 363, 271, 501, 284,
          95, 493, 11, 184, 382, 290, 188, 58, 355, 380, 254, 411, 584, 561, 45,
          143, 430, 292, 336, 131, 417, 127, 163, 121, 186, 412, 384, 249, 453,
          404, 280, 514, 371, 289, 278, 541, 487, 245, 167, 135, 297, 173, 3,
          334, 28, 59, 17, 324, 497, 488, 34, 171, 479, 19, 94, 104, 236, 552,
          409, 424, 201, 562, 339, 168, 500, 512, 241, 208, 597, 232, 102, 310,
          564, 401, 46, 1, 347, 49, 113, 354, 306, 516, 80, 505, 117, 218, 368,
          215, 197, 466, 237, 26, 39, 323, 410, 393, 321, 594, 315, 52, 222,
          439, 567, 431, 235, 359, 42, 283, 132, 180, 546, 357, 92, 588, 60,
          256, 494, 103, 205, 21, 50, 467, 581, 572, 362, 590, 463, 165, 115,
          477, 374, 471, 170, 13, 206, 293, 309, 418, 549, 137, 445, 8, 30, 147,
          598, 199, 130, 272, 492, 24, 436, 504, 575, 175, 485, 589, 36, 266,
          489, 112, 316, 187, 449, 376, 136, 100, 454, 405, 344, 398, 317, 352,
          386, 434, 560, 429, 193, 56, 425, 110, 238, 595, 483, 140, 141, 328,
          373, 108, 529, 338, 15, 70, 142, 399, 294, 307, 41, 582, 457, 569,
          263, 270, 242, 111, 420, 525, 239, 498, 67, 356, 491, 153, 507, 5,
          464, 196, 391, 65, 578, 299, 455, 583, 243, 261, 397, 472, 534, 423,
          557, 287, 532, 551, 224, 73, 191, 182, 85, 573, 109, 298, 364, 138,
          164, 257, 408, 366, 571, 209, 385, 510, 150, 265, 161, 89, 118, 155,
          415, 76, 57, 134, 160, 246, 515, 444, 343, 305, 68, 495, 157, 377,
        ],
      ],
      ount: false,
    };
  },
  watch: {
    add(val) {
      if (val == false) {
        this.getRoomList1();
      }
    },
     showNav(newval,oldval) {

    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getRoomList();
    this.getBack();
    let param = {};
    this.$axios
      .post("/index/User/playerInfo", this.$qs.stringify(param))
      .then((res) => {
        var data = res.data;
        if (data.status == "1") {
          localStorage.setItem("userInfo", JSON.stringify(data.data));
          if (localStorage.getItem("userInfo")) {
            this.homeid = JSON.parse(localStorage.getItem("userInfo")).id;
          }
        }
      });
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    //关闭
    //clearInterval(this.timer);
    this.websock.close();
  },
  methods: {
    backcssVars() {
      return `url("${this.img}")`;
    },
    //音乐 播放
    playAlarm1() {
      this.$refs.notify1.play();
    },
    //音乐 结束
    playAlarm2() {
      this.$refs.notify1.pause();
    },
    //推送
    initWebSocket() {
      const wsuri = battle();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    websocketonopen() {},

    websocketonerror() {
      this.initWebSocket();
    },

    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    websocketonmessage(d) {
      let da = JSON.parse(d.data);
      if (da.info) {
        this.sockBattleList(da);
      }
    },

    websocketsend(Data) {
      if (this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(Data);
      }
    },

    websocketclose(e) {
      //console.log("close:", e);
    },

    sockBattleList(data) {
      console.log("推送数据->:", data.info);
      if (data.info) {
        if (data.info.id == this.id) {
          if (data.info.status != 3) {
            this.getRoomList();
          }
          if (data.info.status == 3) {
            // console.log("状态3")
            this.add = false;
          }
        }
      }
    },

    //跳转
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
    //获取房间信息
    getRoomList(over, tt) {
      if (this.add) {
        return;
      }
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/battleDetail", this.$qs.stringify(param))
        .then((res) => {
          this.playmode = res.data.data.playmode;
          console.log(res.data.data.playmode, "data");
          if (res.data.data.playmode == 1) {
            this.imgs = require("../assets/img/ranking/win.png");
          } else if (res.data.data.playmode == 2) {
            this.imgs = require("../assets/img/ranking/wins.png");
          }
          var JSONres = JSON.parse(JSON.stringify(res));
          var aaa = JSON.parse(JSON.stringify(res.data.data));
          var playerinfo = JSON.parse(
            JSON.stringify(res.data.data.player_info)
          );
          if (over) {
            var re = {
              state: "update",
              info: res.data.data,
            };
            Utils.$emit("update", JSON.stringify(re));
          }
          var data = res.data;
          console.log("房间信息", aaa, data);
          // 对战等待中
          if (data.status == "1") {
            this.statuslist = aaa;
            console.log(this.statuslist, "statuslist");
            this.fightboxList = data.data.boxInfo;
            for (let i = 0; i < this.fightboxList.length; i++) {
              this.boxList.push(this.fightboxList[i].skin_list);
            }
            this.peopleList = data.data.player_info;
            // console.log(this.peopleList, "pppppppppppppppeople");
            var mode = data.data.mode;
            var peopleListCopy = JSON.parse(JSON.stringify(this.peopleList)); //深拷贝
            if (mode != peopleListCopy.length) {
              for (let i = 0; i < mode - peopleListCopy.length; i++) {
                this.peopleList.push(this.peopleObj);
              }
            }
            for (let i = 0; i < this.peopleList.length; i++) {
              if (this.peopleList[i].id) {
                this.peopleList[i].state = true;
              } else {
                this.peopleList[i].state = false;
              }
              //   if (mode == 2) {
              //     this.peopleList[i].width = "48%";
              //   } else if (mode == 3) {
              //     this.peopleList[i].width = "33.33%";
              //   } else {
              //     this.peopleList[i].width = "25%";
              //   }
            }

            //对战的滚动图片数据
            for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }
            //生成滚动的图片数组 Math.floor(30 / this.fightImg[0].length) + 1(循环次数，保证数据至少30个)
            if (this.fightImg.length == 1) {
              for (
                let i = 0;
                i < Math.floor(100 / this.fightImg[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.fightImg[0].length; j++) {
                  this.fightImgObj.push(this.fightImg[0][j]);
                  this.fightImgObj = this.fightImgObj.slice(0, 100);
                  this.fightImgObj = this.getRandomArr(this.fightImgObj, 100);
                }
              }
            } else if (this.fightImg.length != 1) {
              for (let i = 0; i < this.fightImg.length; i++) {
                for (
                  let j = 0;
                  j < Math.floor(100 / this.fightImg[i].length) + 1;
                  j++
                ) {
                  for (let x = 0; x < this.fightImg[i].length; x++) {
                    this.fightImgObj.push(this.fightImg[i][x]);
                    this.fightImgObj = this.fightImgObj.slice(0, 100 * (i + 1));
                    //没有打乱顺序
                    // this.fightImgObj = this.getRandomArr(this.fightImgObj,20 * (i+1))
                  }
                }
              }
            }
            //把滚动数据放在各自下
            if (JSON.parse(JSONres.data.data.result_info)) {
              var winner_owner = JSON.parse(JSONres.data.data.result_info);
              var newin = [];
              winner_owner.forEach((e) => {
                e.forEach((es) => {
                  newin.push(es);
                });
              });
              winner_owner = newin;
              // console.log()
              var array = [];
              if (
                winner_owner.length == 0 &&
                JSONres.data.data.player_info[0]["skin_list"]
              ) {
                for (
                  var isv = 0;
                  isv < JSONres.data.data.player_info.length;
                  isv++
                ) {
                  console.log(isv);
                  if (JSONres.data.data.player_info[isv]["skin_list"]) {
                    for (
                      var isv1 = 0;
                      isv1 <
                      JSONres.data.data.player_info[isv]["skin_list"].length;
                      isv1++
                    ) {
                      winner_owner.push(
                        JSONres.data.data.player_info[isv]["skin_list"][isv1]
                      );
                    }
                  } else {
                    winner_owner.push({
                      ...this.peopleObj,
                      name: this.$t("凉凉奖励"),
                      price: 0.01,
                    });
                  }
                }
              }
              if (winner_owner) {
                for (var i = 0; i < this.peopleList.length; i++) {
                  var json = JSON.parse(JSON.stringify(this.fightImgObj));
                  var a = i + 1;
                  json = this.getNRandom(json, data.data.create_time, a);
                  var z = 1;
                  var c = i;
                  for (var iv = 90; iv < json.length; iv += 90) {
                    if (winner_owner.length == 2) {
                      winner_owner[i] = {
                        id: winner_owner[i]["id"],
                        imageUrl: winner_owner[i]["img"],
                        itemName: winner_owner[i]["name"],
                        color: winner_owner[i]["color"],
                        img: winner_owner[i]["img"],
                        name: winner_owner[i]["name"],
                        price: winner_owner[i]["price"],
                        shortName: winner_owner[i]["shortName"],
                        english_shortName: winner_owner[i]["shortName"],
                        exteriorName: winner_owner[i]["exteriorName"],
                        english_exteriorName: winner_owner[i]["exteriorName"],
                      };
                    } else {
                      if (winner_owner[c]) {
                        winner_owner[c] = {
                          id: winner_owner[c]["id"],
                          imageUrl: winner_owner[c]["img"],
                          itemName: winner_owner[c]["name"],
                          color: winner_owner[c]["color"],
                          img: winner_owner[i]["img"],
                          name: winner_owner[i]["name"],
                          price: winner_owner[i]["price"],
                          shortName: winner_owner[c]["shortName"],
                        english_shortName: winner_owner[c]["shortName"],
                        exteriorName: winner_owner[c]["exteriorName"],
                        english_exteriorName: winner_owner[c]["exteriorName"],
                        };
                      }
                    }
                    if (iv < json.length) {
                      if (winner_owner.length == playerinfo.length) {
                        json[iv] = winner_owner[i];
                      } else {
                        if (winner_owner[c]) {
                          json[iv] = winner_owner[c];
                        }
                      }
                      c += playerinfo.length;
                      z++;
                    }
                  }
                  array.push(json);
                }
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].fightBox = array[i];
                }
              } else {
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.fightImgObj = this.getNRandom(
                    this.fightImgObj,
                    data.data.create_time,
                    i + 1
                  );
                  this.peopleList[i].fightBox = this.fightImgObj;
                }
              }
            }
            //对战进行中2  对战未开始1  3结束
            if (data.data.status == "3") {
              this.playAlarm2();
              var box = data.data.winner_owner;
              let numPrice = 0;
              //是否为平局
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.fightboxList[i].state = false;
              }
              this.totalPrice = 0;
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.totalPrice += Number(this.fightboxList[i].price);
              }
              if (box.length == 0) {
                for (let i = 0; i < this.peopleList.length; i++) {
                  var loser = data.data.loser_reward;
                  this.peopleList[i].box = this.peopleList[i].skin_list;
                  this.peopleList[i].end = 2;
                  this.peopleList[i].winState = true;
                  this.peopleList[i].totalPrice = 0;
                  if (this.peopleList[i].skin_list == undefined) {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    // this.peopleList[i].totalPrice = "0.01";
                    // this.peopleList[i].box[0].name = "凉凉奖励";
                    this.peopleList[i].totalPrice = loser.price;
                    this.peopleList[i].box[0].price = loser.price;
                    this.peopleList[i].box[0].name = loser.itemName;
                    this.peopleList[i].box[0].img = loser.imageUrl;
                    this.peopleList[i].box[0].img = loser.imageUrl;
                    this.peopleList[i].box[0].shortName = loser.shortName;
                    this.peopleList[i].box[0].english_shortName = loser.english_shortName;
                    this.peopleList[i].box[0].english_exteriorName = loser.english_exteriorName;
                    this.peopleList[i].box[0].exteriorName = loser.exteriorName;
                    
                  } else {
                    for (let j = 0; j < this.peopleList[i].box.length; j++) {
                      this.peopleList[i].totalPrice += Number(
                        this.peopleList[i].box[j].price
                      );
                    }
                    this.peopleList[i].totalPrice = Number(
                      this.peopleList[i].totalPrice
                    ).toFixed(2);
                  }
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  // this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              } else {
                this.thisfightboxList = this.fightboxList.length;
                for (let i = 0; i < this.peopleList.length; i++) {
                  if (this.peopleList[i].id == data.data.winner) {
                    this.peopleList[i].box = box;
                    this.peopleList[i].totalPrice = 0;
                    this.peopleList[i].winState = true;
                    for (let j = 0; j < box.length; j++) {
                      this.peopleList[i].totalPrice += Number(box[j].price);
                    }
                  } else {
                    var loser = data.data.loser_reward;
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    // this.peopleList[i].totalPrice = "0.01";
                    // this.peopleList[i].box[0].name = "凉凉奖励";
                    this.peopleList[i].totalPrice = loser.price;
                    this.peopleList[i].box[0].price = loser.price;
                    this.peopleList[i].box[0].name = loser.itemName;
                    this.peopleList[i].box[0].img = loser.imageUrl;
                    this.peopleList[i].box[0].shortName = loser.shortName;
                    this.peopleList[i].box[0].english_shortName = loser.english_shortName;
                    this.peopleList[i].box[0].english_exteriorName = loser.english_exteriorName;
                    this.peopleList[i].box[0].exteriorName = loser.exteriorName;
                    console.log(this.peopleList[i]);
                  }
                  this.peopleList[i].totalPrice = Number(
                    this.peopleList[i].totalPrice
                  ).toFixed(2);
                  this.peopleList[i].end = 2;
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  // this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              }
              if (!tt) {
                // setTimeout(function() {
                // 	this.getRoomList(null, "tt");
                // }, 100);
              }
            } else if (data.data.status == "2") {
              //对战进行中
              this.fightResult1();
            } else {
              //对战未开始
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].end = 1;
              }
              this.totalPrice = 0;
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.totalPrice += Number(this.fightboxList[i].price);
                this.fightboxList[i].state = false;
                this.fightboxList[0].state = false;
              }
            }
          }
        })
        .catch((reason) => {
          //this.getRoomList();
          console.log(reason);
        });
    },
    getRoomList1(tt) {
      let _this = this;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/battleDetail", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.status == "1") {
            this.fightboxList = data.data.boxInfo;
            for (let i = 0; i < this.fightboxList.length; i++) {
              this.boxList.push(this.fightboxList[i].skin_list);
              
            }
            this.peopleList = data.data.player_info;
            var mode = data.data.mode;
            var peopleListCopy = JSON.parse(JSON.stringify(this.peopleList)); //深拷贝
            if (mode != peopleListCopy.length) {
              for (let i = 0; i < mode - peopleListCopy.length; i++) {
                this.peopleList.push(this.peopleObj);
              }
            }
            for (let i = 0; i < this.peopleList.length; i++) {
              if (this.peopleList[i].id) {
                this.peopleList[i].state = true;
              } else {
                this.peopleList[i].state = false;
              }
              //   if (mode == 2) {
              //     this.peopleList[i].width = "48%";
              //   } else if (mode == 3) {
              //     this.peopleList[i].width = "33.33%";
              //   } else {
              //     this.peopleList[i].width = "25%";
              //   }
            }

            //对战的滚动图片数据
            for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }

            //生成滚动的图片数组 Math.floor(30 / this.fightImg[0].length) + 1(循环次数，保证数据至少30个)
            if (this.fightImg.length == 1) {
              for (
                let i = 0;
                i < Math.floor(50 / this.fightImg[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.fightImg[0].length; j++) {
                  this.fightImgObj.push(this.fightImg[0][j]);
                  this.fightImgObj = this.fightImgObj.slice(0, 50);
                  this.fightImgObj = this.getRandomArr(this.fightImgObj, 50);
                }
              }
            } else if (this.fightImg.length != 1) {
              for (let i = 0; i < this.fightImg.length; i++) {
                for (
                  let j = 0;
                  j < Math.floor(50 / this.fightImg[i].length) + 1;
                  j++
                ) {
                  for (let x = 0; x < this.fightImg[i].length; x++) {
                    this.fightImgObj.push(this.fightImg[i][x]);
                    this.fightImgObj = this.fightImgObj.slice(0, 50 * (i + 1));
                    //没有打乱顺序
                    // this.fightImgObj = this.getRandomArr(this.fightImgObj,20 * (i+1))
                  }
                }
              }
            }

            //把滚动数据放在各自下
            for (let i = 0; i < this.peopleList.length; i++) {
              this.peopleList[i].fightBox = this.fightImgObj;
            }

            //对战进行中2  对战未开始1  3结束
            if (data.data.status == "3") {
              console.log("状态3");
              var box = data.data.winner_owner;
              let numPrice = 0;
              //是否为平局
              if (data.data.winner_owner.length == 0) {
                var loser = data.data.loser_reward;
                this.thisfightboxList = this.fightboxList.length;
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = this.peopleList[i].skin_list;
                  this.peopleList[i].end = 2;
                  this.peopleList[i].winState = true;
                  this.peopleList[i].totalPrice = 0;
                  if (this.peopleList[i].skin_list == undefined) {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    // this.peopleList[i].totalPrice = "0.01";
                    // this.peopleList[i].box[0].name = "凉凉奖励";
                    this.peopleList[i].totalPrice = loser.price;
                    this.peopleList[i].box[0].price = loser.price;
                    this.peopleList[i].box[0].name = loser.itemName;
                    this.peopleList[i].box[0].img = loser.imageUrl;
                    this.peopleList[i].box[0].shortName = loser.shortName;
                    this.peopleList[i].box[0].english_shortName = loser.english_shortName;
                    this.peopleList[i].box[0].english_exteriorName = loser.english_exteriorName;
                    this.peopleList[i].box[0].exteriorName = loser.exteriorName;
                  } else {
                    for (let j = 0; j < this.peopleList[i].box.length; j++) {
                      this.peopleList[i].totalPrice += Number(
                        this.peopleList[i].box[j].price
                      );
                    }
                    this.peopleList[i].totalPrice = Number(
                      this.peopleList[i].totalPrice
                    ).toFixed(2);
                  }
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              } else {
                this.thisfightboxList = this.fightboxList.length;
                for (let i = 0; i < this.peopleList.length; i++) {
                  if (this.peopleList[i].id == data.data.winner) {
                    this.peopleList[i].box = box;
                    this.peopleList[i].totalPrice = 0;
                    this.peopleList[i].winState = true;
                    for (let j = 0; j < box.length; j++) {
                      this.peopleList[i].totalPrice += Number(box[j].price);
                    }
                  } else {
                    var loser = data.data.loser_reward;
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = loser.price;
                    // this.peopleList[i].box[0].name = "凉凉奖励";
                    this.peopleList[i].box[0].price = loser.price;
                    this.peopleList[i].box[0].name = loser.itemName;
                    this.peopleList[i].box[0].img = loser.imageUrl;
                    this.peopleList[i].box[0].shortName = loser.shortName;
                    this.peopleList[i].box[0].english_shortName = loser.english_shortName;
                    this.peopleList[i].box[0].english_exteriorName = loser.english_exteriorName;
                    this.peopleList[i].box[0].exteriorName = loser.exteriorName;
                  }
                  this.peopleList[i].totalPrice = Number(
                    this.peopleList[i].totalPrice
                  ).toFixed(2);
                  this.peopleList[i].end = 2;
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              }
              if (!tt) {
                this.getRoomList1("tt");
              }
            } else if (data.data.status == "2") {
              console.log("状态2");
              //对战进行中
              this.fightResult1();
            } else {
              //对战未开始
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].end = 1;
              }
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.totalPrice += Number(this.fightboxList[i].price);
                this.fightboxList[i].state = false;
                this.fightboxList[0].state = true;
              }
            }
          }
        })
        .catch((reason) => {
          //this.getRoomList();
          console.log(reason);
        });
    },

    //跳转至活动规则
    goLuckyRule() {
      this.$router.push({
        path: `/LuckyRule`,
      });
    },
    //跳转至活动历史
    goLuckyHistory() {
      this.$router.push({
        path: `/LuckyHistory`,
      });
    },
    getBot(name, id) {
      this.drawerBot = true;
      this.drawerName = name;
      let param = {
        box_id: id,
      };
      this.$axios
        .post("/index/Box/boxInfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.drawerList = data.data.box_skins;
            this.skins_types = data.data.skins_types;
          }
        });
      console.log(this.drawerList);
    },
    getColorPz(color) {
      if (color == "#007acc") {
        return "blue";
      }
      if (color == "#8a2be2") {
        return "blue";
      }
      if (color == "#FFA500") {
        return "yellow";
      }
      if (color == "#FF4500") {
        return "red";
      }
      return "blue";
    },
    getFrist(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[0];
    },
    getLast(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[1];
    },
    splitAndRemoveParentheses(input) {
      const parts = input.split("|");
      const result = parts.map((part) => {
        const indexOfParenthesis = part.indexOf("(");
        if (indexOfParenthesis !== -1) {
          return part.substring(0, indexOfParenthesis).trim();
        }
        return part.trim();
      });
      return result;
    },
    extractValuesInParentheses(input) {
      const regex = /\((.*?)\)/g;
      const matches = [];
      let match;

      while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
      }

      return matches[0];
    },
    //房间观看对战 请求对战结果
    fightResult1() {
      // 获取右上角总消耗数据
      this.totalPrice = 0;
      for (let i = 0; i < this.fightboxList.length; i++) {
        this.totalPrice += Number(this.fightboxList[i].price);
      }

      let _this = this;
      if (this.ount) {
        return;
      }
      this.ount = true;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/resultInfo", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.status == "1") {
            // console.log(111,data)
            // this.playAlarm1();
            let box = JSON.parse(JSON.stringify(data.data.result));
            this.mode = box.length;
            this.openWin = true;
            this.roomLineState = true;

            //把结果放在对应的玩家下
            for (let i = 0; i < this.peopleList.length; i++) {
              this.peopleList[i].boxImg = [];
              for (let j = 0; j < box.length; j++) {
                this.peopleList[i].boxImg.push(box[j][i]);
              }
            }
            this.thisfightboxList = 1;
            this.fightboxList[0].state = true;

            //在滚动的图片放入相应的结果
            // let obj = JSON.parse(JSON.stringify(this.peopleList));
            // for (let i = 0; i < obj.length; i++) {
            // 	for (let j = 0; j < obj[i].boxImg.length; j++) {
            // 		obj[i].fightBox[45 * (j + 1)] = obj[i].boxImg[j];
            // 	}
            // }
            // this.peopleList = obj;

            // console.log(this.peopleList)
            //调整显示图片的地址
            for (let i = 0; i < this.peopleList.length; i++) {
              for (let j = 0; j < this.peopleList[i].fightBox.length; j++) {
                if (!this.peopleList[i].fightBox[j].imageUrl) {
                  this.peopleList[i].fightBox[j].imageUrl =
                    this.peopleList[i].fightBox[j].img;
                }
              }
            }

            //动画
            if (this.mode == 1) {
              _this.playAlarm1();
              setTimeout(() => {
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = [];
                  this.peopleList[i].box.push(box[0][i]);
                  this.peopleList[i].totalPrice =
                    this.peopleList[i].box[0].price;
                  if (i < this.peopleList.length - 1) {
                    // this.thisfightboxList = i + 1;
                  }
                }
                this.$forceUpdate();
                _this.playAlarm2();
              }, 7000);
              setTimeout(() => {
                this.over();
                this.openWin = false;
                this.roomLineState = false;
              }, 9000);
            } else {
              _this.playAlarm1();
              for (let i = 0; i < this.mode; i++) {
                setTimeout(() => {
                  for (let j = 0; j < this.peopleList.length; j++) {
                    if (!this.peopleList[j].box) {
                      this.peopleList[j].box = [];
                    }
                    this.peopleList[j].box.unshift(box[i][j]);
                    if (j < this.peopleList.length - 1) {
                      this.thisfightboxList = this.peopleList[j].box.length + 1;
                    }
                    if (this.thisfightboxList > this.fightboxList.length) {
                      this.thisfightboxList = this.fightboxList.length;
                    }
                    this.peopleList[j].totalPrice = 0;
                    for (let x = 0; x < this.peopleList[j].box.length; x++) {
                      this.peopleList[j].totalPrice += Number(
                        this.peopleList[j].box[x].price
                      );
                    }
                    this.peopleList[j].totalPrice =
                      this.peopleList[j].totalPrice.toFixed(2);
                  }
                  for (let y = 0; y < this.fightboxList.length; y++) {
                    this.fightboxList[y].state = false;
                  }
                  if (i + 1 < this.mode) {
                    this.fightboxList[i + 1].state = true;
                  } else if (i + 1 == this.mode) {
                    _this.playAlarm2();
                    this.fightboxList[i].state = true;
                  }
                  this.$forceUpdate();
                }, (i + 1) * 7280);
              }
              //   _this.playAlarm2();
              //音乐
              /*  for (let i = 0; i < this.mode; i++) {
								  setTimeout(() => {
								    this.playAlarm1();
								  }, i * 5000 );
								}
								for (let i = 0; i < this.mode; i++) {
								  setTimeout(() => {
								    this.playAlarm2();
								  }, (i + 1 ) * 5000 - 1000);
								} */

              setTimeout(() => {
                this.openWin = false;
                this.roomLineState = false;
                this.over();
              }, this.mode * 8000 + 500);
            }
          }
        });
    },
    removeIndexesFromObjectArray(array, indexes) {
      indexes.sort((a, b) => b - a); // Sort indexes in descending order

      for (const index of indexes) {
        array.splice(index, 1);
      }

      return array;
    },

    getNRandom(e, data, type) {
      // this.ccc();
      const date = new Date(data);
      // 获取该时间的秒数
      const seconds = date.getSeconds();
      var selectArray = Math.floor(seconds / 10);
      var lengths = e.length;
      var c1 = JSON.parse(JSON.stringify(this.c1[selectArray]));
      var c11 = [];
      var c2 = JSON.parse(JSON.stringify(this.c2[selectArray]));
      var c22 = [];
      var c3 = JSON.parse(JSON.stringify(this.c3[selectArray]));
      var c33 = [];
      var c4 = JSON.parse(JSON.stringify(this.c4[selectArray]));
      var c44 = [];
      var c5 = JSON.parse(JSON.stringify(this.c5[selectArray]));
      var c55 = [];
      var c6 = JSON.parse(JSON.stringify(this.c6[selectArray]));
      var c66 = [];
      var delArray = [];
      if (type == 1) {
        for (var i = 0; i < c1.length; i++) {
          if (c1.length > lengths && i >= lengths) {
            delArray.push(i);
          }
        }
        delArray.forEach((es) => {
          c1 = this.removeValueFromArray(c1, es);
        });
        c1.forEach((ef) => {
          c11.push(e[ef]);
        });
        return c11;
      }
      if (type == 2) {
        for (var i = 0; i < c2.length; i++) {
          if (c2.length > lengths && i >= lengths) {
            delArray.push(i);
          }
        }
        delArray.forEach((es) => {
          c2 = this.removeValueFromArray(c2, es);
        });
        c2.forEach((ef) => {
          c22.push(e[ef]);
        });
        return c22;
      }
      if (type == 3) {
        for (var i = 0; i < c3.length; i++) {
          if (c3.length > lengths && i >= lengths) {
            delArray.push(i);
          }
        }
        delArray.forEach((es) => {
          c3 = this.removeValueFromArray(c3, es);
        });
        c3.forEach((ef) => {
          c33.push(e[ef]);
        });
        return c33;
      }
      if (type == 4) {
        for (var i = 0; i < c4.length; i++) {
          if (c4.length > lengths && i >= lengths) {
            delArray.push(i);
          }
        }
        delArray.forEach((es) => {
          c4 = this.removeValueFromArray(c4, es);
        });
        c4.forEach((ef) => {
          c44.push(e[ef]);
        });
        return c44;
      }
      if (type == 5) {
        for (var i = 0; i < c5.length; i++) {
          if (c5.length > lengths && i >= lengths) {
            delArray.push(i);
          }
        }
        delArray.forEach((es) => {
          c5 = this.removeValueFromArray(c5, es);
        });
        c5.forEach((ef) => {
          c55.push(e[ef]);
        });
        return c55;
      }
      if (type == 6) {
        for (var i = 0; i < c6.length; i++) {
          if (c6.length > lengths && i >= lengths) {
            delArray.push(i);
          }
        }
        delArray.forEach((es) => {
          c6 = this.removeValueFromArray(c6, es);
        });
        c6.forEach((ef) => {
          c66.push(e[ef]);
        });
        return c66;
      }
    },
    removeValueFromArray(array, value) {
      return array.filter((item) => item !== value);
    },
    ccc() {
      var cc = "";
      cc += "[";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += ",";
      cc += "[";
      this.generateRandomArray(600).forEach((e, index) => {
        if (index == 599) {
          cc += e;
        } else {
          cc += e + ",";
        }
      });
      cc += "]";
      cc += "]";
      console.log(cc);
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },

    generateRandomArray(length) {
      const result = [];
      for (let i = 0; i < length; i++) {
        result.push(i);
      }
      this.shuffleArray(result);
      return result;
    },
    //对局结束
    over() {
      var _this = this;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/setBattleStatus", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          if (res.data.status == "1") {
            // console.log("对战结束")

            //发送
            /* let sendData = {
							   state: "update",
							   battle_id: _this.id,
							 };
							 _this.websocketsend(JSON.stringify(sendData));*/
            _this.getRoomList("over");
          }
        });
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    //点击参与活动
    goParticipate(index, type) {
      if (
        !type &&
        localStorage.getItem("userInfo") &&
        this.statuslist.home_owner ==
          JSON.parse(localStorage.getItem("userInfo")).id
      ) {
        this.showlll = true;
        return;
      }
      this.showlll = false;
      // this.loading = true;
      this.disabled = true;
      let _this = this;
      var param = {
        battle_id: this.id,
        robot: 1,
      };
      _this.$axios
        .post("/index/Battle/addBattle", _this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(123)
          // console.log(data);
          if (data.status == "1") {
            this.loading = false;
            //扣除余额
            this.$parent.getPlayerInfo();
            //人数已满 开始对战
            if (data.data.battle_status == "start") {
              let box = data.data.result;
              this.mode = box.length;
              this.add = false;
              this.getRoomList();
              setTimeout(function () {
                _this.playAlarm1(); // 开启音乐
              }, 1000);
            }
          } else {
            this.disabled = false;
            this.loading = false;
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        })
        .catch((reason) => {
          //console.log("reason" + reason);
          //this.goParticipate(index);
          console.log(reason);
        });
      // this.loading = false;
    },
    //点击盒子查看右侧图片
    selImg(index) {
      this.boxListIndex = index;
      this.fightboxList.forEach((item) => {
        item["state"] = false;
      });
      this.fightboxList[index]["state"] = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./css/luckyroom.less";

.biaoti-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.641) !important;
  border-radius: 5px !important;
  width: 1120px;
  height: 600px;
  padding: 16px;
  border: 1px solid #3a4525;
  box-shadow: 0 17px 57px 0 rgba(0, 0, 0, 0.77);
  color: white;
  font-family: "微软雅黑";
  z-index: 99999;

  .top {
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    img {
      width: 30px;
    }
  }

 .bottom {
    display: flex;
    align-items: center;
    overflow-y: auto;
    margin-top: 22px;

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      height: 540px;
      font-size: 12px;

      .blue {
        background-image: url("../assets/img/openBox/d.png");
        background-size: 100% 100%;
      }
      .purple {
        background-image: url("../assets/img/openBox/b.png");
        background-size: 100% 100%;
      }

      .red {
        background-image: url("../assets/img/openBox/c.png");
        background-size: 100% 100%;
      }

      .yellow {
        background-image: url("../assets/img/openBox/e.png");
        background-size: 100% 100%;
      }

      // .blue:hover {
      // 	background-image: url("../assets/img/ranking/arms-on-bg1.png");
      // }

      // .red:hover {
      // 	background-image: url("../assets/img/ranking/arms-on-bg6.png");
      // }

      // .yellow:hover {
      // 	background-image: url("../assets/img/ranking/arms-on-bg7.png");
      // }

      li {
        margin-top: 12px;
        margin-left: 4px;
        width: 180px;
        height: 180px;
        background: #191926;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-top: 12px;
        .libottom {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .pirce-left {
          display: flex;
          align-items: center;

          span {
            margin-left: 4px;
            color: rgb(117, 220, 158);
            font-weight: 600;
          }
        }

        .box-img1 img {
          width: 100px;
          height: 100px;
        }

        img {
          width: 20px;
        }
      }
    }
  }
}

.room-win-status {
  background-color: transparent !important;
  height: max-content;
  //   padding: 0 3px 5px 3px;
}

.room-fail-status {
  background-color: transparent !important;
  height: max-content;
  //   padding: 0 3px 5px 3px;
}

.room::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  //   background: var(--backimg) no-repeat center center;
  /*  z-index:1; */
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
}

.room-right-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.room-right {
  width: 350px;
  height: 200px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  // background-color: #24252f;
  background: rgba(50, 19, 59, 0.7);
  border: 1px solid rgba(50, 19, 59, 1);
  border-radius: 10px;
  color: white;
  z-index: 999;
  text-align: center;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  div:first-child {
    color: yellow;
  }

  div {
    color: rgba(100, 103, 107, 1);
  }

  div:last-child {
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
      border: 2px solid rgba(113, 43, 134, 1);
      border-radius: 20px;
      display: block;
      padding: 8px 32px;
    }
  }
}

.room {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  z-index: 2;
  background-color: #160e39;

  .audio {
    display: none;
  }

  .room-box {
    // background: url('../assets/img/main-bg.png') no-repeat;
    height: 100%;
    background-position: 50% 0%;
    background-size: 95%;
    padding: 0 6px !important;
  }

  .room-left {
    // padding: 16px 316px 0 16px;
    width: 100vw;
    max-width: 1440px;
    margin: auto;

    .title {
      height: 57px;
      font-family: YouSheBiaoTiHei;
      position: relative;
      max-width: 700px;
      width: 100vw;
      margin: 0 auto;
      padding: 50px 0;
    }

    .title::after {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../assets/img/title-bg.png") center no-repeat;
      background-size: auto;
      background-size: 100%;
      z-index: 10;
      top: 0;
    }

    .title .title-text {
      position: absolute;
      top: 52px;
      font-size: 36px;
      z-index: 11;
      left: 50%;
      margin-left: -100px;
      width: 200px;
      text-align: center;
      color: #fff;
    }

    .title .en-title {
      position: absolute;
      top: 20px;
      font-size: 50px;
      color: #392782;
      opacity: 0.3;
      z-index: 9;
      left: 50%;
      margin-left: -100px;
      width: 200px;
      text-align: center;
    }

    .room-left-hint {
      margin-top: 20px;
      // display: flex;
      // justify-content: flex-start;
      position: relative;

      .roomleft-num {
        display: flex;
        align-items: center;
        color: #848492;
        font-size: 15px !important;

        span {
          margin-right: 5px;

          &:last-child:hover {
            color: #e9b10e;
          }
        }
      }

      .roomleft-btn {
        width: 100%;
        margin-right: -5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
          padding: 6px 15px;
          color: #fff !important;
          font-weight: 400;
          font-size: 14px;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #c9c9f1 !important;

          img {
            width: 32px;
          }
        }

        /deep/.el-switch__label {
          color: #fff !important;
        }
      }
    }

    .room-left-box {
      // margin-top: 10px;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      //   background-color: #2b2c37;
      justify-content: center;
      width: 100%;

      .roombox-warp {
        width: 100%;

        .room-info-top {
          border-bottom: 1px solid #22252f;
          margin-top: 10px;
          background: #3c256d;
          padding: 0 20px;
          height: 64px;
          border-radius: 10px 10px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .first {
            display: flex;
            align-items: center;

            span {
              margin-left: 6px;
            }

            .point_1 {
              // background-color: #e9b10e;
              width: 18px;
              height: 18px;
              background: url("../assets/img/ranking/dd.png");
            }

            .point_2 {
              width: 21px;
              height: 16px;
              background: url("../assets/img/ranking/jxz.png");
            }

            .point_3 {
              width: 24px;
              height: 24px;
              background: url("../assets/img/ranking/yjs.png");
            }

            .text_1 {
              color: #fb9a84;
            }

            .text_2 {
              color: #67c23a;
            }

            .text_3 {
              color: #999;
            }
          }

          .seconed {
            margin-left: 60px;
            color: #74b0ff;
            font-family: "微软雅黑";

            span:first-child {
            }
          }

          .threed {
            span {
              display: flex;
              align-items: center;
              color: #74b0ff;
            }

            .price {
              font-weight: 600;
              color: #ffc83c;
              font-family: "微软雅黑";
              margin-left: 4px;
            }

            img {
              width: 20px;
            }
          }
        }

        ul {
          display: flex;
          align-items: center;
          // justify-content: center;
          overflow: auto;
          overflow-y: hidden;
          padding: 20px 0 20px 0;
          background: #2a1350;
          border-radius: 0 0 10px 10px;

          li {
            width: 100px;
            height: 100px;
            margin-left: 20px;

            .roombox-num {
              display: flex;
              align-items: center;
              // margin: 10px 15px 15px 15px;
              position: relative;

              .roombox-img {
                width: 100px;
                height: 100px;
                position: relative;

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: contain;
                  cursor: pointer;
                  opacity: 0.3;
                }

                img:last-child {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  z-index: 99;
                  margin: auto;
                }
              }

              .roombox-num1 {
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                margin-right: 30px;
                color: #e9b10e;
                position: relative;
                font-size: 14px;
                margin: 0 auto;
              }

              // .roombox-num1::before {
              //   top: 0;
              //   left: 0;
              //   width: 100%;
              //   border: 1px solid #e9b10e;
              //   height: 100%;
              //   content: "";
              //   position: absolute;
              //   transform: rotate(45deg);
              //   box-sizing: border-box;
              // }
              .roombox-line {
                width: 60px;
                height: 1px;
                background-color: #e9b10e;
              }
            }
          }

          li:last-child .roombox-line {
            display: none;
          }

          #room-li {
            position: relative;

            &::before {
              // content: "";
              // position: absolute;
              // width: 100%;
              // height: 100%;
              // top: 0;
              // left: 0;
              // //   background-image: url("../assets/new/bg_border.png");
              // background-size: 100% 100%;
              // background-image: url("../assets/img/lucky/choose.png");
              //   transform: rotate(180deg);
            }

            .roombox-num1 {
              width: 30px;
              height: 30px;
              line-height: 30px;
            }

            .roombox-img {
              width: 100px;
              height: 100px;
              position: relative;

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                cursor: pointer;
                animation: flip 2.5s infinite alternate;
                -webkit-animation: flip 2.5s infinite alternate;
                opacity: 1;
              }

              @keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }
              @-webkit-keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }

              img:last-child {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 99;
                margin: auto;
              }
            }

            .roombox-num1::before {
              box-shadow: 0px 0px 7px #e9b10e;
            }
          }
        }
      }
    }

    .room-left-people {
      .roompe-warp {
        position: relative;

        .ul1 {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
          gap: 10px;

          .fail {
            opacity: 0.5;
          }

          .li1 {
            width: 100%;
            // max-width: 300px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            .game-bottom {
              height: 110px;
              line-height: 110px;
              border: 1px solid #6f3bdf;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 12px;
              padding: 24px 0;
              background: #23124c !important;
              /* background-image: linear-gradient(
							  rgba(43, 44, 55, 1),
							  rgba(35, 71, 59, 1)
							);*/
              // border-top-left-radius: 5px;
              // border-top-right-radius: 5px;
              text-align: center;
              position: relative;
              overflow: hidden;

              .pool {
                overflow: hidden;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                z-index: 2;

                ul {
                  width: 100%;
                  position: absolute;
                  top: 0;
                  animation: run 7s;
                  animation: run 7s;
                  -webkit-animation: run 7s;
                  animation-timing-function: cubic-bezier(
                    0.0001,
                    0.9,
                    0.6,
                    1
                  ) !important; //动画慢 快 慢
                  // animation-timing-function: ease-out; //动画 速度一样
                  animation-iteration-count: 1 !important; //播放几次动画
                  -webkit-animation-iteration-count: 1 !important; //播放几次动画
                  animation-delay: 0s; //动画运行前等待时间
                  -webkit-animation-delay: 0s; //动画运行前等待时间
                  animation-fill-mode: forwards !important; //动画结束 是否保持
                  -webkit-animation-fill-mode: forwards !important; //动画结束 是否保持
                  padding-top: 32px;

                  .blue {
                    background-image: url("../assets/img/ranking/arms-bg1.png");
                  }

                  .red {
                    background-image: url("../assets/img/ranking/arms-bg6.png");
                  }

                  .yellow {
                    background-image: url("../assets/img/ranking/arms-bg7.png");
                  }

                  .blue:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg1.png");
                  }

                  .red:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg6.png");
                  }

                  .yellow:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg7.png");
                  }

                  li {
                    width: 150px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-size: 100% 100%;
                    margin: auto;
                    position: relative;

                    .box-img1 {
                      position: relative;

                      img {
                        position: absolute;
                        width: 60px;
                        height: 60px;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                      }
                    }

                    .box-img-name {
                      color: white;
                      font-size: 12px;
                      position: absolute;
                      height: 30px;
                      line-height: 30px;
                      left: 4px;
                      bottom: 1%;
                      display: inline-block;
                      width: 100%;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      text-align: left;
                      position: absolute;
                    }
                  }
                }

                @keyframes run {
                  0% {
                    top: 0;
                  }

                  100% {
                    top: -9000px;
                  }
                }
                @-webkit-keyframes run {
                  0% {
                    top: 0;
                  }

                  100% {
                    top: -9000px;
                  }
                }

                .pool-ul2 {
                  animation: run2 14s;
                  -webkit-animation: run2 14s;
                }

                @keyframes run2 {
                  0% {
                    top: 0;
                  }

                  50% {
                    top: -9000px;
                  }

                  100% {
                    top: -18000px;
                  }
                }
                @-webkit-keyframes run2 {
                  0% {
                    top: 0;
                  }

                  50% {
                    top: -9000px;
                  }

                  100% {
                    top: -18000px;
                  }
                }

                .pool-ul3 {
                  animation: run3 22s;
                  -webkit-animation: run3 22s;
                }

                @keyframes run3 {
                  0% {
                    top: 0;
                  }

                  33.33% {
                    top: -9000px;
                  }

                  66.66% {
                    top: -18000px;
                  }

                  100% {
                    top: -27000px;
                  }
                }
                @-webkit-keyframes run3 {
                  0% {
                    top: 0;
                  }

                  33.33% {
                    top: -9000px;
                  }

                  66.66% {
                    top: -18000px;
                  }

                  100% {
                    top: -27000px;
                  }
                }

                .pool-ul4 {
                  animation: run4 29s;
                  -webkit-animation: run4 29s;
                }

                @keyframes run4 {
                  0% {
                    top: 0;
                  }

                  25% {
                    top: -9000px;
                  }

                  50% {
                    top: -18000px;
                  }

                  75% {
                    top: -27000px;
                  }

                  100% {
                    top: -36000px;
                  }
                }
                @-webkit-keyframes run4 {
                  0% {
                    top: 0;
                  }

                  25% {
                    top: -9000px;
                  }

                  50% {
                    top: -18000px;
                  }

                  75% {
                    top: -27000px;
                  }

                  100% {
                    top: -36000px;
                  }
                }

                .pool-ul5 {
                  animation: run5 36s;
                  -webkit-animation: run5 36s;
                }

                @keyframes run5 {
                  0% {
                    top: 0;
                  }

                  20% {
                    top: -9000px;
                  }

                  40% {
                    top: -18000px;
                  }

                  60% {
                    top: -27000px;
                  }

                  80% {
                    top: -36000px;
                  }

                  100% {
                    top: -45000px;
                  }
                }
                @-webkit-keyframes run5 {
                  0% {
                    top: 0;
                  }

                  20% {
                    top: -9000px;
                  }

                  40% {
                    top: -18000px;
                  }

                  60% {
                    top: -27000px;
                  }

                  80% {
                    top: -36000px;
                  }

                  100% {
                    top: -45000px;
                  }
                }

                .pool-ul6 {
                  animation: run6 44s;
                  -webkit-animation: run6 44s;
                }

                @keyframes run6 {
                  0% {
                    top: 0;
                  }

                  16.66% {
                    top: -9000px;
                  }

                  33.33% {
                    top: -18000px;
                  }

                  50% {
                    top: -27000px;
                  }

                  66.66% {
                    top: -36000px;
                  }

                  83.33% {
                    top: -45000px;
                  }

                  100% {
                    top: -54000px;
                  }
                }
                @-webkit-keyframes run6 {
                  0% {
                    top: 0;
                  }

                  16.66% {
                    top: -9000px;
                  }

                  33.33% {
                    top: -18000px;
                  }

                  50% {
                    top: -27000px;
                  }

                  66.66% {
                    top: -36000px;
                  }

                  83.33% {
                    top: -45000px;
                  }

                  100% {
                    top: -54000px;
                  }
                }
              }

              .room1-img {
                //   background-image: linear-gradient(
                //     rgba(43, 44, 55, 1),
                //     rgba(35, 71, 59, 1)
                //   );
                img {
                  margin-top: 40px;
                  width: 30px;
                  height: 30px;
                }

                .room1-text2 {
                  margin-top: 0px;
                  font-size: 20px;
                  color: #848492;
                }

                .room1-text1 {
                  margin-top: 0px;
                  font-size: 20px;
                  color: #e9b10e;
                }
              }

              button {
                margin-top: -50px;
                padding: 8px 10px;
                background-color: #e9b10e;
                border-radius: 8px;
                color: #1a1c24;
                font-size: 8px;
                font-weight: 400;
              }

              button:hover {
                cursor: pointer;
              }
            }

            .game-top {
              border: 1px solid #6f3bdf;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 12px;
              padding: 24px 0;

              .win-img {
                animation: flip 2.5s infinite alternate;
                -webkit-animation: flip 2.5s infinite alternate;
              }

              @keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }
              @-webkit-keyframes flip {
                0% {
                  transform: scale(1);
                }

                25% {
                  transform: scale(1.1);
                }

                50% {
                  transform: scale(1);
                }

                75% {
                  transform: scale(1.1);
                }
              }

              img {
                width: 250px;
                height: 112px;
              }

              .prize-price {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "微软雅黑";
                font-weight: 600;
                color: #ffc83c;

                img {
                  width: 20px !important;
                  height: 20px !important;
                }

                span {
                  margin-left: 5px;
                  font-size: 14px;
                }

                font-size: 12px;
              }
            }

            .room-warp {
              width: 100%;

              .room1 {
                padding: 48px 0;
                border: 1px solid #6f3bdf;
                // background: #23124C !important;
                /* background-image: linear-gradient(
                  rgba(43, 44, 55, 1),
                  rgba(35, 71, 59, 1)
                );*/
                // border-top-left-radius: 5px;
                // border-top-right-radius: 5px;
                text-align: center;
                position: relative;
                overflow: hidden;

                .pool {
                  overflow: hidden;
                  position: absolute;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background-size: 100% 100%;
                  z-index: 2;

                  .blue {
                    background-image: url("../assets/img/ranking/arms-bg1.png");
                  }

                  .red {
                    background-image: url("../assets/img/ranking/arms-bg6.png");
                  }

                  .yellow {
                    background-image: url("../assets/img/ranking/arms-bg7.png");
                  }

                  .blue:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg1.png");
                  }

                  .red:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg6.png");
                  }

                  .yellow:hover {
                    background-image: url("../assets/img/ranking/arms-on-bg7.png");
                  }

                  ul {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    animation: run 5s;
                    animation-timing-function: ease-out; //动画 速度一样
                    animation-iteration-count: 1; //播放几次动画
                    animation-delay: 0s; //动画运行前等待时间
                    animation-fill-mode: forwards; //动画结束 是否保持
                    -webkit-animation: run 5s;
                    -webkit-animation-timing-function: ease-out; //动画 速度一样
                    -webkit-animation-iteration-count: 1; //播放几次动画
                    -webkit-animation-delay: 0s; //动画运行前等待时间
                    -webkit-animation-fill-mode: forwards; //动画结束 是否保持

                    li {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;

                      .pool-img {
                        height: 100px;

                        img {
                          object-fit: contain;
                          height: 100px;
                          width: auto;
                          margin: 0 auto;
                        }
                      }
                    }
                  }

                  .pool-ul2 {
                    animation: run2 16s;
                    -webkit-animation: run2 16s;
                  }

                  @keyframes run2 {
                    0% {
                      top: 0;
                    }

                    50% {
                      top: -9000px;
                    }

                    100% {
                      top: -18000px;
                    }
                  }
                  @-webkit-keyframes run2 {
                    0% {
                      top: 0;
                    }

                    50% {
                      top: -9000px;
                    }

                    100% {
                      top: -18000px;
                    }
                  }

                  .pool-ul3 {
                    animation: run3 24s;
                    -webkit-animation: run3 24s;
                  }

                  @keyframes run3 {
                    0% {
                      top: 0;
                    }

                    33.33% {
                      top: -9000px;
                    }

                    66.66% {
                      top: -18000px;
                    }

                    100% {
                      top: -27000px;
                    }
                  }
                  @-webkit-keyframes run3 {
                    0% {
                      top: 0;
                    }

                    33.33% {
                      top: -9000px;
                    }

                    66.66% {
                      top: -18000px;
                    }

                    100% {
                      top: -27000px;
                    }
                  }

                  .pool-ul4 {
                    animation: run4 32s;
                    -webkit-animation: run4 32s;
                  }

                  @keyframes run4 {
                    0% {
                      top: 0;
                    }

                    25% {
                      top: -9000px;
                    }

                    50% {
                      top: -18000px;
                    }

                    75% {
                      top: -27000px;
                    }

                    100% {
                      top: -36000px;
                    }
                  }
                  @-webkit-keyframes run4 {
                    0% {
                      top: 0;
                    }

                    25% {
                      top: -9000px;
                    }

                    50% {
                      top: -18000px;
                    }

                    75% {
                      top: -27000px;
                    }

                    100% {
                      top: -36000px;
                    }
                  }

                  .pool-ul5 {
                    animation: run5 40s;
                    -webkit-animation: run5 40s;
                  }

                  @keyframes run5 {
                    0% {
                      top: 0;
                    }

                    20% {
                      top: -9000px;
                    }

                    40% {
                      top: -18000px;
                    }

                    60% {
                      top: -27000px;
                    }

                    80% {
                      top: -36000px;
                    }

                    100% {
                      top: -45000px;
                    }
                  }
                  @-webkit-keyframes run5 {
                    0% {
                      top: 0;
                    }

                    20% {
                      top: -9000px;
                    }

                    40% {
                      top: -18000px;
                    }

                    60% {
                      top: -27000px;
                    }

                    80% {
                      top: -36000px;
                    }

                    100% {
                      top: -45000px;
                    }
                  }

                  .pool-ul6 {
                    animation: run6 46s;
                    -webkit-animation: run6 46s;
                  }

                  @keyframes run6 {
                    0% {
                      top: 0;
                    }

                    16.66% {
                      top: -9000px;
                    }

                    33.33% {
                      top: -18000px;
                    }

                    50% {
                      top: -27000px;
                    }

                    66.66% {
                      top: -36000px;
                    }

                    83.33% {
                      top: -45000px;
                    }

                    100% {
                      top: -54000px;
                    }
                  }
                  @-webkit-keyframes run6 {
                    0% {
                      top: 0;
                    }

                    16.66% {
                      top: -9000px;
                    }

                    33.33% {
                      top: -18000px;
                    }

                    50% {
                      top: -27000px;
                    }

                    66.66% {
                      top: -36000px;
                    }

                    83.33% {
                      top: -45000px;
                    }

                    100% {
                      top: -54000px;
                    }
                  }
                }

                .room1-img {
                  //   background-image: linear-gradient(
                  //     rgba(43, 44, 55, 1),
                  //     rgba(35, 71, 59, 1)
                  //   );
                  img {
                    width: 120px;
                    height: 120px;
                  }

                  .room1-text2 {
                    margin-top: 0px;
                    font-size: 20px;
                    color: #848492;
                  }

                  .room1-text1 {
                    margin-top: 0px;
                    font-size: 20px;
                    color: #e9b10e;
                  }

                  div {
                    color: #20c288;
                    margin-top: 20px;
                  }
                }

                .room1-img:last-child {
                  div {
                    color: #9a4bff !important;
                  }
                }

                button {
                  margin-top: -50px;
                  padding: 8px 10px;
                  background-color: #e9b10e;
                  border-radius: 8px;
                  color: #1a1c24;
                  font-size: 8px;
                  font-weight: 400;
                }

                button:hover {
                  cursor: pointer;
                }
              }

              .room2 {
                display: flex;
                align-items: center;
                padding: 0 24px;
                height: 90px;
                border: 2px solid rgba(180, 85, 255, 0.2) !important;
                display: flex;
                align-items: center;
                padding-left: 20px;
                background: linear-gradient(
                  180deg,
                  rgba(130, 71, 255, 0) 0%,
                  rgba(191, 10, 255, 0.2) 100%
                );
                padding-right: 20px;
                justify-content: space-between;

                .room2-swap {
                  display: flex;
                  align-items: center;
                }

                .prize-price {
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: "微软雅黑";
                  font-weight: 600;

                  img {
                    width: 20px !important;
                    height: 20px !important;
                  }

                  span {
                    padding-top: 0;
                    margin-left: 5px;
                    color: #ffc83c !important;
                    font-size: 14px;
                  }
                }

                .room2-tou {
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }
                }

                .room2-name {
                  font-size: 14px;
                  margin-left: 12px;
                  font-weight: 500;
                  color: #fff;
                  font-size: 16px;
                  font-family: "微软雅黑";
                }

                span {
                  font-size: 14px;
                  color: #848492;
                  display: inline-block;
                  padding-top: 50px;
                }
              }
            }
          }

          .room-line:before {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            z-index: 8;
            top: 60px;
            left: 0;
            background-color: #e9b10e;
            box-shadow: 0px 0px 7px #e9b10e;
          }
        }
      }

      .roompe-line {
        position: absolute;
        left: 0;
        top: 80px;
        width: 100%;
        height: 2px;
        z-index: 9;
        background-color: #e9b10e;
      }

      // .roompe-warp::before {
      // 	content: "";
      // 	display: block;
      // 	width: 0;
      // 	height: 0;
      // 	border-top: 10px solid transparent;
      // 	border-left: 20px solid #e9b10e;
      // 	border-bottom: 10px solid transparent;
      // 	position: absolute;
      // 	left: 0;
      // 	top: 51px;
      // 	z-index: 10;
      // }

      // .roompe-warp::after {
      // 	content: "";
      // 	display: block;
      // 	width: 0;
      // 	height: 0;
      // 	border-top: 10px solid transparent;
      // 	border-right: 20px solid #e9b10e;
      // 	border-bottom: 10px solid transparent;
      // 	position: absolute;
      // 	right: 0;
      // 	top: 51px;
      // 	z-index: 10;
      // }
    }

    .room-left-bot {
      margin-top: 30px;
      color: #848492;
      font-size: 12px;
      text-align: center;
      margin-bottom: 20px;

      & > p:last-child {
        color: #e9b10e;
      }

      p:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  //开奖列表
  .win-list {
    // margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    background: #23124c;

    .win-title {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 24px;
        width: auto;
      }

      span {
        margin-left: 5px;
        font-size: 24px;
        color: #e9b10e;
      }
    }

    .blue {
      background-image: url("../assets/img/ranking/arms-bg1.png");
    }

    .red {
      background-image: url("../assets/img/ranking/arms-bg6.png");
    }

    .yellow {
      background-image: url("../assets/img/ranking/arms-bg7.png");
    }

    .blue:hover {
      background-image: url("../assets/img/ranking/arms-on-bg1.png");
    }

    .red:hover {
      background-image: url("../assets/img/ranking/arms-on-bg6.png");
    }

    .yellow:hover {
      background-image: url("../assets/img/ranking/arms-on-bg7.png");
    }

    ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 12px 0 12px 12px;
    }

    li {
      width: 140px;
      background-size: 100% 100%;
      height: 94px;
      margin-right: 10px;
      margin-top: 12px;
      position: relative;

      .box-img1 {
        background-size: 100% 100%;
        position: relative;
        height: 94px;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 65px;
        }
      }

      .box-img-name {
        color: white;
        font-size: 12px;
        position: absolute;
        left: 4px;
        bottom: 1%;
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
      }

      .probability {
        font-size: 14px;
        position: absolute;
        right: 4px;
        top: 5px;
        font-weight: 700;
        font-family: "微软雅黑";
        color: white;
      }

      .list-pirce {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .ico {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        color: #c3c3e2;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 2px 4px;
      }

      .pirce-left {
        display: flex;
        align-items: center;

        img {
          width: auto;
          height: 20px;
        }

        span {
          color: #e9b10e;
          font-size: 14px;
          font-weight: 600;
          font-family: "微软雅黑";
          margin-left: 4px;
        }
      }

      .pirce-right {
        color: white;
        font-size: 12px;
        margin-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .win-li {
        width: 140px;
        //min-width: 200px;

        .win-warp {
          margin: 5px 0;
          background-color: #2b2c37;

          .img {
            height: 150px;
            // border-top-left-radius: 5px;
            // border-top-right-radius: 5px;
            background-position: top center;
            background-image: url("../assets/img/box-skins-blue.jpg");
            // background-size: 100% 95%;
            padding: 0 5px;
            display: grid;
            place-items: center;

            img {
              width: 70%;
              height: auto;
              max-width: 200px;
            }
          }

          h6 {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            color: #fff;
            font-weight: 400;
            font-size: 10px;
            width: calc(100% - 10px);

            img {
              height: 16px;
              width: auto;
            }

            span {
              margin-left: 5px;
              font-size: 16px;
              color: #e9b10e;
            }
          }
        }
      }
    }
  }
}

//底部弹框
.box-bot {
  .box-img {
    .box-img-title {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 16px;
        color: white;
      }

      span:last-child {
        cursor: pointer;
      }

      .span1 {
        color: #e9b10e;
      }
    }

    .box-img-title1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: rgb(255, 255, 255);
        text-align: center;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      span:last-child {
        cursor: pointer;
      }
    }

    .box-img-list {
      padding: 0 16px;

      ul {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        height: 190px;

        .blue {
          background-image: url("../assets/img/ranking/arms-bg1.png");
        }

        .red {
          background-image: url("../assets/img/ranking/arms-bg6.png");
        }

        .yellow {
          background-image: url("../assets/img/ranking/arms-bg7.png");
        }

        .blue:hover {
          background-image: url("../assets/img/ranking/arms-on-bg1.png");
        }

        .red:hover {
          background-image: url("../assets/img/ranking/arms-on-bg6.png");
        }

        .yellow:hover {
          background-image: url("../assets/img/ranking/arms-on-bg7.png");
        }

        li {
          width: 200px;
          height: 134px;
          background-size: 100% 100%;
          margin-right: 16px;
          margin-top: 12px;
          position: relative;

          .box-img1 {
            background-size: 100% 100%;
            position: absolute;
            top: 20%;
            height: 50%;
            left: 50%;
            margin-left: -23%;

            img {
              width: 85px;
            }
          }

          .box-img-name {
            color: white;
            font-size: 12px;
            position: absolute;
            left: 4px;
            bottom: 1%;
            display: inline-block;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: left;
          }

          .probability {
            font-size: 14px;
            position: absolute;
            right: 4px;
            top: 5px;
            font-weight: 700;
            font-family: "微软雅黑";
            color: white;
          }

          .list-pirce {
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .ico {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            color: #c3c3e2;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 2px 4px;
          }

          .pirce-left {
            display: flex;
            align-items: center;

            img {
              width: auto;
              height: 20px;
            }

            span {
              color: #e9b10e;
              font-size: 16px;
              font-weight: 600;
              font-family: "微软雅黑";
              margin-left: 4px;
            }
          }

          .pirce-right {
            color: white;
            font-size: 12px;
            margin-right: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

::v-deep {
  .el-drawer__body {
    padding: 10px 0px 10px 15px;
    background: #1f1f23;
    // overflow-y: auto;
  }

  .el-drawer__header {
    background: #1f1f23;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}

.homebot {
  margin-top: 110px;
}
@media screen and (max-width: 767px) {
  .room-left{
    overflow: hidden !important;
  }
  .room-left-hint {
    padding-top: 10px;
    // margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
  .title1{
    // margin-left: 15% !important;
    transform: translate(-48%,-43%) !important;
  }
}
.title1 {
  // margin-left: 35.5%;
  // margin-right: 400px !important;
  position: absolute;
  width: 160px;
  left: 50%;
  top: 50%;
  transform: translate(-58%,-50%);
  .t {
    width: 160px !important;
    img{
      width: 160px !important;
      height: auto;
    }
  }
}

.gailv {
  color: #fff;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 550px ) {
    justify-content: flex-start !important;
  }
  span {
    display: flex;
    align-items: center;
  }
  .probability-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 2px 0 12px;
  }
}
</style>
<template>
  <div class="main">
    <div class="container">
      <div class="ruleBox" v-if="close">
        <div class="cancel" @click="close = false">
          <img src="./img/close.png" alt="" />
        </div>
        <div class="title">
          <div>{{$t("玩法规则")}}</div>
        </div>
        <div class="con">
          <p>
            1.{{$t("从背包中选择最少3件不需要的皮肤饰品作为材料（总价值不低于XX金币），点击合成，合成随机饰品，饰品价格是合成总价的50%~200%")}}
          </p>
          <p>2.{{$t("汰换后，作为材料的饰品将会消失，请谨慎操作。")}}</p>
          <p>
            {{$t("特别注意：汰换所得饰品，若本平台暂无库存，可联系客服兑换等价值金币的饰品（因饰品市场价值快速波动，不以市场价为准）。")}}
          </p>
          <p>{{$t("谨慎操作，理性消费！")}}</p>
        </div>
      </div>
      <div class="top-title" v-if="width < 600">
        <div class="tai">
          <img src="./img/tai.png" alt="" />
          <span>{{$t("汰换")}}</span>
        </div>
        <div class="rule" @click="close = true">
          <img src="./img/rule.png" alt="" />
          <span>{{$t("玩法规则")}}</span>
        </div>
      </div>
      <div class="top">
        <div class="title">
          <span>{{$t("您将获得的价格区间")}}</span>
        </div>
        <div class="rule" @click="close = true" v-if="width > 600">
          <img src="./img/rule.png" alt="" />
          <span>{{$t("玩法规则")}}</span>
        </div>
        <div class="min-max">
          <span
            ><span>{{$t("最低")}}</span
            ><span style="margin-left: 3px; color: #ffc600">{{
              0.5 * allPrice
            }}</span></span
          >
          <span style="margin-left: 5px"
            ><span>{{$t("最高")}}</span
            ><span style="margin-left: 3px; color: #ffc600">{{
              2 * allPrice
            }}</span></span
          >
        </div>
        <div class="add">
          <ul>
            <li>
              <div class="box" v-if="addList.length >= 1">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[0].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[0].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{ $getLocaleProperty(addList[0],'exteriorName')}}</div>
                  <div class="c2">{{ $getLocaleProperty(addList[0],'shortName')  }}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 2">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[1].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[1].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{ $getLocaleProperty(addList[1],'exteriorName')}}</div>
                  <div class="c2">{{ $getLocaleProperty(addList[1],'shortName')}}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 3">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[2].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[2].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{ $getLocaleProperty(addList[2],'exteriorName')}}</div>
                  <div class="c2">{{ $getLocaleProperty(addList[2],'shortName')}}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 4">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[3].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[3].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{ $getLocaleProperty(addList[3],'exteriorName')}}</div>
                  <div class="c2">{{ $getLocaleProperty(addList[3],'shortName')}}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 5">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[4].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[4].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{$getLocaleProperty(addList[4],'exteriorName')  }}</div>
                  <div class="c2">{{$getLocaleProperty(addList[4],'shortName') }}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 6">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[5].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[5].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{$getLocaleProperty(addList[5],'exteriorName')}}</div>
                  <div class="c2">{{$getLocaleProperty(addList[5],'shortName') }}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 7">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[6].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[6].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{$getLocaleProperty(addList[6],'exteriorName')  }}</div>
                  <div class="c2">{{$getLocaleProperty(addList[6],'shortName')  }}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 8">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[7].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[7].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{$getLocaleProperty(addList[7],'exteriorName')  }}</div>
                  <div class="c2">{{$getLocaleProperty(addList[7],'shortName')  }}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
            <li>
              <div class="box" v-if="addList.length >= 9">
                <div class="price">
                  <img src="./img/money.png" alt="" />
                  <span>{{ addList[8].price }}</span>
                </div>
                <div class="wuqi">
                  <img :src="addList[8].img" alt="" />
                </div>
                <div class="description">
                  <div class="c1">{{$getLocaleProperty(addList[8],'exteriorName')  }}</div>
                  <div class="c2">{{$getLocaleProperty(addList[8],'shortName')  }}</div>
                </div>
              </div>
              <div class="money" v-else>{{$t("添加饰品")}}</div>
            </li>
          </ul>
        </div>
        <div class="total">
          <span
            ><span>{{$t("总件数")}}</span
            ><span style="margin: 0px 3px; color: #ffc600">{{
              addList.length
            }}</span
            ><span style="color: #ffc600">/</span
            ><span style="margin-left: 3px; color: #ffc600">9</span></span
          >
          <span style="margin-left: 5px"
            ><span>{{$t("总价值")}}</span
            ><span style="color: #ffc600; margin-left: 3px">{{
              allPrice
            }}</span></span
          >
        </div>
        <div class="button">
          <span @click="goAdd">{{$t("合成")}}</span>
        </div>
      </div>
      <div class="boxlist">
        <div class="boxs">
          <div
            class="box"
            :class="selectBox.includes(item.id) ? 'active' : ''"
            @click="add(item)"
            v-for="item in boxList"
            :key="item.id"
          >
            <div class="price">
              <img src="./img/money.png" alt="" />
              <span>{{ item.price }}</span>
            </div>
            <div class="wuqi">
              <img :src="item.img" alt="" />
            </div>
            <div class="description">
              <div class="c1">{{$getLocaleProperty(item,'exteriorName')}}</div>
              <div class="c2">{{$getLocaleProperty(item,'shortName')}}</div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="win-box" v-if="winState">
        <mywin
          :winList="winInfo"
          :winState="winState"
          :isis="false"
          :player_skins_ids='player_skins_ids'
          @winexchange="winexchange($event)"
          @winget="winget($event)"
          @winX="winX($event)"
        ></mywin>
      </div> -->
    <div class="win-box" v-if="winInfo.id">
      <div class="win">
        <div class="win-conm">
          <!-- <div class="win-tips">恭喜获得</div> -->
        </div>
        <div
          class="win-conms"
          style="
            color: white;
            display: flex;
            align-items: center;
            flex-flow: row;
          "
        >
          <div class="img"><img :src="winInfo.imageUrl" alt="" /></div>
          <div style="font-size:10px;margin-bottom:10px">{{$getLocaleProperty(winInfo,'shortName')}}</div>
          <div style="color: #80c00c;">
            {{  $getLocaleProperty(winInfo,'exteriorName')}}
          </div>
        </div>
        <div
          class="win-span2"
          @click="
            winState = false;
            winInfo = [];
          "
        >
        {{$t("放入背包")}}
        </div>
        <div class="win-span2 fen" @click="fenjie">
          <img src="./img/masonry.png" style="width: 24px" />
          <div>{{ winInfo.price }}</div>
          <span style="font-size:15px;margin-left:3px">({{$t("分解")}})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mywin from "@/components/my_win.vue";
export default {
  components: {
    mywin,
  },
  data() {
    return {
      close: false,
      isSelect: false,
      page: 1,
      pageSize: 24,
      total: "",
      boxList: [],
      addList: [],
      selectBox: [],
      allPrice: 0,
      winInfo: [],
      width: "",
      player_skins_ids: "",
      winState: false,
    };
  },
  mounted() {
    this.getInfo(this.page);
    this.width = document.body.clientWidth;
  },
  methods: {
    winexchange(msg) {
      this.winState = msg;
    },
    winget(msg) {
      this.winState = msg;
    },
    winX(msg) {
      this.winState = msg;
    },
    // 分解饰品
    fenjie() {
      this.$axios({
        url: "/index/User/exchangeToMoney",
        method: "post",
        data: { player_skins_ids: [this.player_skins_ids] },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            message: this.$t("分解成功!"),
            type: "success",
            customClass: "messageStyle",
          });
          this.winInfo = [];
          this.getInfo(this.page);
        } else {
          this.$message({
            message: this.$getLocaleProperty(res.data.data,'msg'),
            type: "warning",
            customClass: "messageStyle",
          });
          this.winInfo = [];
        }
      });
    },
    // 获取页面数据
    getInfo(page) {
      let id = JSON.parse(localStorage.getItem("userInfo")).id;
      this.$axios({
        url: "/index/User/packageList",
        method: "post",
        data: { page: page, pageSize: this.pageSize },
      }).then((res) => {
        if (res.data.status == 1) {
          this.boxList = res.data.data.skinList;
          this.total = res.data.data.total;
        }
      });
    },
    // 页码切换
    currentChange(index) {
      this.getInfo(index);
    },
    // 点击箱子将箱子添加到合成
    add(boxInfo) {
      if (this.selectBox.includes(boxInfo.id)) {
        // 如果已经选择箱子了，则点击取消选择
        let arrs = this.selectBox;
        for (let i = 0; i < arrs.length; i++) {
          if (arrs[i] == boxInfo.id) {
            arrs.splice(arrs.indexOf(arrs[i]), 1);
          }
        }
        // 将箱子从添加里面删除
        let addArr = this.addList;
        for (let i = 0; i < addArr.length; i++) {
          if (addArr[i].id == boxInfo.id) {
            // 删除箱子时减去先箱子的价格
            this.allPrice = (
              Number(this.allPrice) - Number(addArr[i].price)
            ).toFixed(2);
            addArr.splice(addArr.indexOf(addArr[i]), 1);
          }
        }
      } else {
        // 如果没有选择箱子，则加入到选择的箱子中
        if (this.selectBox.length >= 9) {
          // 如果选择的箱子达到了最大值，则提示用户，不再添加
          this.$message({
            message: this.$t("最多只能添加9个饰品"),
            type: "warning",
            customClass: "messageStyle",
          });
        } else {
          this.selectBox.push(boxInfo.id);
          this.addList.push(boxInfo);
          // 计算出所有饰品的总价值
          let box = this.addList;
          this.allPrice = 0;
          for (let i = 0; i < box.length; i++) {
            this.allPrice = (
              Number(this.allPrice) + Number(box[i].price)
            ).toFixed(2);
          }
        }
      }
      // console.log(this.selectBox)
      // console.log(this.addList,'addList')
    },
    // 合成饰品
    goAdd() {
      let id = this.selectBox.join();
      this.$axios({
        url: "/index/exchange/exchange",
        method: "post",
        data: { ids: id },
      }).then((res) => {
        if (res.data.status == 1) {
          this.winInfo = res.data.data;
          this.player_skins_ids = res.data.data.player_skins_id;
          this.selectBox = [];
          this.addList = [];
          this.allPrice = 0;
          this.$message({
            message: this.$getLocaleProperty(res.data,'msg'),
            type: "success",
            customClass: "messageStyle",
          });
          this.getInfo(this.page);
        } else {
          this.$message({
            message: this.$getLocaleProperty(res.data,'msg'),
            type: "warning",
            customClass: "messageStyle",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  background-color: black;
}
.container {
  width: 100%;
  // height: calc(100vh - 175px);
  background-color: black;
  padding: 25px 150px;
  box-sizing: border-box;
  @media (max-width: 550px) {
    width: 100vw;
    padding: 0 20px;
    overflow: hidden;
  }
  .top-title {
    width: 100%;
    height: 40px;
    background-color: #181c27;
    border-radius: 5px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0 15px;
    box-sizing: border-box;
    .tai {
      img {
        width: 15px;
        height: auto;
        margin-right: 3px;
      }
    }
    .rule {
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: auto;
        margin-right: 3px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .ruleBox {
    width: 530px;
    height: 480px;
    background-image: url(./img/box-bg.jpg);
    background-size: 100% 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 10px;
    @media (max-width: 550px) {
      width: 90vw;
    }
    .cancel {
      position: absolute;
      top: 10px;
      right: 13px;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        transform: rotate(90deg);
      }
      img {
        width: 16px;
        height: auto;
      }
    }
    .title {
      font-size: 22px;
      margin-top: 70px;
    }
    .con {
      padding: 0 100px;
      @media (max-width: 550px) {
        padding: 0 50px;
      }
      p {
        margin-top: 20px;
      }
    }
  }
  .top {
    width: 100%;
    height: 300px;
    background-color: #181c27;
    border-radius: 7px;
    padding: 25px 20px;
    box-sizing: border-box;
    color: white;
    position: relative;
    box-sizing: border-box;
    @media (max-width: 550px) {
      padding: 10px;
      height: 450px;
    }
    .title {
      span {
        font-size: 26px;
        font-weight: 400;
        @media (max-width: 550px) {
          font-size: 22px;
        }
      }
    }
    .min-max {
    }
    .rule {
      position: absolute;
      top: 20px;
      right: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 20px;
        height: auto;
        margin-right: 5px;
      }
    }
    .add {
      width: 100%;
      height: 140px;
      margin-top: 8px;
      // margin-left: 105px;
      margin-left: 5vw;
      @media (max-width: 550px) {
        height: auto;
        margin-left: 0;
        .money {
          font-size: 12px !important;
        }
      }
      ul {
        margin-left: 10px;
        display: flex;
        @media (max-width: 550px) {
          flex-wrap: wrap;
          margin-left: 5px;
        }
        li {
          width: 7.29vw;
          // width: 140px;
          // height: 140px;
          height: 7.29vw;
          border: 1px solid #555;
          border-radius: 5px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          @media (max-width: 550px) {
            width: 100px;
            height: 100px;
            margin-right: 0;
            margin: 3px;
          }
          .box {
            // margin: 7.2px 7.2px;
            width: 140px;
            height: 140px;
            background-image: url(./img/boxlist-bg.png);
            background-size: 125% auto;
            background-position: center;
            border-radius: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            box-sizing: border-box;
            cursor: pointer;
            @media (max-width: 550px) {
              width: 100px;
              height: 100px;
            }
            .price {
              position: absolute;
              top: 5px;
              right: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 15px;
                height: auto;
              }
            }
            .wuqi {
              img {
                width: 100px;
                height: auto;
              }
            }
            .description {
              position: absolute;
              left: 5px;
              bottom: 5px;
              .c1 {
                color: #a9abbc;
                font-size: 12px;
                @media (max-width: 550px) {
                  font-size: 8px;
                }
              }
              .c2 {
                @media (max-width: 550px) {
                  font-size: 8px;
                }
              }
            }
          }
          .money {
            width: 40px;
            font-size: 18px;
            color: #555;
            position: absolute;
            @media (max-width: 550px) {
              width: 30px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .total {
      margin-top: 5px;
    }
    .button {
      // margin-top: 5px;
      width: 100%;
      text-align: center;
      @media (max-width: 550px) {
        text-align: end;
        margin-top: -5px;
      }
      span {
        background-color: #ffc600;
        color: black;
        font-size: 18px;
        padding: 7px 50px;
        border-radius: 5px;
        cursor: pointer;
        @media (max-width: 550px) {
          font-size: 14px;
          padding: 4px 40px;
        }
      }
    }
  }
  .boxlist {
    width: 100%;
    height: 740px;
    background-color: #181c27;
    border-radius: 7px;
    margin-top: 20px;
    padding: 25px;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 550px) {
      height: auto;
      padding: 10px;
    }
    .pagination {
      position: absolute;
      left: 50%;
      bottom: 40px;
      transform: translate(-50%, 0);
      @media (max-width: 550px) {
        width: 100%;
        height: 50px;
        background-color: #181c27;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
      }
    }
    .active {
      border: 1px solid #f5d016;
    }
    .boxs {
      width: 100%;
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      @media (max-width: 550px) {
        margin-bottom: 50px;
      }
    }
    .box {
      margin: 0.37vw;
      // width: 9.3vw;
      // height: 9.3vw;
      width: 11.5%;
      // height: 180px;
      height: 9.5vw;
      // height: var(--ww);
      background-image: url(./img/boxlist-bg.png);
      background-size: 125% auto;
      background-position: center;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      box-sizing: border-box;
      cursor: pointer;
      @media (max-width: 550px) {
        width: 100px;
        height: 100px;
        margin: 5px;
      }
      .price {
        position: absolute;
        top: 5px;
        right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 15px;
          height: auto;
        }
      }
      .wuqi {
        img {
          width: 100px;
          height: auto;
        }
      }
      .description {
        position: absolute;
        left: 5px;
        bottom: 5px;
        .c1 {
          color: #a9abbc;
          font-size: 12px;
          @media (max-width: 550px) {
            font-size: 8px;
          }
        }
        .c2 {
          @media (max-width: 550px) {
            font-size: 8px;
          }
        }
      }
    }
  }
}
.bottom {
  width: 100%;
  height: 100px;
  background-color: red;
}

/deep/ .el-pagination {
  display: flex;
}

/deep/ .el-pagination.is-background .el-pager {
  display: flex;
}

/deep/ .el-pagination.is-background .el-pager li {
  width: 35px;
  height: 33px;
  margin-top: -4px;
  padding: 0;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ffc600;
  color: black;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  background-color: #ffc600;
  color: black;
}
/deep/ .el-pagination .btn-next .btn-prev,
.el-pagination .btn-prev .el-icon {
  background-color: #333542;
}
/deep/ .el-pagination .btn-next,
.el-pagination .btn-prev {
  background-color: #181c27;
  border: none !important;
}
/deep/ .el-pager li.active,
.el-pager {
  color: black !important;
}
/deep/ .el-pagination.is-background .btn-next {
  background-color: #181c27 !important;
  border: none !important;
  width: 40px !important;
}
/deep/ .el-pagination.is-background .btn-prev {
  background-color: #181c27 !important;
  border: none !important;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 24px;
}
/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 24px;
}

//中奖后盒子
.win-box {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
}

.win-conm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .win-tips {
    height: 60px;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    font-weight: bold;
  }
}
.win {
  width: 450px;
  padding: 20px;
  // background-color: #1c2230;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 550px) {
    width: 100vw;
  }
  .win-conms {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    .img {
      width: 220px;
      height: 220px;
      background-image: url(./img/box-skins-golden.jpg);
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100px;
      height: auto;
    }
  }
  .win-span2 {
    width: 220px;
    padding: 10px 0;
    height: 20px;
    // background-color: #e9b10e;
    background-color: #80c00c;
    background-size: 100% 100%;
    border: 1px solid #80c00c;
    color: white;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 14px;
  }
  .fen {
    background-color: rgba(255, 255, 255, 0.8);
    color: white;
    span {
      color: red;
    }
  }

  .win-span2:hover {
    cursor: pointer;
  }
}
</style>